import * as S from "./Styles";
import { useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";

// type Error404Props = {};
export const Error404 = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | 404";
  }, []);
  useReloadScrollTop();
  return (
    <S.Wrapper>
      <h1>Ошибка 404</h1>
      <br />
      <p>Данная страница не существует.</p>
    </S.Wrapper>
  );
};
