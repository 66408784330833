import { PageMarginSection, PageWrapper } from "../../Styles/components";
import * as S from "./Styles";
import { Profile } from "./Profile/Profile";
import { ReactComponent as Cup } from "../../assets/icons/profile/cup.svg";
import { User as UserType } from "../../gql/graphql";
import { UserItems } from "./UserItems/UserItems";

type UserProps = {
  isMine: boolean;
  currentUser: UserType;
};
export const User = ({ isMine, currentUser }: UserProps) => {
  return (
    <PageWrapper>
      <PageMarginSection>
        <S.Heading>
          Профиль
          <Cup />
        </S.Heading>
        <Profile user={currentUser} isMine={isMine} />
      </PageMarginSection>
      <UserItems isMine={isMine} userId={currentUser.id} />
    </PageWrapper>
  );
};
