import { BottomContainer as Wrapper } from "../Styles";
import * as S from "./Styles";
import { ReactComponent as Wallet } from "../../../../assets/icons/profile/wallet.svg";
import { Convert } from "./Convert/Convert";
import { Input } from "./Input/Input";
import { Output } from "./Output/Output";
import { User as UserType } from "../../../../gql/graphql";

type BottomContainerProps = {
  user: UserType;
};
export const BottomContainer = ({ user }: BottomContainerProps) => {
  return (
    <Wrapper>
      <S.Balance>
        <S.WalletDivider>
          <Wallet />
          <div>
            <div>Баланс</div>
            <S.BalanceValue>
              {user.balance}₽{/*<span style={{ color: "yellow" }}>₵</span>*/}
            </S.BalanceValue>
          </div>
        </S.WalletDivider>
        <Input />
      </S.Balance>
      <S.BtnContainer>
        <Convert />
      </S.BtnContainer>
      <S.RefBalance>
        <S.WalletDivider>
          <S.BalanceValue>{user.ref_balance}§</S.BalanceValue>
          <S.TextRef>
            <div>Реферальный</div>
            <div>баланс</div>
          </S.TextRef>
        </S.WalletDivider>
        <Output />
      </S.RefBalance>
    </Wrapper>
  );
};
