import { ModalContainerProps } from "../../../../../Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../../../../../Components/Modal/Modal";
import * as S from "../../../BottomContainer/Convert/ConfirmModal/Styles";
import {
  ModalDescription,
  ModalHeading
} from "../../../../../../Styles/components";
import { useMutation, useQuery } from "@apollo/client";
import { SELL_ALL_ITEMS } from "../../../../../../graphql/Mutation";
import {
  GET_ME,
  GET_TOTAL_PRICE,
  GET_USER_ITEMS
} from "../../../../../../graphql/Query";
import { Loader } from "../../../../../../Components/Preloader/Loader/Loader";
import { getCount } from "../../../../../../functions/getCount";

type ConfirmModalProps = ModalContainerProps & {
  userId: string;
};
export const ConfirmModal = ({
  isOpen,
  isShow,
  toggle,
  userId
}: ConfirmModalProps) => {
  const { data, loading: priceLoad } = useQuery(GET_TOTAL_PRICE);
  const [sellAll, { loading }] = useMutation(SELL_ALL_ITEMS, {
    refetchQueries: [
      { query: GET_ME },
      { query: GET_USER_ITEMS, variables: { id: userId } }
    ]
  });
  const onConfirm = async () => {
    await sellAll({
      onCompleted: (data) => {
        if (data.sellAllItems) {
          toggle();
        }
      }
    });
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Подтверждение продажи предметов</ModalHeading>
        {priceLoad || !data ? (
          <Loader isSmall={true} />
        ) : (
          <>
            <ModalDescription>
              Нам требуется ваше подтверждение для осуществления данного
              действия.
            </ModalDescription>
            <S.Text>
              Вы уверены, что хотите продать все свои предметы на сумму{" "}
              {getCount(data.getAllPrice)}₽?
            </S.Text>
            <S.BtnsContainer>
              <S.ConfirmBtn onClick={onConfirm} disabled={loading}>
                {loading ? "Загрузка" : "Подтвердить"}
              </S.ConfirmBtn>
              <S.CancelBtn onClick={toggle} disabled={loading}>
                Отмена
              </S.CancelBtn>
            </S.BtnsContainer>
          </>
        )}
      </S.Wrapper>
    </Modal>
  ) : null;
};
