import styled from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const HelpDivider = styled.div`
  text-align: right;
`;
export const Help = styled.button`
  cursor: pointer;
  font-size: 3.5rem;
  line-height: 1.1;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brightOrange};
  text-align: center;
  margin-right: 5rem;
  padding: 2rem 3rem;
  border: 3px solid ${({ theme }) => theme.colors.brightOrange};
  border-radius: 50%;
  box-shadow: 0 0 15px hsl(16, 100%, 61%);
  background-color: transparent;
  &:hover {
    background-color: hsla(${({ theme }) => theme.colors.orangeValue}, 0.3);
  }
  ${getTransition()}
`;
