import * as S from "./Styles";
import { ModalContainerProps } from "../../../../Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../../../../Components/Modal/Modal";
import { ModalHeading } from "../../../../../Styles/components";

export const ModalContainer = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const onClick = () => {
    localStorage.setItem("dontShowColModal", "true");
    toggle();
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Акция "Собери все предметы"</ModalHeading>
        <S.ModalDescription>
          Соберите всю коллекцию предметов и получите{" "}
          <S.Price>100 000₽</S.Price>!
        </S.ModalDescription>
        <S.Content>
          Открывайте кейсы, собирайте коллекцию и получите шанс выиграть{" "}
          <S.Price>100 000₽</S.Price> на свой баланс! Срок проведения акции
          неограничен, принять участие может любой желающий.
        </S.Content>
      </S.Wrapper>
      <S.CloseForever onClick={onClick}>Больше не показывать</S.CloseForever>
      <S.Image
        src={process.env.REACT_APP_URI + "imgs/collection.jpg"}
        alt={"Фон бонусной акции"}
      />
    </Modal>
  ) : null;
};
