import styled, { css, keyframes } from "styled-components";
import { getTransition } from "../../../../../Styles/utilites";

export enum CardVars {
  items = "items",
  secret = "secret",
  gold = "gold",
  simple = "simple",
  epic = "epic"
}
type WrapperProps = {
  type: CardVars;
  $isWrap?: boolean;
  $isWin?: boolean;
  $isFast?: boolean;
  $isDrop?: boolean;
  $isSide?: boolean;
  $isNext?: boolean;
  $idx?: number;
  $speed?: number;
};
export type Colors = {
  [key in CardVars]: {
    border: string;
    leftGrad: string;
  };
};

const winnerShadow = keyframes`
  0% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 0 6px 5px hsl(29,88%,56%); //hsl(302,87%,34%)
  }
`;
const loseGrey = keyframes`
  0% {
    opacity: 1;
    //filter: saturate(1)
  }
  100% {
    //filter: saturate(0.35);
    opacity: 0.5;
  }
`;
export const Wrapper = styled.li<WrapperProps>`
  user-select: none;
  position: relative;
  font-size: 0.8rem;
  height: 100%;
  width: ${({ $isDrop }) => ($isDrop ? "100%" : "10rem")};
  ${({ $isSide, $idx }) =>
    $isSide &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      transform: translateX(calc(100% * ${$idx}));
      cursor: pointer;
      > a {
        text-decoration: none;
      }
      > a > div:first-child {
        opacity: 1;
        ${getTransition(300, "opacity")}
      }
      > a > div > img:first-child {
        transform: scale(1);
        opacity: 1;
        ${getTransition(300, ["opacity", "transform"])}
      }
      > a > div > img {
        transform: scale(0.7);
        opacity: 0;
        ${getTransition(300, ["opacity", "transform"])}
      }
      &:hover {
        > a > div:first-child {
          opacity: 0;
        }
        > a > div > img:first-child {
          opacity: 0;
          transform: scale(0.7);
        }
        > a > div > img {
          opacity: 1;
          transform: scale(1.3);
        }
        > a > div > span > h5 {
          opacity: 1;
        }
        > a > div > span > h5:first-child {
          opacity: 0;
        }
      }
      @media screen and ${({ theme }) => theme.media.sm} {
        left: 0;
        right: 0;
        transform: translateY(calc((0.2em + 100%) * ${$idx}));
      }
    `}
  ${({ $isWrap, $isDrop }) =>
    $isWrap &&
    !$isDrop &&
    css`
      margin-bottom: 0.3em;
      width: 50%;
    `}

  ${({ $isWin, $isFast }) =>
    $isWin &&
    css`
      z-index: ${({ theme }) => theme.order.three};
      animation: ${winnerShadow} 0.5s ${$isFast ? "0.5s" : "10s"} ease;
      animation-fill-mode: both;
    `};
  ${({ $isWin, $isFast }) =>
    $isWin === false &&
    css`
      animation: ${loseGrey} 0.5s ${$isFast ? "0.5s" : "10s"} ease;
      animation-fill-mode: both;
    `};
  &:before {
    z-index: ${({ theme }) => theme.order.one};
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url("/logo480.png") no-repeat center;
    left: 0;
    background-size: calc(4% + 4rem);
    opacity: 0.25;
    filter: grayscale();
  }
  ${({ theme, type }) => {
    const colors: Colors = {
      items: {
        border: `${theme.colors.items}`,
        leftGrad: `${theme.colors.itemsGradLeft}`
      },
      secret: {
        border: `${theme.colors.secret}`,
        leftGrad: `${theme.colors.secretGradLeft}`
      },
      epic: {
        border: `${theme.colors.epic}`,
        leftGrad: `${theme.colors.epicGradLeft}`
      },
      gold: {
        border: `${theme.colors.gold}`,
        leftGrad: `${theme.colors.goldGradLeft}`
      },
      simple: {
        border: `${theme.colors.simple}`,
        leftGrad: `${theme.colors.simpleGradLeft}`
      }
    };
    const palette = colors[type];
    return css`
      border-left: 3px solid ${palette.border};
      background: linear-gradient(
        270deg,
        ${theme.colors.bgLayout} 0%,
        ${palette.leftGrad} 100%
      );
    `;
  }}
  @media screen and ${({ theme }) => theme.media.sm} {
    width: ${({ $isWrap, $isDrop }) =>
      $isWrap && !$isDrop ? "50%" : $isDrop ? "100%" : "auto"};
    height: ${({ $isDrop }) => ($isDrop ? "90%" : "6rem")};
    border-left-width: 4px;
    margin-bottom: ${({ $isWrap }) => ($isWrap ? "0.3em" : "0.25em")};
  }
  @media screen and ${({ theme }) => theme.media.md} {
    ${({ $isWrap, $isDrop }) =>
      $isWrap &&
      !$isDrop &&
      css`
        width: 33.3333%;
      `};
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    ${({ $isWrap, $isDrop }) =>
      $isWrap &&
      !$isDrop &&
      css`
        width: 25%;
      `}
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    ${({ $isWrap, $isDrop }) =>
      $isWrap &&
      !$isDrop &&
      css`
        width: 20%;
      `}
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    ${({ $isWrap, $isDrop }) =>
      $isWrap &&
      !$isDrop &&
      css`
        width: 16.6666%;
      `}
  }
  ${getTransition(300, ["transform", "color", "background-color"])}
`;

export const CaseImg = styled.img`
  position: absolute;
`;

type ItemProps = {
  $isWrap?: boolean;
};
export const Price = styled.div<ItemProps>`
  display: ${({ $isWrap }) => ($isWrap ? "block" : "none")};
  position: absolute;
  top: 0.2em;
  right: 0.6em;
  font-weight: 600;

  @media screen and ${({ theme }) => theme.media.sm} {
    display: block;
  }
`;
export const Container = styled.div<ItemProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.order.two};
  width: 100%;
  height: 100%;
  img {
    height: ${({ $isWrap }) => ($isWrap ? "5rem" : "3.8rem")};
    margin: 0.5em 0;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    img {
      height: 65%;
    }
  }
`;
export const Span = styled.span`
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const Name = styled.h5<ItemProps>`
  text-align: center;
  margin: ${({ $isWrap }) => ($isWrap ? "0.3em" : "0")};
  font-size: ${({ $isWrap }) => ($isWrap ? "0.75rem" : "0.7rem")};
  font-weight: 600;
  ${getTransition(300, "opacity")}
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 0.75rem;
    font-weight: ${({ $isWrap }) => ($isWrap ? "600" : "500")};
  }
`;

export const UserName = styled(Name)`
  position: absolute;
  margin: auto;
  opacity: 0;
`;
