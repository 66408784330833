import { Accordion, Items } from "../../../Components/Accordion/Accordion";
import * as S from "./Styles";

// type QuestionsProps = {};
export const items: Items = [
  {
    title: "Что такое инвайт-код?",
    body: "Инвайт-код — это код, который ваш знакомый может вставить в соответствующей форме ввода, что даст вам бонус при каждом пополнении счета, приглашенным пользователем, начиная со второго."
  },
  {
    title: "Зачем нужна реферальная ссылка?",
    body: "Реферальная ссылка автоматизирует ввод вашего ивайт-кода пользователем. Ему требуется только перейти по ссылке и подтвердить свое согласие на приглашение. Данное подтверждение он увидит сразу после авторизации."
  },
  {
    title: "Как мне получить бонус за приглашение?",
    body: "Бонусы начисляются автоматически, когда приглашенный вами пользователь пополняет баланс на сайте. Вы можете после использовать их по своему усмотрению — вывести на карту или воспользоваться ими на нашем сайте."
  }
];
export const Questions = () => {
  return (
    <S.Wrapper>
      <S.Title>Вопросы по бонусам</S.Title>
      <S.Container>
        <Accordion items={items} />
      </S.Container>
    </S.Wrapper>
  );
};
