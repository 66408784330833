import { useFormInput } from "../../../hooks/useFormInput";
import { ModalHeading } from "../../../Styles/components";
import * as S from "./Styles";
import { FaCheck } from "react-icons/fa";
import { paymentsData } from "../../../data/paymentsData";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { SET_PROMOCODE } from "../../../graphql/Mutation";
import { ReactComponent as Check } from "../../../assets/icons/referral/check.svg";
import { Relative } from "../../../pages/Referral/TopContainer/Styles";

type MainProps = {
  onAgreement: () => void;
  promo?: string;
};

export const Main = ({ onAgreement, promo }: MainProps) => {
  const [loading, setLoading] = useState(false);
  const [codeConfirmed, setConfirm] = useState<null | string>(null);
  const [formValue] = useFormInput({
    checkbox: "true",
    promocode: promo || "",
    price: 200
  });
  const [setCode] = useMutation(SET_PROMOCODE, {
    variables: {
      code: formValue.value.promocode
    },
    onCompleted: (data) => {
      if (data.promocode) setConfirm("TRUE");
    },
    onError: () => setConfirm("ERROR")
  });
  const [selected, setSelected] = useState<null | number>(null);

  const onClick = async () => {
    if (!formValue.value.promocode.length || codeConfirmed === "TRUE") return;
    await setCode();
  };
  const makePayment = async () => {
    if (formValue.value.price <= 10 || formValue.value.checkbox !== "true")
      return;
    const body: {
      price: number;
      promocode?: string;
    } = {
      price: formValue.value.price
    };
    if (formValue.value.promocode && codeConfirmed)
      body.promocode = formValue.value.promocode;
    setLoading(true);
    try {
      const resp = await fetch(process.env.REACT_APP_URI + "yokassa_test", {
        method: "post",
        credentials: "include",
        body: JSON.stringify(body),
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const data = await resp.json();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (promo) {
      setCode();
    }
  }, []);
  return (
    <>
      <ModalHeading>Пополнение баланса</ModalHeading>
      <S.Content>
        <S.Payments>
          {paymentsData.map(({ name, img }, i) => (
            <S.Payment
              key={name}
              $isActive={i === selected}
              onClick={() => setSelected(i)}
            >
              <img src={img} alt={name} />
            </S.Payment>
          ))}
        </S.Payments>
        <Relative>
          <S.Input
            name={"promocode"}
            id={"promocode"}
            type={"text"}
            placeholder={"Промокод"}
            value={formValue.value.promocode}
            onChange={formValue.onChange}
            disabled={codeConfirmed === "TRUE"}
          />
          <Check onClick={onClick} />
          <S.Notification $show={codeConfirmed === "TRUE"}>
            {codeConfirmed && codeConfirmed === "TRUE" && (
              <div>Промокод применён</div>
            )}
          </S.Notification>
          <S.ErrorNotification $show={codeConfirmed === "ERROR"}>
            {codeConfirmed && codeConfirmed === "ERROR" && (
              <div>Промокод не действителен</div>
            )}
          </S.ErrorNotification>
        </Relative>
        <S.AgreementCheck>
          <label htmlFor={"agreement"}>
            <input
              type={"checkbox"}
              id={"agreement"}
              name={"checkbox"}
              value={formValue.value.checkbox}
              onChange={formValue.onChange}
            />
            <S.Checkmark $isSelected={formValue.value.checkbox === "true"}>
              {formValue.value.checkbox === "true" && <FaCheck />}
            </S.Checkmark>
            Я принимаю условия
            <button onClick={onAgreement}>Соглашения</button>
          </label>
        </S.AgreementCheck>
        <S.FlexDivider>
          {typeof selected === "number" && (
            <S.InputContainer>
              <S.InputSpan>₽</S.InputSpan>
              <S.Input
                name={"price"}
                id={"price"}
                type={"number"}
                placeholder={"Сумма пополнения"}
                value={formValue.value.price}
                onChange={formValue.onChange}
                $isPrice={true}
                //disabled={codeConfirmed === "TRUE"}
              />
              <S.Notification $show={true} $isLight={true}>
                <div>Пополнение от 10₽</div>
              </S.Notification>
            </S.InputContainer>
          )}

          <S.ConfirmBtn
            disabled={
              typeof selected !== "number" ||
              formValue.value.checkbox === "false" ||
              loading
            }
            onClick={makePayment}
          >
            {loading ? "Загрузка" : "Пополнить"}
          </S.ConfirmBtn>
        </S.FlexDivider>
      </S.Content>
    </>
  );
};
