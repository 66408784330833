import styled from "styled-components";
import { getTransition } from "./utilites";

export const PageWrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 4rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 4rem;
  }
`;

export const PageHeading = styled.h5`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const PageMarginSection = styled.section`
  margin: 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 2rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin: 2.5rem;
  }
`;

export const WrapperModal = styled.div`
  text-align: center;
  margin: 3rem 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin: auto 1rem;
  }
`;
export const ModalHeading = styled.h5`
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
`;

export const ModalDescription = styled.div`
  color: ${({ theme }) => theme.colors.grey45};
  font-weight: bold;
`;

export const FormInput = styled.input`
  position: relative;
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  padding: 0.5em 1.5em;
  background: linear-gradient(90deg, #44312a 0%, #41412a 99.42%);
  &:focus-visible {
    outline: none;
  }
`;

// export const ColumnFlex = css`
//   display: flex;
//   flex-direction: column;
// `;
//
// type ModalProps = {
//   isBig?: boolean;
// };
// export const Modal = styled.div<ModalProps>`
//   cursor: initial;
//   position: relative;
//   ${({ isBig }) =>
//     !isBig &&
//     css`
//       top: -5rem;
//     `};
//
//   width: 450px;
//   animation: ${openModal} 0.3s ease-out;
// `;
//
type ModalProps = {
  $isShow: boolean;
};
export const FullScreenModalWrapper = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.order.action};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(${({ theme }) => theme.colors.revertValue}, 0.6);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  opacity: ${({ $isShow }) => ($isShow ? 1 : 0)};
  ${getTransition(300, "opacity", "ease-out")}
`;
//
// export const FullScreenModal = styled.div`
//   width: 100vw;
//   max-width: none;
//   height: 100%;
//   margin: 0;
//   position: absolute;
//   ${ColumnFlex};
//   background-color: ${({ theme }) => theme.colors.bgColor};
// `;
//
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 1rem;
  top: -7px;
  right: -2px;
  z-index: ${({ theme }) => theme.order.two};
  color: ${({ theme }) => theme.colors.grey45};
  font-size: 25px;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  width: 28px;
  max-width: 28px;
  height: 28px;
  max-height: 28px;
  //border: 2px solid ${({ theme }) => theme.colors.grey45};
  //border-radius: 50%;
  border: none;
  padding: 0;
  background: transparent;
  // background-color: hsl(0, 0%, 30%);
  //transform: rotateZ(45deg);
  &:hover {
    color: ${({ theme }) => theme.colors.grey58};
    border-color: ${({ theme }) => theme.colors.grey58};
    //background-color: ${({ theme }) => theme.colors.grey45};
  }
  ${getTransition(300, ["color", "border-color", "background-color"])}
  @media screen and ${({ theme }) => theme.media.sm} {
    top: 0;
    right: 5px;
    width: 34px;
    height: 34px;
  }
`;
// type ModalTitleProps = {
//   textAlign?: string;
// };
// export const ModalTitle = styled.h5<ModalTitleProps>`
//   color: ${({ theme }) => theme.colors.font};
//   margin: 0 2rem 1rem 0;
//   ${({ textAlign }) =>
//     textAlign &&
//     css`
//       margin-right: 0;
//     `};
//   font-size: 1.25rem;
//   font-weight: 600;
//   line-height: 1.1;
//   text-align: ${({ textAlign }) => (textAlign ? textAlign : "inherit")};
// `;
