import * as S from "../Styles";
import { ScreenReader } from "../../../Styles/utilites";
import src from "../../../assets/imgs/logo480.png";
import * as React from "react";

type LoaderProps = {
  isFull?: boolean;
  isSmall?: boolean;
};
export const Loader = ({ isFull, isSmall }: LoaderProps) => {
  if (isFull)
    return (
      <S.IOSDivider>
        <S.Loader>
          <ScreenReader>Brawl Cases</ScreenReader>
          <img src={src} alt={"Brawl Cases Logo"} />
        </S.Loader>
      </S.IOSDivider>
    );
  return (
    <S.LoaderDivider role={"status"} aria-label={"spinner"} $isSmall={isSmall}>
      <S.Loader $isSmall={isSmall}>
        <ScreenReader>Brawl Cases</ScreenReader>
        <img src={src} alt={"Brawl Cases Logo"} />
      </S.Loader>
    </S.LoaderDivider>
  );
};
