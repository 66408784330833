import React from "react";
import GlobalStyles from "./Styles/global";
import { Layout } from "./Components/Layout/Layout";
import { Pages } from "./pages";
import { AppContextProvider } from "./Components/AppContext/AppContext";
import { Preloader } from "./Components/Preloader/Preloader";
import src from "./assets/imgs/logo480.png";

function App() {
  return (
    <div>
      <GlobalStyles />
      <Preloader />
      <AppContextProvider>
        <img src={src} alt={"logoCached"} style={{ display: "none" }} />
        <Layout>
          <Pages />
        </Layout>
      </AppContextProvider>
    </div>
  );
}

export default App;
