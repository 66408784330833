import styled, { css, keyframes } from "styled-components";
import { getTransition } from "../../Styles/utilites";

type WrapperProps = {
  $isLoaded: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  z-index: ${({ theme }) => theme.order.action};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  //text-align: center;
  background-color: ${({ theme }) => theme.colors.bgColor};
  opacity: ${({ $isLoaded }) => ($isLoaded ? "0" : "1")};
  ${getTransition(300, "opacity", "ease-out")}
`;

const loading = keyframes`
 0%, 50%, 100% {
    transform: rotateY(0);
}
  25%, 75%{
    transform: rotateY(90deg);
  }
`;

type LoaderDividerProps = {
  $isSmall?: boolean;
};
export const LoaderDivider = styled.div<LoaderDividerProps>`
  display: flex;
  align-items: center;
  align-content: center;
  min-height: ${({ $isSmall }) => ($isSmall ? "auto" : "70vh")};
`;

type LoaderProps = {
  $isSmall?: boolean;
};
export const Loader = styled.div<LoaderProps>`
  ${({ $isSmall }) =>
    $isSmall
      ? css`
          margin: 2rem auto;
        `
      : css`
          margin: auto;
        `}
  img {
    min-width: 100%;
    width: ${({ $isSmall }) => ($isSmall ? "10rem" : "15rem")};
    animation: ${loading} 5s 0s infinite ease-in-out;
    animation-fill-mode: both;
  }
`;

export const IOSDivider = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  //background-color: ${({ theme }) => theme.colors.bgColor};
`;
