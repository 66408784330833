import * as S from "./Styles";
import { ReactComponent as Network } from "../../../../../assets/icons/network.svg";
import { getCount } from "../../../../../functions/getCount";

type OnlineStatusProps = {
  online: number;
};
export const OnlineStatus = ({ online }: OnlineStatusProps) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.IconDivider>
          <Network />
        </S.IconDivider>
        <S.TextContainer>
          <S.Count>{getCount(online)}</S.Count>
          <S.Status>ОНЛАЙН</S.Status>
        </S.TextContainer>
      </S.Container>
    </S.Wrapper>
  );
};
