import styled from "styled-components";

export const BottomContainer = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  @media screen and ${({ theme }) => theme.media.sm} {
    //если онлайн-статус будет снизу, то убрать
    // position: relative;
    display: block;
    height: 100%;
  }
`;
