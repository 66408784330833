import * as S from "./Styles";

import { Item } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Item";
import { CardData } from "../../../gql/graphql";
import { CardVars } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Styles";

type ContentProps = {
  items: CardData[];
};
export const Content = ({ items }: ContentProps) => {
  return (
    <S.Wrapper>
      <S.Heading>Содержимое кейса</S.Heading>

      <S.ItemContainer>
        {items.map(({ type, image, price, name, id }) => (
          <Item
            type={type as CardVars}
            img={process.env.REACT_APP_URI + image}
            price={price}
            name={name}
            isWrap={true}
            key={id}
          />
        ))}
      </S.ItemContainer>
    </S.Wrapper>
  );
};
