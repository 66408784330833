import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin: 1rem 0;
  @media screen and ${({ theme }) => theme.media.xl} {
    margin: 0;
  }
`;
export const BtnDivider = styled.div`
  vertical-align: middle;
  text-align: center;
  label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.font};
    font-weight: 600;
    cursor: pointer;
  }
`;

export const Label = styled.label`
  display: inline-block;
  position: relative;

  input[type="checkbox"] {
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0;
  }

  input[type="checkbox"]:checked + label:after {
    content: attr(data-on);
    left: 146px;
    background: ${({ theme }) => theme.colors.brightPurple};
  }

  input[type="checkbox"]:checked + label:before {
    content: attr(data-off);
    right: auto;
    left: 40px;
  }
`;
export const InnerLabel = styled.label`
  margin: 0px;
  width: 300px;
  height: 42px;
  background: ${({ theme }) => theme.colors.bgLayout};
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
  &:before {
    content: attr(data-on);
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    top: 13px;
    right: 30px;
    color: ${({ theme }) => theme.colors.font};
  }
  &:after {
    content: attr(data-off);
    width: 150px;
    height: 36px;
    background: ${({ theme }) => theme.colors.brightPurple};
    border-radius: 26px;
    position: absolute;
    left: 4px;
    top: 3px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
    line-height: 1.8;
  }
`;
