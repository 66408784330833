import gem30 from "../assets/imgs/gems/Гем 30 -.png";
import gem80 from "../assets/imgs/gems/Гем 80.png";
import gem170 from "../assets/imgs/gems/Гем 170.png";
import gem360 from "../assets/imgs/gems/Гем 360.png";
import gem950 from "../assets/imgs/gems/Гем 950 over.png";
import gem2000 from "../assets/imgs/gems/Гем 2000.png";

export type GemCase = {
  id: string;
  img: string;
  price: number;
  count: number;
};

export const gemsData: GemCase[] = [
  {
    id: "1",
    img: gem30,
    price: 145,
    count: 30
  },
  {
    id: "2",
    img: gem30,
    price: 279,
    count: 60
  },
  {
    id: "3",
    img: gem80,
    price: 329,
    count: 80
  },
  {
    id: "4",
    img: gem80,
    price: 459,
    count: 160
  },
  {
    id: "5",
    img: gem170,
    price: 499,
    count: 170
  },
  {
    id: "6",
    img: gem170,
    price: 859,
    count: 340
  },
  {
    id: "7",
    img: gem360,
    price: 899,
    count: 360
  },
  {
    id: "8",
    img: gem360,
    price: 1999,
    count: 720
  },
  {
    id: "9",
    img: gem950,
    price: 2399,
    count: 950
  },
  {
    id: "10",
    img: gem950,
    price: 4299,
    count: 1900
  },
  {
    id: "11",
    img: gem2000,
    price: 4499,
    count: 2000
  },
  {
    id: "12",
    img: gem2000,
    price: 8499,
    count: 4000
  }
];
