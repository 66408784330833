import { Banner } from "./Banner/Banner";
import { Cases } from "./Cases/Cases";
import { useEffect, useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { IS_LOGGED_IN } from "../../graphql/Query";

// type MainProps = {};
export const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    if (location.search.match(/isAuth=/)) {
      const isAuth = location.search.replace("?isAuth=", "") === "true";
      if (isAuth) {
        localStorage.setItem("isAuth", "true");
        client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
        navigate("/", { replace: true });
      }
    }
    if (location.search.match(/referral-link=/)) {
      const referral = location.search.replace("?referral-link=", "");
      localStorage.setItem("referralCode", referral);
      navigate("/", { replace: true });
    }
  }, []);
  useLayoutEffect(() => {
    document.title = "Brawl Cases | Открывай кейсы";
  }, []);
  useReloadScrollTop();
  return (
    <div>
      <Banner />
      <Cases />
    </div>
  );
};
