import { useState } from "react";

import { PureTopSiteLink } from "./TopSiteLink";
import { useScrollTo } from "../../../../hooks/useScrollTo";
import { useVisibleTopLink } from "../../../../hooks/useVisibleTopLink";

export default function TopSiteLinkContainer() {
  const [isTopLinkVisible, toggleIsTopLinkVisible] = useState(false);
  const [scrollToTop] = useScrollTo(0);
  useVisibleTopLink(isTopLinkVisible, toggleIsTopLinkVisible);

  return (
    <PureTopSiteLink
      isTopLinkVisible={isTopLinkVisible}
      scrollToTop={scrollToTop}
    />
  );
}
