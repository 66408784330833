import * as S from "./Styles";
import { TopContainer } from "./TopContainer/TopContainer";
import { Navigation } from "./Navigation/Navigation";
import { Statistic } from "./Statistic/Statistic";

type HeaderProps = {
  gems: number;
  users: number;
  cases: number;
};
export const Header = ({ gems, users, cases }: HeaderProps) => {
  return (
    <S.Header>
      <TopContainer />
      <Navigation />
      <Statistic cases={cases} gems={gems} users={users} />
    </S.Header>
  );
};
