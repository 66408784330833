import * as S from "./Styles";
import { Logo } from "../../../Logo/Logo";
import { SocialLinks } from "../../../SocialLinks/SocialLinks";
import { SignComponent } from "../../../SignComponent/SignComponent";

// type TopContainerProps = {};

export const TopContainer = () => {
  return (
    <S.Wrapper>
      <S.LogoWrapper>
        <Logo isHeader={true} />
      </S.LogoWrapper>
      <S.Links>
        <S.Support>
          <S.FaqLink to={"/faq"}>FAQ</S.FaqLink>
          <S.StyledLink to={"/contacts"}>Контакты</S.StyledLink>
        </S.Support>
        <SocialLinks />
      </S.Links>
      <S.SignContainer>
        <SignComponent />
      </S.SignContainer>
    </S.Wrapper>
  );
};
