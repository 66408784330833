import { Modal } from "../../../../Components/Modal/Modal";
import * as S from "./Styles";
import { GreenBtn } from "../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import {
  FormInput,
  ModalDescription,
  ModalHeading,
  WrapperModal
} from "../../../../Styles/components";
import { useMutation } from "@apollo/client";
import { SEND_OUTPUT_DATA } from "../../../../graphql/Mutation";
import { useFormInput } from "../../../../hooks/useFormInput";
import { useMemo, useState } from "react";
import {
  Messages,
  validation as getValidation
} from "../../../../functions/validation";
import { ErrorNotification } from "../../../../Components/InputModal/Main/Styles";
import { Relative } from "../../../Referral/TopContainer/Styles";

export type ModalContainerProps = {
  isOpen: boolean;
  isShow: boolean;
  toggle: () => void;
};

export const ModalContainer = ({
  isOpen,
  isShow,
  toggle,
  gemCount,
  toggleOrder
}: ModalContainerProps & { gemCount: number; toggleOrder: () => void }) => {
  const [values, reset] = useFormInput({
    email: ""
  });
  const [isError, setError] = useState(false);
  const [sendOrder, { loading }] = useMutation(SEND_OUTPUT_DATA, {
    onCompleted: (data) => {
      if (data.outputEmailSum) {
        toggle();
        toggleOrder();
        reset();
      } else {
        setError(true);
      }
    },
    onError: (err) => {
      console.log(err);
      setError(true);
    }
  });
  const [validMessages, setValidMessages] = useState<Messages | null>(null);

  const validation = useMemo(
    () =>
      getValidation({
        optional: []
      }),
    []
  );
  const onClick = async () => {
    const valid = validation({
      ...values.value
    });
    setValidMessages(valid);
    if (valid.isValid) {
      console.log(gemCount);
      await sendOrder({
        variables: {
          sum: gemCount,
          email: values.value.email
        }
      });
    }
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <WrapperModal>
        <ModalHeading>Вывод гемов</ModalHeading>
        <ModalDescription>
          Вывод {gemCount} гемов.
          <br />
          Введите свои данные для совершения операции
        </ModalDescription>
        <S.Content>
          <Relative>
            <FormInput
              name={"email"}
              id={"email"}
              placeholder={"Введите Email"}
              value={values.value.email}
              onChange={values.onChange}
            />
            <ErrorNotification
              $show={
                (!!validMessages && validMessages.email !== "isValid") ||
                isError
              }
            >
              {validMessages && validMessages.email !== "isValid"
                ? validMessages.email
                : ""}
              {isError && "Произошла непредвиденная ошибка, попробуйте позже"}
            </ErrorNotification>
          </Relative>
          <GreenBtn
            $width100={true}
            $notUpper={true}
            $borderWidth={1}
            onClick={onClick}
            disabled={loading || !values.value.email}
          >
            {loading ? "Загрузка" : "Подтвердить"}
          </GreenBtn>
        </S.Content>
      </WrapperModal>
    </Modal>
  ) : null;
};
