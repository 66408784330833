import * as S from "./Styles";
import src from "../../../../assets/imgs/greyLogo.png";
import { ReactElement } from "react";
import { FaQuestion, FaFileAlt, FaEnvelope } from "react-icons/fa";
import { ReactComponent as Handshake } from "../../../../assets/icons/handshake.svg";
import { useNavigate } from "react-router-dom";
import { GradButton } from "../../../../Styles/StyleComponents/GradButton/GradButton";

// type FirstPanelProps = {};

type Link = {
  name: string;
  to: string;
  icon: ReactElement;
};
const links: Link[] = [
  {
    name: "FAQ",
    to: "/faq",
    icon: <FaQuestion />
  },
  {
    name: "Контакты",
    to: "/contacts",
    icon: <FaFileAlt />
  },
  {
    name: "Политика конфиденциальности",
    to: "/privacy-policy",
    icon: <FaFileAlt />
  },
  {
    name: "Пользовательское соглашение",
    to: "/agreement",
    icon: <FaFileAlt />
  },
  {
    name: "brawlcases@mail.ru",
    to: "mailto:brawlcases@mail.ru",
    icon: <FaEnvelope />
  }
];
export const FirstPanel = () => {
  const navigate = useNavigate();
  return (
    <S.Wrapper>
      <S.TopInfo>
        <img src={src} alt={"Brawl Cases Logo"} />
        <S.Heading>2023 Brawl Cases</S.Heading>
      </S.TopInfo>
      <S.ListContainer>
        {links.map(({ name, to, icon }) => (
          <S.ListElt key={to}>
            <S.Link to={to} $isMail={to.startsWith("mailto")}>
              {icon}
              {name}
            </S.Link>
          </S.ListElt>
        ))}
      </S.ListContainer>
      <GradButton onClick={() => navigate("/partners")}>
        <div>
          <Handshake />
          Сотрудничество
        </div>
      </GradButton>
    </S.Wrapper>
  );
};
