import styled from "styled-components";

export const Footer = styled.footer`
  padding: 0 0.7em;

  @media screen and ${({ theme }) => theme.media.lg} {
    padding: 0 1.5em;
  }
`;

export const Wrapper = styled.div`
  margin: 1.5rem 0 3rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 3rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    display: flex;
  }
`;
