import { useEffect, useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import {
  PageHeading,
  PageMarginSection,
  PageWrapper
} from "../../Styles/components";
import * as S from "./Styles";
import { TopContainer } from "./TopContainer/TopContainer";
import { BottomContainer } from "./BottomContainer/BottomContainer";
import { InviteTable } from "./InviteTable/InviteTable";
import { useQuery } from "@apollo/client";
import { GET_ME, IS_LOGGED_IN } from "../../graphql/Query";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../Components/Preloader/Loader/Loader";

// type ReferralProps = {};
export const Referral = () => {
  const { data: me, loading } = useQuery(GET_ME, {
    fetchPolicy: "cache-first"
  });
  const { data } = useQuery(IS_LOGGED_IN);
  const navigate = useNavigate();

  useEffect(() => {
    if (!data?.isLoggedIn) navigate("/");
  }, [data]);
  useLayoutEffect(() => {
    document.title = "Brawl Cases | Реферальная программа";
  }, []);
  useReloadScrollTop();

  if (loading || !me || !me.getMe) return <Loader />;
  return (
    <PageWrapper>
      <PageMarginSection>
        <PageHeading>Реферальная программа</PageHeading>
        <TopContainer referral={me.getMe.invite_code} />
        <BottomContainer />
        <S.TableWrapper>
          <PageHeading>Список приглашенных (100 последних)</PageHeading>
          <InviteTable />
        </S.TableWrapper>
      </PageMarginSection>
    </PageWrapper>
  );
};
