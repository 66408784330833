import logo from "../../../../assets/imgs/greyLogo.png";
import title from "../../../../assets/imgs/banner/bantitle.png";
import * as S from "./Styles";
import { ReactComponent as R } from "../../../../assets/icons/R.svg";

// type HeadProps = {};
export const Head = () => {
  return (
    <S.Wrapper>
      <img src={logo} alt={"Brawl Cases Logo"} />
      <S.FlexDivider>
        <R />
        <img src={title} alt={"Brawl Cases 2023"} />
      </S.FlexDivider>
    </S.Wrapper>
  );
};
