import { FC, memo } from "react";
import * as S from "./Styles";
import { ScreenReader } from "../../../../Styles/utilites";
import { FaAngleDoubleUp } from "react-icons/fa";

type TopSiteLinkProps = {
  isTopLinkVisible: boolean;
  scrollToTop: () => void;
};
const TopSiteLink: FC<TopSiteLinkProps> = ({
  isTopLinkVisible,
  scrollToTop
}) => {
  return (
    <S.Container onClick={scrollToTop} $isVisible={isTopLinkVisible}>
      <ScreenReader>На верх страницы</ScreenReader>
      <FaAngleDoubleUp />
    </S.Container>
  );
};

export const PureTopSiteLink = memo(
  TopSiteLink,
  (prevProps, nextProps) =>
    prevProps.isTopLinkVisible === nextProps.isTopLinkVisible
);
