import * as S from "./Styles";
import { ReactComponent as VK } from "../../assets/icons/social/vk.svg";
import { ReactComponent as Tg } from "../../assets/icons/social/tg.svg";
import { ReactComponent as Tik } from "../../assets/icons/social/tik.svg";
import { ReactComponent as Discord } from "../../assets/icons/social/discord.svg";
import { ReactComponent as Youtube } from "../../assets/icons/social/youtube.svg";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

type SocialLinksProps = {
  isFooter?: boolean;
  isBonus?: boolean;
};
export type SocialType = "vk" | "tg" | "tik" | "youtube" | "discord";
type Social = {
  name: SocialType;
  to: string;
  img: ReactElement;
};
const socials: Social[] = [
  {
    name: "vk",
    to: "https://vk.com/public221759270",
    img: <VK />
  },
  {
    name: "tg",
    to: "https://t.me/brawlcases_admin_bot",
    img: <Tg />
  },
  {
    name: "tik",
    to: "https://www.tiktok.com/@ramzinm691?_t=8eujtkBzUwr",
    img: <Tik />
  },
  {
    name: "youtube",
    to: "https://www.youtube.com/channel/UCWFVADxQEbkNqJCQDxNH2VQ",
    img: <Youtube />
  },
  {
    name: "discord",
    to: "https://discord.gg/Uku2bSNq",
    img: <Discord />
  }
];
export const SocialLinks = ({ isFooter, isBonus }: SocialLinksProps) => {
  return (
    <S.Wrapper $isFooter={isFooter} $isBonus={isBonus}>
      {socials.map(({ to, img, name }) => (
        <S.Social
          key={name}
          $isFooter={isFooter}
          $isBonus={isBonus}
          type={name}
        >
          {isBonus ? (
            <>{img}</>
          ) : (
            <a href={to} target={"_blank"}>
              {img}
            </a>
          )}
        </S.Social>
      ))}
    </S.Wrapper>
  );
};
