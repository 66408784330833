import styled from "styled-components";

export const Table = styled.table`
  margin-top: 2rem;
  text-align: center;
  padding: 1rem;
  border-radius: 2rem;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: ${({ theme }) => theme.colors.bgLayout};
`;

export const Th = styled.th`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey58};
`;

export const Tr = styled.tr`
  &:hover {
    box-shadow: inset 1px 0 0 hsl(152, 9%, 30%),
      inset -1px 0 0 hsl(152, 9%, 30%), 0 1px 2px 0 hsla(210, 6%, 30%, 0.3),
      0 1px 3px 1px hsla(206, 6%, 100%, 0.25);
    z-index: 1;
  }
`;
