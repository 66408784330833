import * as S from "./Styles";
import { ReactElement } from "react";
type DropdownProps = {
  isOpen: boolean;
  array: { content: string | ReactElement; action: () => void }[];
  widthInRem?: number;
};
export const Dropdown = ({ isOpen, array, widthInRem }: DropdownProps) => {
  return (
    <S.Container
      $isOpen={isOpen}
      $widthInRem={widthInRem}
      $isJsxIn={typeof array[0].content !== "string"}
      data-testid={"dropdown"}
    >
      {array.map((elt, i) => (
        <li
          key={i}
          onClick={elt.action}
          data-testid={"dropdown-elt"}
          // isJsxIn={typeof elt.title !== "string"}
        >
          {elt.content}
        </li>
      ))}
    </S.Container>
  );
};
