import master from "../assets/imgs/payments/master.png";
import btc from "../assets/imgs/payments/btc.png";
import alfa from "../assets/imgs/payments/alfa.png";
import mir from "../assets/imgs/payments/mir.png";
import qiwi from "../assets/imgs/payments/qiwi.png";
import sber from "../assets/imgs/payments/sber.png";
import tele2 from "../assets/imgs/payments/tele2.png";
import visa from "../assets/imgs/payments/visa.png";
import youmoney from "../assets/imgs/payments/youmoney.png";

type Payment = {
  name: string;
  img: string;
};
export const paymentsData: Payment[] = [
  {
    name: "master",
    img: master
  },
  {
    name: "visa",
    img: visa
  },
  {
    name: "mir",
    img: mir
  },
  {
    name: "qiwi",
    img: qiwi
  },
  {
    name: "sber",
    img: sber
  },
  {
    name: "alfa",
    img: alfa
  },
  {
    name: "tele2",
    img: tele2
  },
  {
    name: "btc",
    img: btc
  },
  {
    name: "youmoney",
    img: youmoney
  }
];
