import styled, { keyframes } from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-top: 3rem;
  }
`;

type BonusEltProps = {
  $isTickets?: boolean;
};
export const BonusElt = styled.div<BonusEltProps>`
  position: relative;
  overflow: hidden;
  max-width: 15rem;
  flex: 0 0 45%;
  height: 20rem;
  padding: 1rem;
  background: linear-gradient(
    258.27deg,
    rgba(0, 0, 0, 0.77) 13.87%,
    ${({ theme }) => theme.colors.bgLayout} 85.29%
  );

  img {
    z-index: ${({ theme }) => theme.order.one};
    position: absolute;
    right: 0;
    bottom: ${({ $isTickets }) => ($isTickets ? "-5px" : 0)};
    height: ${({ $isTickets }) => ($isTickets ? "75%" : "90%")};
    ${getTransition(500, "transform")}
  }

  &:hover {
    img {
      transform: scale(1.1) rotateZ(2deg);
    }
  }

  @media screen and ${({ theme }) => theme.media.md} {
    flex: 0 0 30%;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    flex: 0 0 23%;
  }
`;

export const Title = styled.h6`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 2rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-right: 1rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-right: 0;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-right: 3rem;
  }
`;

export const Description = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.order.two};
  font-size: 0.85rem;
  line-height: 1.2;
  font-weight: 500;
  margin-right: 3rem;
  text-shadow: 1px 1px 1px black;
`;

const arrowMove = keyframes`
  0%, 50%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(10px);
  }
`;
export const BtnContainer = styled.div`
  z-index: ${({ theme }) => theme.order.two};
  position: absolute;
  left: 1rem;
  bottom: 2rem;
  button {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  svg {
    margin-right: 0;
    vertical-align: -2px;
  }
  &:hover {
    svg {
      animation: ${arrowMove} 0.8s ease;
    }
  }
`;
