import { Item } from "../Item/Item";
import { CardVars } from "../Item/Styles";
import { Result } from "../../../../../gql/graphql";
import { memo, useEffect, useState } from "react";

type ItemsProps = {
  current: Result[];
  next: boolean;
  queueLen: number;
};
const Items = ({ current, next, queueLen }: ItemsProps) => {
  // const [speed, setSpeed] = useState(1);
  // useEffect(() => {
  //   if (queueLen === 0) return;
  //   setSpeed(queueLen);
  // }, [queueLen]);
  return (
    <>
      {current.slice(0, 16).map(({ user, case: caseValue, item, date }, i) => {
        return (
          <Item
            key={`${item.id}`}
            type={item.type as CardVars}
            name={item.name}
            img={process.env.REACT_APP_URI + item.image}
            price={item.price}
            user={{
              id: user.id,
              name: user.first_name
            }}
            caseImg={process.env.REACT_APP_URI + caseValue.image}
            isSide={true}
            isNext={next}
            idx={next ? i - queueLen : i}
            // speed={speed}
          />
        );
      })}
    </>
  );
};

export const PureItems = memo(
  Items,
  (prevProps, nextProps) =>
    prevProps.current === nextProps.current && prevProps.next === nextProps.next
);
