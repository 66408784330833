import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  margin: 3rem 1rem;

  @media screen and ${({ theme }) => theme.media.md} {
    margin: auto 1rem;
  }
`;
