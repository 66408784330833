import * as S from "./Styles";
import { ReactElement } from "react";
import { ReactComponent as Key } from "../../../../assets/icons/topstat/key.svg";
import { ReactComponent as Diamond } from "../../../../assets/icons/topstat/diamond.svg";
import { ReactComponent as Man } from "../../../../assets/icons/topstat/man.svg";
import { getCount } from "../../../../functions/getCount";

type StatisticProps = {
  gems: number;
  users: number;
  cases: number;
};
export type ItemType = "key" | "diamond" | "man";
type Item = {
  type: ItemType;
  name: string;
  img: ReactElement;
};
const items: Item[] = [
  {
    type: "key",
    name: "Кейсов открыто",
    img: <Key />
  },
  {
    type: "diamond",
    name: "Выведено гемов",
    img: <Diamond />
  },
  {
    type: "man",
    name: "Игроков",
    img: <Man />
  }
];

const typeToStat = {
  key: "cases",
  diamond: "gems",
  man: "users"
};
export const Statistic = (props: StatisticProps) => {
  return (
    <S.Wrapper>
      {items.map(({ type, name, img }) => (
        <S.Item key={type} type={type}>
          {img}
          <S.InfoDivider>
            <S.Count>
              {getCount(props[typeToStat[type] as keyof StatisticProps])}
            </S.Count>
            <S.Name>{name}</S.Name>
          </S.InfoDivider>
        </S.Item>
      ))}
    </S.Wrapper>
  );
};
