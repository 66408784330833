import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { baseTheme } from "./Styles/theme";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  split
} from "@apollo/client";
import { createClient } from "graphql-ws";
import { Cache, errorWSCountVar } from "./graphql/Cache";
import { IS_LOGGED_IN } from "./graphql/Query";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";

const uri = process.env.REACT_APP_URI + "graphql/";
const ws = process.env.REACT_APP_WS + "graphql/";
const httpLink = createHttpLink({ uri, credentials: "include" });

let countWSRetry = 0;
const wsClient = createClient({
  url: ws,
  on: {
    connected: () => {
      countWSRetry = 0;
      errorWSCountVar(0);
      console.log("Connection successes.");
    },
    error: () => {
      countWSRetry += 1;
      errorWSCountVar(countWSRetry);
      console.log("Error count: ", countWSRetry);
    }
  },
  shouldRetry: () => true
  // connectionParams: () => {
  //   return {
  //     authorization:
  //       localStorage.getItem("token") || sessionStorage.getItem("token") || ""
  //   };
  // }
});

// const authLink = setContext((_, { headers }) => {
//   return {
//     headers: {
//       ...headers,
//       authorization: localStorage.getItem("token") || ""
//     }
//   };
// });

const wsLink = () => new GraphQLWsLink(wsClient);
const requestLink = ({
  httpLinkQuery,
  subscriptionLink
}: {
  httpLinkQuery: ApolloLink;
  subscriptionLink: GraphQLWsLink;
}) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    subscriptionLink,
    httpLinkQuery
  );

const client = new ApolloClient({
  link: requestLink({
    httpLinkQuery: httpLink,
    subscriptionLink: wsLink()
  }),
  cache: Cache
});

client.cache.writeQuery({
  query: IS_LOGGED_IN,
  data: {
    isLoggedIn: localStorage.getItem("isAuth") === "true"
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyleSheetManager enableVendorPrefixes={true}>
      <ThemeProvider theme={baseTheme}>
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <App />
            </Router>
          </ApolloProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyleSheetManager>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
