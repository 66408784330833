import { PropsWithChildren, useEffect } from "react";
import * as S from "./Styles";
import { Sidebar } from "./Sidebar/Sidebar";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { Toastify } from "../Toastify2/Toastify";
import { useQuery } from "@apollo/client";
import { GET_ME, GET_STATS, IS_LOGGED_IN } from "../../graphql/Query";
import { useToggleVisible } from "../../hooks/useToggleVisible";
import { ReferralModal } from "./ModalReferral/ReferralModal";
import { OrderModal } from "../OrderModal/OrderModal";

export const Layout = ({ children }: PropsWithChildren) => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isOpenOrder, isShowOrder, toggleOrder] = useToggleVisible(300);
  const { data: isAuth } = useQuery(IS_LOGGED_IN);
  const { data: me } = useQuery(GET_ME, { fetchPolicy: "cache-first" });
  const { data } = useQuery(GET_STATS, {
    pollInterval: 4000
  });

  useEffect(() => {
    if (
      !localStorage.getItem("referralCode") ||
      !isAuth?.isLoggedIn ||
      !me ||
      !me.getMe ||
      me.getMe.isOld ||
      me.getMe.invite_code.toLowerCase() ===
        localStorage.getItem("referralCode")
    )
      return;
    toggle();
  }, []);

  useEffect(() => {
    if (
      isAuth?.isLoggedIn &&
      me &&
      me.getMe &&
      me.getMe.orderStatus.status &&
      !isOpenOrder
    ) {
      console.log(me.getMe.orderStatus.status);
      toggleOrder();
    }
  }, [isAuth, me, toggleOrder]);

  return (
    <S.Wrapper>
      <Sidebar online={data && data.statistic ? data.statistic.online : 0} />
      <S.Container>
        <Header
          gems={data && data.statistic ? data.statistic.gems : 0}
          users={data && data.statistic ? data.statistic.users : 0}
          cases={data && data.statistic ? data.statistic.cases : 0}
        />
        <main>{children}</main>
        <Footer />
      </S.Container>
      <Toastify />
      <ReferralModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
      <OrderModal
        isShow={isShowOrder}
        isOpen={isOpenOrder}
        toggle={toggleOrder}
      />
    </S.Wrapper>
  );
};
