import { useApolloClient } from "@apollo/client";
import { IS_LOGGED_IN } from "../graphql/Query";
import { useNavigate } from "react-router-dom";

export function useLogout(force?: boolean) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const logOut = () => {
    client.cache.evict({ fieldName: "getMe" });
    client.cache.gc();

    localStorage.removeItem("isAuth");

    client.cache.writeQuery({
      query: IS_LOGGED_IN,
      data: {
        isLoggedIn: false
      }
    });

    if (force) navigate("/");
  };
  return logOut;
}
