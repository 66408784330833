import styled, { css } from "styled-components";
import { getTransition } from "../../../../Styles/utilites";
type ContainerProps = {
  $isVisible: boolean;
};
export const Container = styled.span<ContainerProps>`
  ${({ $isVisible }) =>
    $isVisible
      ? css`
          transform: translateY(0);
        `
      : css`
          transform: translateY(5rem);
        `};
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  z-index: ${({ theme }) => theme.order.fixed};
  cursor: pointer;
  ${getTransition(300, ["transform", "box-shadow"], "ease-out")}
  padding: .5em;
  border-radius: 0.3em;
  background-color: ${({ theme }) => theme.colors.brightPurple};
  box-shadow: 0 1px 10px 2px hsla(${({ theme }) => theme.colors.fontValue}, 0.3);
  svg {
    vertical-align: -5px;
    margin-right: 0;
    font-size: 1.5rem;
  }
  &:hover {
    box-shadow: 0 1px 10px 10px
      hsla(${({ theme }) => theme.colors.brightPurpleValue}, 0.5);
  }
`;
