import { Modal } from "../../../../Components/Modal/Modal";
import * as S from "./Styles";

export type ModalContainerProps = {
  isOpen: boolean;
  isShow: boolean;
  toggle: () => void;
};

const rules = [
  "Выберите подходящую позицию из представленных, предварительно убедившись, что на вашем балансе достаточно средств",
  "При наведении на выбраную позицию должна появиться надпись 'Вывести', при нажатии на позицию откроется окно вывода",
  "Вам будет предложено ввести свой email и код - сделайте это",
  "Далее модератор рассмотрит вашу заявку и выполнит ее в разумный срок. После чего вы получите уведомление"
];
export const ModalContainer = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <S.Heading>Торговая площадка</S.Heading>
        <S.Description>
          Внимательно изучите правила использования торговой площадки, перед
          совершением действий
        </S.Description>
        <S.Rules>
          {rules.map((r, i) => (
            <li key={i}>{r}</li>
          ))}
        </S.Rules>
      </S.Wrapper>
    </Modal>
  ) : null;
};
