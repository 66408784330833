import styled from "styled-components";

export const Wrapper = styled.div`
  grid-area: sidebar;
  display: flex;
  flex-direction: row;

  @media screen and ${({ theme }) => theme.media.sm} {
    // TODO пока это лучшее решение
    position: sticky;
    top: 0;
    overflow: hidden;
    max-height: 100vh;
    flex-direction: column;
  }
`;
