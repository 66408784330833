import { ModalContainerProps } from "../../../Marketplace/GemCase/ModalContainer/ModalContainer";
import { Modal } from "../../../../Components/Modal/Modal";
import * as S from "./Styles";
import { ModalDescription, ModalHeading } from "../../../../Styles/components";
import { GradButton } from "../../../../Styles/StyleComponents/GradButton/GradButton";
import { ReactComponent as Warning } from "../../../../assets/icons/warning.svg";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ME } from "../../../../graphql/Query";
import { OneTimeBonus } from "../../../../gql/graphql";
import { GET_YT_BONUS } from "../../../../graphql/Mutation";

const tasks = [
  {
    type: "vk",
    title: "Подписаться на нашу страницу в VK",
    cost: 2
  },
  {
    type: "tg",
    title: "Подписаться на канал в Tелеграм",
    cost: 2
  },
  {
    type: "tt",
    title: "Подписаться на канал в TikTok",
    cost: 2
  },
  {
    type: "youtube",
    title: "Подписаться на канал в YouTube",
    cost: 1
  },
  {
    type: "ds",
    title: "Подписаться на канал в Discord",
    cost: 2
  }
];
export const ModalContainer = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const { data } = useQuery(GET_ME);
  const [getYtBonus] = useMutation(GET_YT_BONUS);
  const onClick = async (type: string) => {
    if (type === "vk") {
      window.location.href = "https://brawl-test.onrender.com/vk/member/";
    } else if (type === "youtube") {
      const url = await getYtBonus();
      if (!url.data) return;
      window.location.href = url.data.youtube_bonus;
    }
  };
  return isOpen && data && data.getMe ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Разовые бонусы</ModalHeading>
        <ModalDescription>
          Выполните задание ниже и получите дополнительные бонусы
        </ModalDescription>
        <S.Content>
          {tasks.map(({ title, cost, type }) => (
            <S.Block key={type}>
              <S.FlexRow
                $isDone={!!data.getMe!.bonus_one[type as keyof OneTimeBonus]}
              >
                <Warning />
                <span>{title}</span>
                <S.Price>{cost}₽</S.Price>
              </S.FlexRow>
              <S.BtnContainer>
                <GradButton
                  $variant={"orange"}
                  onClick={() => onClick(type)}
                  disabled={!!data.getMe!.bonus_one[type as keyof OneTimeBonus]}
                >
                  <div>Подписаться</div>
                </GradButton>
              </S.BtnContainer>
            </S.Block>
          ))}
        </S.Content>
      </S.Wrapper>
    </Modal>
  ) : null;
};
