import styled from "styled-components";
import {
  getTransition,
  UniversalButton
} from "../../../../../../Styles/utilites";

export const Wrapper = styled.div`
  text-align: center;
  margin: 2rem 1rem 1rem;

  @media screen and ${({ theme }) => theme.media.md} {
    margin: auto 1rem;
  }
`;

export const Text = styled.div`
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 1rem 2rem 0;
  }
`;

export const BtnsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    margin-top: 4rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-top: 2rem;
  }
`;

export const ConfirmBtn = styled(UniversalButton)`
  color: ${({ theme }) => theme.colors.font};
  background-color: ${({ theme }) => theme.colors.greenGrad};
  margin: 0 4rem 1rem;
  width: 100%;
  &:hover {
    opacity: 0.6;
  }
  ${getTransition(300, "opacity")}
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 0;
    margin-right: 1.5rem;
    width: auto;
  }
`;

export const CancelBtn = styled(UniversalButton)`
  color: ${({ theme }) => theme.colors.font};
  background-color: ${({ theme }) => theme.colors.grey45};
  width: 100%;
  &:hover {
    opacity: 0.6;
  }
  ${getTransition(300, "opacity")}
  @media screen and ${({ theme }) => theme.media.sm} {
    width: auto;
  }
`;
