import { useEffect, useState } from "react";

export const useScrollWindowListener = () => {
  const [pageY, setPageY] = useState(0);

  const eventHandler = (e: Event) => {
    if (e.currentTarget instanceof Window) {
      setPageY(e.currentTarget.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);
    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  }, []);

  return [pageY];
};
