import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState
} from "react";
import { v4 } from "uuid";
import { ToastifyVars } from "../Toastify2/Toastify";

type Toast = {
  type: string;
  id: string;
};
type Context = {
  toastifyArr: Toast[];
  setToastifyArr: (arg: Toast[]) => void;
  pushToast: (arg: ToastifyVars) => void;
};
type AppContextProps = {
  value?: Partial<Context>;
};

const toastifyArr: Toast[] = [];

const initValue: Context = {
  toastifyArr,
  setToastifyArr: (arg: Toast[]) => {},
  pushToast: (arg: ToastifyVars) => {}
};
const AppContext = createContext<Context>(initValue);

export const useAppContext = () => useContext(AppContext);
export const AppContextProvider = ({
  children,
  value
}: PropsWithChildren<AppContextProps>) => {
  const [toastifyArr, setToastifyArr] = useState<Toast[]>([]);
  const pushToast = useCallback(
    (type: string) => {
      if (toastifyArr.length > 10) return;
      setToastifyArr([{ type, id: v4() }, ...toastifyArr]);
    },
    [toastifyArr]
  );
  const initValue = {
    toastifyArr,
    setToastifyArr,
    pushToast,
    ...value
  };
  return (
    <AppContext.Provider value={initValue}>{children}</AppContext.Provider>
  );
};
