import { ModalContainerProps } from "../../../../../Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../../../../../Components/Modal/Modal";
import * as S from "../../Convert/ConfirmModal/Styles";
import {
  FormInput,
  ModalDescription,
  ModalHeading
} from "../../../../../../Styles/components";
import { GreenBtn } from "../../../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { Content } from "../../../../../Marketplace/GemCase/ModalContainer/Styles";

export const OutputModal = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Вывод реферального баланса</ModalHeading>
        <ModalDescription>
          Укажите реквизиты для вывода вашего реферального баланса.
        </ModalDescription>
        <Content>
          <FormInput
            name={"card"}
            id={"card"}
            type={"number"}
            placeholder={"Введите номер банковской карты"}
            required
          />
          <GreenBtn $width100={true} $notUpper={true} $borderWidth={1}>
            Подтвердить
          </GreenBtn>
        </Content>
      </S.Wrapper>
    </Modal>
  ) : null;
};
