import styled from "styled-components";
import { getTransition } from "../../Styles/utilites";

type LogoProps = {
  $isHeader?: boolean;
};
export const Logo = styled.div<LogoProps>`
  display: ${({ $isHeader }) => ($isHeader ? "block" : "none")};
  visibility: ${({ $isHeader }) => ($isHeader ? "visible" : "hidden")};
  padding: 0.5rem;
  img {
    cursor: pointer;
    height: ${({ $isHeader }) => ($isHeader ? "4rem" : "5.5rem")};
    width: auto;
    ${getTransition(300, "transform")}
  }
  img:hover {
    transform: scale(1.05);
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    display: ${({ $isHeader }) => ($isHeader ? "none" : "block")};
    visibility: ${({ $isHeader }) => ($isHeader ? "hidden" : "visible")};
    padding: 1rem;
  }
`;
