import { ModalHeading } from "../../../Styles/components";
import * as S from "./Styles";
import { FaArrowLeft } from "react-icons/fa";
import { AgreementBody } from "../../../pages/agreement/AgreementBody/AgreementBody";

type AgreementProps = {
  back: () => void;
};
export const Agreement = ({ back }: AgreementProps) => {
  return (
    <>
      <ModalHeading>Пользовательское соглашение</ModalHeading>
      <S.Back onClick={back}>
        <FaArrowLeft />
        Назад
      </S.Back>

      <S.Content>
        <AgreementBody />
      </S.Content>
    </>
  );
};
