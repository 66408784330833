import * as S from "./Styles";
import { SocialLinks } from "../../../SocialLinks/SocialLinks";
import { paymentsData } from "../../../../data/paymentsData";

// type SecondPanelProps = {};

export const SecondPanel = () => {
  return (
    <S.Wrapper>
      <S.LinksContainer>
        <S.Text>Мы в соц. сетях:</S.Text>
        <SocialLinks isFooter={true} />
      </S.LinksContainer>
      <S.Payments>
        {paymentsData.map(({ img, name }) => (
          <S.PayElt key={name}>
            <img src={img} alt={name} />
          </S.PayElt>
        ))}
      </S.Payments>
        <a href="https://freekassa.ru" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn.freekassa.ru/banners/big-dark-1.png" title="Прием платежей на сайте для физических лиц и т.д."/>
        </a>
    </S.Wrapper>
  );
};
