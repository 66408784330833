import styled from "styled-components";
import { getTransition } from "../../../../Styles/utilites";

export const Wrapper = styled.div`
  text-align: center;
  margin: 2rem 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 3rem 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  max-height: 70vh;
  overflow-y: scroll;
  padding-right: 5px;
  margin-top: 1rem;
  > div:first-child {
    margin-top: 0;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    overflow-y: initial;
    max-height: 35rem;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  padding: 0.5em 1em;
  background: linear-gradient(90deg, #44312a 0%, #41412a 99.42%);
  margin-top: 1rem;
  gap: 0.5rem;
  svg {
    flex: 0 0;
    flex-basis: 3rem;
    transform: scale(0.9);
    margin: -2rem 1rem -2rem 0;
    filter: grayscale();
    ${getTransition(300, "filter")}
  }
  &:hover {
    svg {
      filter: none;
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    flex-direction: row;
  }
`;

type FlexRowProps = {
  $isDone?: boolean;
};
export const FlexRow = styled.div<FlexRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: ${({ $isDone }) => ($isDone ? "line-through" : "none")} 3px;
  text-decoration-color: ${({ theme }) => theme.colors.orange};
  span {
    font-size: 0.85rem;
    margin-right: auto;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    span {
      font-size: 1rem;
    }
  }
`;

export const BtnContainer = styled(FlexRow)`
  button {
    border: 1px solid ${({ theme }) => theme.colors.brightOrange};
    background: transparent;
    width: 100%;
    &:hover {
      border-color: ${({ theme }) => theme.colors.orange};
      background: ${({ theme }) => theme.colors.orange};
    }
    ${getTransition(300, ["background", "border-color"])}
  }
  @media screen and (max-width: 420px) {
    button {
      padding: 0.3rem;
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    flex: 0 1;
    button {
      width: initial;
    }
  }
`;

export const Price = styled.div`
  color: ${({ theme }) => theme.colors.border};
  font-weight: 600;
  margin-left: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-right: 1rem;
  }
`;
