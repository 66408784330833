import styled from "styled-components";
import bg from "../../../assets/imgs/banner/bannerBG.png";

export const Wrapper = styled.div`
  display: none;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 1rem;
  background-image: url(${() => bg});
  background-color: rgba(0, 0, 0, 0.85);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-blend-mode: color;
  height: auto;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: block;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    padding: 1.5rem;
  }
`;
