import styled from "styled-components";
import { DividerStyle } from "../Styles";

export const Balance = styled.div`
  ${DividerStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-right: 1rem;
    margin-bottom: 0;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    flex-direction: row;
    justify-content: space-around;
    height: 5.5rem;
  }
`;

export const WalletDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  font-weight: bold;
  svg {
    margin-right: 1em;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-bottom: 0;
  }
`;

export const BalanceValue = styled.div`
  font-size: 1.5rem;
  line-height: 1;
`;

export const BtnContainer = styled.div`
  svg {
    transform: rotateZ(90deg);
  }
  @media screen and ${({ theme }) => theme.media.md} {
    button {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    svg {
      transform: none;
    }
  }
`;

export const RefBalance = styled(Balance)`
  margin-top: 1rem;
  margin-right: 0;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-left: 1rem;
    margin-top: 0;
  }
`;

export const TextRef = styled.div`
  margin-left: 1rem;
  line-height: 1;
`;
