import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  > img {
    height: 4rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 0;
    > img {
      height: auto;
    }
  }
`;
export const FlexDivider = styled.div`
  margin-left: 1em;
  display: flex;
  img {
    margin-left: 0.5em;
    margin-top: -0.5em;
    height: 4.5rem;
  }
`;
