import { FaDollarSign } from "react-icons/fa";
import * as S from "../Styles";
import { ToastifyVars } from "../../../../../Components/Toastify2/Toastify";
import { useAppContext } from "../../../../../Components/AppContext/AppContext";
import { CardData } from "../../../../../gql/graphql";
import { useToggleVisible } from "../../../../../hooks/useToggleVisible";
import { ConfirmModal } from "./ConfirmModal/ConfirmModal";

type SellButtonProps = {
  userId: string;
  items: CardData[];
};
export const SellButton = ({ userId, items }: SellButtonProps) => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const { pushToast } = useAppContext();

  const onClick = async () => {
    if (items.length) {
      toggle();
    } else pushToast(ToastifyVars.noThing);
  };
  return (
    <S.SellBtn onClick={onClick}>
      <div>
        <FaDollarSign />
        Продать все
      </div>
      <ConfirmModal
        isOpen={isOpen}
        isShow={isShow}
        toggle={toggle}
        userId={userId}
      />
    </S.SellBtn>
  );
};
