import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0;
  padding: 0;

  z-index: 1200;
  position: fixed;
  top: 0;
  right: 0;
`;
