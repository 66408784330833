import { ModalContainerProps } from "../../../Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../../../Components/Modal/Modal";
import * as S from "./Styles";
import { ReactComponent as Copy } from "../../../../assets/icons/referral/copy.svg";
import {
  FormInput,
  ModalDescription,
  ModalHeading
} from "../../../../Styles/components";
import { Content } from "../ModalContainer/Styles";
import { Relative } from "../../../Referral/TopContainer/Styles";
import { ToastifyVars } from "../../../../Components/Toastify2/Toastify";
import { useAppContext } from "../../../../Components/AppContext/AppContext";
import { GreenBtn } from "../../../../Styles/StyleComponents/GreenBtn/GreenBtn";

export const StartModal = ({
  isOpen,
  isShow,
  toggle,
  onPay
}: ModalContainerProps & { onPay: () => void }) => {
  const { pushToast } = useAppContext();
  const onClick = (text: string) => {
    navigator.clipboard.writeText(text);
    pushToast(ToastifyVars.copy);
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Стартовый бонус!</ModalHeading>
        <ModalDescription>
          Получите свой стартовый бонус введя промокод ниже. <br />
          При пополнении от 500 рублей вам будет доступно 10 открытий любых
          кейсов с вероятностью x2 в течении 24 часов. Воспользуйтесь
          промокодом, вставиви его в поле "Промокод" при пополнении баланса и
          нажав на кнопку в правой части поля ввода.
        </ModalDescription>
        <Content>
          <Relative>
            <FormInput
              name={"promoCode"}
              id={"promoCode"}
              type={"text"}
              placeholder={"Промокод"}
              value={"DOUBLE10"}
              readOnly
            />
            <Copy onClick={() => onClick("DOUBLE10")} />
          </Relative>
          <GreenBtn onClick={onPay}>К пополнению</GreenBtn>
        </Content>
      </S.Wrapper>
    </Modal>
  ) : null;
};
