import styled, { css } from "styled-components";
import { getTransition } from "../../Styles/utilites";

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin: 0 0.5em;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-left: 1.5rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-left: 4.5rem;
  }
`;

export const SearchContainer = styled.div`
  order: 3;
  margin-top: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 50vw;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    order: 1;
    margin-left: 1.5rem;
    margin-top: 0;
    width: 35vw;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-left: 4.5rem;
  }
`;

export const GreyContainer = css`
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey37};
  background-color: ${({ theme }) => theme.colors.bgLayout};
  color: ${({ theme }) => theme.colors.font};
  padding: 0.85em 1em;
`;
export const SearchInput = styled.input`
  ${GreyContainer};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  flex: 1 1 auto;
  width: 100%;
  padding-right: 0;
  &:focus-visible {
    outline: none;
  }
`;

export const InputSpan = styled.span`
  ${GreyContainer};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1em 1em;
`;

type SortContainerProps = {
  $isMenuOpen: boolean;
};
export const SortContainer = styled.div<SortContainerProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  ${GreyContainer};
  width: 100%;
  text-align: center;
  justify-content: center;
  span > svg {
    margin-left: 0.75em;
    transform: rotateZ(${({ $isMenuOpen }) => ($isMenuOpen ? "180deg" : 0)});
    ${getTransition(300, "transform")}
  }
`;

type DirectionDividerProps = {
  $isDes: boolean;
};
export const DirectionDivider = styled.div<DirectionDividerProps>`
  svg {
    margin-right: 0.75em;
    transform: rotateZ(${({ $isDes }) => ($isDes ? 0 : "180deg")})
      rotateY(${({ $isDes }) => ($isDes ? 0 : "180deg")});
  }
`;

export const RelativeDivider = styled.div`
  position: relative;
  order: 2;
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    width: 50%;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    width: 40%;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-right: 10%;
    flex: 0 1;
    min-width: 15rem;
    margin-top: 0;
    margin-left: 1.5rem;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-right: 20%;
  }
`;
