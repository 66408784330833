import * as S from "./Styles";
import src from "../../assets/imgs/logo480.png";
import * as React from "react";
import { Link } from "react-router-dom";

type LogoProps = {
  isHeader?: boolean;
};
export const Logo = ({ isHeader }: LogoProps) => {
  return (
    <S.Logo
      $isHeader={isHeader}
      data-testid={isHeader ? "logo-header" : "logo-panel"}
    >
      <Link to={"/"}>
        <img src={src} alt={"Brawl Cases Logo"} />
      </Link>
    </S.Logo>
  );
};
