import { useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { Wrapper } from "../agreement/Styles";
import { PageHeading } from "../../Styles/components";
import * as S from "./Styles";
import { Accordion, Items } from "../../Components/Accordion/Accordion";

//type FaqProps = {};

const items: Items = [
  {
    title: "Что такое инвайт-код?",
    body: "Инвайт-код — это код, который ваш знакомый может вставить в соответствующей форме ввода, что даст вам бонус при каждом пополнении счета, приглашенным пользователем."
  },
  {
    title: "Зачем нужна реферальная ссылка?",
    body: "Реферальная ссылка автоматизирует ввод вашего ивайт-кода пользователем. Ему требуется только перейти по ссылке и подтвердить свое согласие на приглашение."
  },
  {
    title: "Как мне получить бонус за приглашение?",
    body: "Бонусы начисляются автоматически, когда приглашенный вами пользователь пополняет баланс на сайте."
  },
  {
    title: "Что такое инвайт-код?",
    body: "Инвайт-код — это код, который ваш знакомый может вставить в соответствующей форме ввода, что даст вам бонус при каждом пополнении счета, приглашенным пользователем."
  },
  {
    title: "Зачем нужна реферальная ссылка?",
    body: "Реферальная ссылка автоматизирует ввод вашего ивайт-кода пользователем. Ему требуется только перейти по ссылке и подтвердить свое согласие на приглашение."
  },
  {
    title: "Как мне получить бонус за приглашение?",
    body: "Бонусы начисляются автоматически, когда приглашенный вами пользователь пополняет баланс на сайте."
  },
  {
    title: "Что такое инвайт-код?",
    body: "Инвайт-код — это код, который ваш знакомый может вставить в соответствующей форме ввода, что даст вам бонус при каждом пополнении счета, приглашенным пользователем."
  },
  {
    title: "Зачем нужна реферальная ссылка?",
    body: "Реферальная ссылка автоматизирует ввод вашего ивайт-кода пользователем. Ему требуется только перейти по ссылке и подтвердить свое согласие на приглашение."
  },
  {
    title: "Как мне получить бонус за приглашение?",
    body: "Бонусы начисляются автоматически, когда приглашенный вами пользователь пополняет баланс на сайте."
  }
];
const Faq = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | FAQ";
  }, []);
  useReloadScrollTop();
  return (
    <Wrapper>
      <PageHeading>FAQ</PageHeading>
      <S.SubTitle>Часто задаваемые вопросы</S.SubTitle>
      <S.QuestionsContainer>
        <Accordion items={items} />
      </S.QuestionsContainer>
    </Wrapper>
  );
};
export default Faq;
