import { DependencyList, useEffect } from "react";

export const useReloadScrollTop = (deps?: DependencyList) => {
  useEffect(
    () => {
      window.scroll(0, 0);
      const initScroll = () => window.scroll(0, 0);
      window.addEventListener("load", initScroll);
      return () => window.removeEventListener("load", initScroll);
    },
    !!deps ? deps : []
  );
};
