import styled from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const Wrapper = styled.div`
  margin-bottom: 5rem;
`;

export const MainNav = styled.ul`
  list-style: none;
  display: none;
  margin: 5rem 0 0;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  @media screen and ${({ theme }) => theme.media.sm} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    padding: 1rem 1.5rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    padding: 1rem 8rem;
  }
`;

export const NavItem = styled.li`
  margin: 0.5em 2em;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.font};
      ${getTransition(300, "fill")}
    }
    margin-right: 1em;
  }
  a {
    color: ${({ theme }) => theme.colors.font};
    text-decoration: none;
    ${getTransition()}
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.yellow};
      }
    }
    a {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
`;
