import { PageWrapper } from "../../Styles/components";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import * as S from "./Styles";
import { gemsData } from "../../data/gemsData";
import _ from "lodash";
import { GemCase } from "./GemCase/GemCase";
import { Help } from "./Help/Help";
import { useLayoutEffect } from "react";

// type MarketplaceProps = {};
const Marketplace = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | Торговая площадка";
  }, []);
  useReloadScrollTop();
  return (
    <PageWrapper>
      <S.Heading>Торговая площадка</S.Heading>
      <S.Wrapper>
        {_(gemsData)
          .chunk(2)
          .value()
          .map(([a, b], i) => (
            <S.PairContainer key={i}>
              <GemCase gem={a} isMargin={true} />
              <GemCase gem={b} />
            </S.PairContainer>
          ))}
      </S.Wrapper>
      <Help />
    </PageWrapper>
  );
};
export default Marketplace;
