import styled from "styled-components";
import { getTransition } from "../../../Styles/utilites";

export const Content = styled.div`
  font-size: 0.85rem;
  text-align: justify;
  height: 77vh;
  overflow-y: scroll;
  line-height: 1.2;
  padding-right: 0.25em;
  @media screen and ${({ theme }) => theme.media.sm} {
    height: 35rem;
  }
`;

export const Back = styled.div`
  position: absolute;
  margin: 1rem;
  top: 0;
  left: 5px;
  z-index: ${({ theme }) => theme.order.two};
  color: ${({ theme }) => theme.colors.grey58};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  svg {
    margin-right: 0.5rem;
    vertical-align: -3px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.grey85};
  }
  ${getTransition()}
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 1.2rem;
    top: 8px;
    left: 15px;
  }
`;
