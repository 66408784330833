import styled, { css } from "styled-components";

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.yellow};
  font-size: 1.25rem;
  font-weight: 500;
`;

export const TopContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

export const BottomContainer = styled(TopContainer)`
  @media screen and ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;

export const DividerStyle = css`
  height: 11rem;
  width: 100%;
  padding: 0.75rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.bgLayout};
`;
