import * as React from "react";
import { useEffect, useState } from "react";
import * as S from "./Styles";
import { Loader } from "./Loader/Loader";

export const Preloader = () => {
  // const [showLoader, setShowLoader] = useState(true);
  // const [isLoded, setIsLoded] = useState(false);
  //
  // useEffect(() => {
  //   window.addEventListener("load", () => {
  //     setIsLoded(true);
  //   });
  // }, []);
  //
  // useEffect(() => {
  //   if (!isLoded) return;
  //   const timer = setTimeout(() => {
  //     setShowLoader(false);
  //   }, 300);
  //   return () => clearTimeout(timer);
  // }, [isLoded]);
  const [showLoader, setShowLoader] = useState(true);
  const [isLoded, setIsLoded] = useState(false);

  useEffect(() => {
    window.addEventListener("load", () => {
      setIsLoded(true);
    });

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showLoader && (
        <S.Wrapper id="preloader" $isLoaded={isLoded}>
          <Loader isFull={true} />
        </S.Wrapper>
      )}
    </>
  );
};
