import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: left;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin-bottom: 0.8rem;
  }
`;

export const Button = styled.button`
  padding: 0.9em 7em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.font};
  border: none;
  background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.orange} 0%,
      ${({ theme }) => theme.colors.brightBlue} 100%
    ),
    ${({ theme }) => theme.colors.grey45};
  border-radius: 0.9em;
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 0.9em 10em;
  }
`;

export const TimerDivider = styled.div`
  position: relative;
`;

export const PercentFigure = styled.div`
  z-index: 1;
  position: absolute;
  right: -45px;
  top: 15px;
  @media screen and ${({ theme }) => theme.media.lg} {
    right: -60px;
    top: 0px;
  }
`;

export const LittleMan = styled.img`
  z-index: ${({ theme }) => theme.order.two};
  transform: scale(0.5);
  position: absolute;
  right: calc(-240px + 10vw);
  bottom: calc(-250px + 10vw);
  @media screen and ${({ theme }) => theme.media.lg} {
    right: calc(-420px + 40vw);
    bottom: -100px;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    right: calc(-200px + 20vw);
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    right: calc(-700px + 50vw);
  }
`;

export const BigMan = styled.img`
  position: absolute;
  transform: rotateY(180deg) scale(0.4);
  left: calc(-320px + 10vw);
  bottom: calc(-400px + 10vw);
  @media screen and ${({ theme }) => theme.media.lg} {
    transform: scale(0.5);
    left: initial;
    right: calc(-500px + 20vw);
    bottom: -250px;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    transform: scale(0.6);
  }
`;
