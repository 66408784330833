import { GemCase as GemCaseType } from "../../../data/gemsData";
import * as S from "./Styles";
import { GreenBtn } from "../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { useAppContext } from "../../../Components/AppContext/AppContext";
import { ToastifyVars } from "../../../Components/Toastify2/Toastify";
import { useToggleVisible } from "../../../hooks/useToggleVisible";
import { ModalContainer } from "./ModalContainer/ModalContainer";
import { useQuery } from "@apollo/client";
import { GET_ME, IS_LOGGED_IN } from "../../../graphql/Query";
import { useState } from "react";
import { OrderModal } from "../../../Components/OrderModal/OrderModal";
import { useTimeout } from "../../../hooks/useTimeout";

type GemCaseProps = {
  isMargin?: boolean;
  gem: GemCaseType;
};

export const GemCase = ({ gem, isMargin }: GemCaseProps) => {
  const [gemCount, setGemCount] = useState(0);
  const { data } = useQuery(IS_LOGGED_IN);
  const { data: me } = useQuery(GET_ME);
  const { pushToast } = useAppContext();
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isOpenOrder, isShowOrder, toggleOrder] = useToggleVisible(300);
  const [set] = useTimeout(toggleOrder, 310);
  const onClick = (count: number) => {
    if (!data?.isLoggedIn || !me || !me.getMe) pushToast(ToastifyVars.noAuth);
    else if (me.getMe.balance < gem.price) pushToast(ToastifyVars.noMoney);
    else {
      setGemCount(count);
      toggle();
    }
  };
  return (
    <>
      <S.Wrapper $isMargin={isMargin} onClick={() => onClick(gem.count)}>
        <S.Divider>
          <S.Price>{gem.count}</S.Price>
          <S.Container>
            <img
              src={gem.img}
              alt={gem.count + " гемов"}
              loading={"lazy"}
              decoding={"async"}
            />
          </S.Container>
          <GreenBtn size={"small"} $isMarket={true} $width100={true}>
            {gem.price},00 ₽
          </GreenBtn>
        </S.Divider>
      </S.Wrapper>
      <ModalContainer
        isOpen={isOpen}
        isShow={isShow}
        toggle={toggle}
        toggleOrder={set}
        gemCount={gemCount}
      />
      <OrderModal
        isShow={isShowOrder}
        isOpen={isOpenOrder}
        toggle={toggleOrder}
      />
    </>
  );
};
