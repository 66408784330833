import { InMemoryCache, makeVar, Reference } from "@apollo/client";
import { SafeReadonly } from "@apollo/client/cache/core/types/common";

export const Cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getItemsUser: {
          //чувствительность кэша поля к аргументам запроса
          keyArgs: ["id"],
          merge(existing, incoming) {
            return mergeFuncForItems(existing, incoming);
          }
        },
        errorWSCountVar: {
          read() {
            return errorWSCountVar();
          }
        },
        isSlowOpeningVar: {
          read() {
            return isSlowOpeningVar();
          }
        }
      }
    }
  }
});

const mergeFuncForItems = (existing: any, incoming: SafeReadonly<any>) => {
  //строка ниже, как напоминание - если произвести выход из мердж ф-ции, то
  // происходит рефетч данных с сервера! А не чтение из кэша.
  // if (incoming && incoming.messages.length > 30) return;

  let items: Reference[] = [];
  if (existing && existing["items"]) {
    items = items.concat(existing["items"]);
  }
  if (incoming && incoming["items"]) {
    items = items.concat(incoming["items"]);
  }
  return {
    ...incoming,
    items
  };
};

export const errorWSCountVar = makeVar(0);
export const isSlowOpeningVar = makeVar(false);
