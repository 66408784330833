import styled from "styled-components";

export const Wrapper = styled.div`
  flex: 1 1;
  margin-top: 3rem;
  >a { 
    display: block;
    text-align: left; 
    margin-top: 2rem;
    width: 170px;
    height: 60px;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: 1rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    flex: initial;
    margin: 1rem auto 0;
    padding-left: 8rem;
  }
`;

export const LinksContainer = styled.div`
  @media screen and ${({ theme }) => theme.media.sm} {
    display: flex;
    align-content: center;
  }
`;
export const Text = styled.div`
  margin-bottom: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    line-height: 2;
    margin-bottom: 0;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 0.9rem;
    margin: 0 -0.5em;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    font-size: 1rem;
    margin: 0;
  }
`;

export const Payments = styled.ul`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  max-width: 500px;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 1rem;
  }
`;

export const PayElt = styled.li`
  margin-top: 1.5rem;
  margin-right: 2rem;
`;
