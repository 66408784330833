import { PageHeading } from "../../../Styles/components";
import * as S from "./Styles";

//type ContactsProps = {};
export const Contacts = () => {
  return (
    <>
      <PageHeading>Контакты</PageHeading>
      <S.Text>
        <S.Heading>Наименование компании</S.Heading>
        <S.Paragraph>
          ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ БЕЛЯКОВ КОНСТАНТИН ВАДИМОВИЧ
        </S.Paragraph>
        <S.Heading>Юридический адрес организации</S.Heading>
        <S.Paragraph>
          170026, РОССИЯ, ТВЕРСКАЯ ОБЛ, Г ТВЕРЬ, УЛ МУСОРГСКОГО, Д 17, КВ 7
        </S.Paragraph>
        <S.Heading>ИНН</S.Heading>
        <S.Paragraph>695208886126</S.Paragraph>
        <S.Heading>ОГРН</S.Heading>
        <S.Paragraph>323695200003511</S.Paragraph>
        <S.Heading>Расчетный счет</S.Heading>
        <S.Paragraph>40802810500004196329</S.Paragraph>
        <S.Heading>Банк</S.Heading>
        <S.Paragraph>АО "ТИНЬКОФФ БАНК"</S.Paragraph>
        <S.Heading>ИНН банка</S.Heading>
        <S.Paragraph>7710140679</S.Paragraph>
        <S.Heading>БИК банка</S.Heading>
        <S.Paragraph>044525974</S.Paragraph>
        <S.Heading>Корреспондентский счет банка</S.Heading>
        <S.Paragraph>30101810145250000974</S.Paragraph>
        <S.Heading>Юридический адрес банка</S.Heading>
        <S.Paragraph>
          Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26
        </S.Paragraph>
        <S.Heading>Мобильный телефон</S.Heading>
        <S.Paragraph>8 (910) 832-5000</S.Paragraph>
      </S.Text>
    </>
  );
};
