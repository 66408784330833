import styled from "styled-components";
import { getTransition } from "../../../../../Styles/utilites";

export const Wrapper = styled.div`
  position: relative;
  text-align: left;

  margin: 3rem 9rem 3rem 1rem;
  @media screen and (max-width: 420px) {
    margin: 3rem 5rem 3rem 1rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 7rem 12rem 2rem 1rem;
  }
`;

export const ModalDescription = styled.div`
  color: ${({ theme }) => theme.colors.grey58};
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  max-height: 70vh;
  overflow-y: scroll;
  padding-right: 5px;
  margin: 1rem 0 4rem;
  > div:first-child {
    margin-top: 0;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    overflow-y: initial;
    max-height: 25rem;
  }
`;

export const Price = styled.span`
  font-weight: bold;
  white-space: nowrap;
  display: contents;
`;

export const CloseForever = styled.div`
  position: absolute;
  left: 1.25rem;
  bottom: 10px;
  font-size: 0.85rem;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.grey58};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.font};
  }
  ${getTransition()}
  @media screen and ${({ theme }) => theme.media.sm} {
    left: 3rem;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  min-width: 95vw;
  transform: rotateY(180deg);
  z-index: -1;
  object-fit: cover;

  @media screen and (max-width: 420px) {
    right: -5rem;
  }
  @media screen and (max-width: 285px) {
    right: -10rem;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    height: auto;
    width: 95vw;
    min-width: auto;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    width: calc(100% + 3rem);
  }
`;
