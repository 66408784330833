import { GreenBtn } from "../../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { BsArrowLeftRight } from "react-icons/bs";
import { useToggleVisible } from "../../../../../hooks/useToggleVisible";
import { ConfirmModal } from "./ConfirmModal/ConfirmModal";

//type ConvertProps = {};
export const Convert = () => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  return (
    <>
      <GreenBtn onClick={toggle}>
        <BsArrowLeftRight size={30} />
      </GreenBtn>
      <ConfirmModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
    </>
  );
};
