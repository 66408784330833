import { graphql } from "../gql";

export const LISTEN_FOR_NEW_ITEMS = graphql(`
  subscription NewItems($flow: String!) {
    caseItemWon(flow: $flow) {
      date
      type
      name
      price
      image
      first_name
      userId
      caseImage
    }
  }
`);

export const LISTEN_ORDER_STATUS = graphql(`
  subscription OrderStatus {
    sourceStatus {
      status
    }
  }
`);
