import styled from "styled-components";
import { DividerStyle } from "../../Referral/TopContainer/Styles";

export const Wrapper = styled.div`
  ${DividerStyle};
  width: 100%;
  margin-top: 3rem;
  display: flex;
  min-height: 30rem;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-top: 4rem;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  @media screen and ${({ theme }) => theme.media.xxl} {
    max-width: 1200px;
  }
`;
