import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 3em 5em;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    padding: 3em 2em;
  }
`;

export const Heading = styled.h5`
  margin: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-left: 2rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-left: 2.5rem;
  }
`;

export const PairContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 430px) {
    display: block;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    width: 30%;
    flex-direction: column;
    flex-wrap: wrap;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    max-width: 27%;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    max-width: 25%;
    margin: 0 1em;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    margin: 0;
    width: 15.6666%;
  }
`;
