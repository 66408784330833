import styled from "styled-components";
import { getTransition } from "../../Styles/utilites";

export const Wrapper = styled.div`
  text-align: center;
  margin-top: 4rem;
`;
export const TextDivider = styled.div`
  margin: 2rem 0;
  font-size: 1.5rem;
`;

export const Link = styled.a`
  font-size: 1.5rem;
  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }
  ${getTransition()}
`;
