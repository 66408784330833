import styled from "styled-components";

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
  margin-top: 1rem;
`;

export const QuestionsContainer = styled.div`
  max-width: 950px;
  width: 100%;
  margin: 5rem auto;
  padding-bottom: 5rem;
  @media screen and ${({ theme }) => theme.media.xxl} {
    max-width: 1200px;
  }
`;
