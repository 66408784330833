import { GreenBtn } from "../../../../Styles/StyleComponents/GreenBtn/GreenBtn";
import * as S from "./Styles";
import { Case } from "../../../../gql/graphql";

type SectionProps = {
  id: string;
  heading: string;
  cases: Case[];
};
export const Section = ({ heading, id, cases }: SectionProps) => {
  return (
    <S.Wrapper id={id} $isSpecial={heading === ""}>
      {heading !== "" && <S.Heading>{heading}</S.Heading>}
      <S.CasesContainer>
        {cases.map(({ id, image, name, price }) => (
          <S.CaseElt
            key={id}
            // onClick={() => navigate(`case/${id}`)}
            to={`case/${id}`}
          >
            <img
              height={"200px"}
              width={"200px"}
              src={process.env.REACT_APP_URI + image}
              alt={name}
              loading={"lazy"}
              decoding="async"
            />
            <S.Name>{name}</S.Name>
            <GreenBtn size={"small"}>{price} ₽</GreenBtn>
          </S.CaseElt>
        ))}
      </S.CasesContainer>
    </S.Wrapper>
  );
};
