import * as S from "./Styles";

type ToggleProps = {
  isCol: boolean;
  setCol: (arg: boolean) => void;
};
export const Toggle = ({ isCol, setCol }: ToggleProps) => {
  return (
    <S.Wrapper>
      <S.BtnDivider>
        <S.Label>
          <input
            type="checkbox"
            name="color_mode"
            id="color_mode"
            value={isCol.toString()}
            checked={isCol}
            onChange={() => setCol(!isCol)}
          />
          <S.InnerLabel
            htmlFor="color_mode"
            data-on="Коллекция"
            data-off="Предметы"
            className="btn-color-mode-switch-inner"
          ></S.InnerLabel>
        </S.Label>
      </S.BtnDivider>
    </S.Wrapper>
  );
};
