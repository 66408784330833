import { ModalContainerProps } from "../../../../../Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../../../../../Components/Modal/Modal";
import * as S from "./Styles";
import {
  ModalDescription,
  ModalHeading
} from "../../../../../../Styles/components";
import { useMutation } from "@apollo/client";
import { CONVERT_REF_BALANCE } from "../../../../../../graphql/Mutation";
import { GET_ME } from "../../../../../../graphql/Query";

export const ConfirmModal = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const [convert, { loading, client }] = useMutation(CONVERT_REF_BALANCE);
  const onConfirm = async () => {
    await convert({
      onCompleted: (data) => {
        if (data.convertRefBalance) {
          toggle();
          const user = client.readQuery({ query: GET_ME });
          if (!user || !user.getMe) return;
          const newData = {
            ...user,
            getMe: {
              ...user.getMe,
              ref_balance: 0,
              balance: user.getMe.balance + user.getMe.ref_balance
            }
          };
          client.writeQuery({ query: GET_ME, data: newData });
        }
      }
    });
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Подтверждение конвертации</ModalHeading>
        <ModalDescription>
          Нам требуется ваше подтверждение для осуществления данного действия.
        </ModalDescription>
        <S.Text>
          Вы уверены, что хотите конвертировать свой реферальный баланс в
          основной?
        </S.Text>
        <S.BtnsContainer>
          <S.ConfirmBtn onClick={onConfirm} disabled={loading}>
            {loading ? "Загрузка" : "Подтвердить"}
          </S.ConfirmBtn>
          <S.CancelBtn onClick={toggle} disabled={loading}>
            Отменить
          </S.CancelBtn>
        </S.BtnsContainer>
      </S.Wrapper>
    </Modal>
  ) : null;
};
