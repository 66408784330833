import styled, { css } from "styled-components";
import { getTransition, hidden, visible } from "../../../../../Styles/utilites";
import { Link } from "react-router-dom";

type WrapperProps = {
  $isOpen: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  border-radius: calc(1px + 1rem);
  top: 110%;
  right: 0;
  z-index: ${({ theme }) => theme.order.fixed};
  width: 15rem;
  box-shadow: 0 5px 10px 2px ${({ theme }) => theme.colors.revert};
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          ${visible};
          opacity: 1;
        `
      : css`
          ${hidden};
          opacity: 0;
          transform: translateY(-15px);
        `};
  ${getTransition(300, ["visibility", "transform", "opacity"])}
`;

export const Arrow = styled.div`
  z-index: ${({ theme }) => theme.order.fixed};
  position: absolute;
  display: block;
  left: 85%;
  width: 1rem;
  height: 0.5rem;
  top: 0;
  transform: translate(-50%, calc(-100%));
  &::after,
  &::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }
  &::before {
    top: 0;
    border-bottom-color: ${({ theme }) => theme.colors.grey45};
  }
  &::after {
    top: 1px;
    border-bottom-color: ${({ theme }) => theme.colors.grey45};
  }
`;
export const TopContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey45};
  color: ${({ theme }) => theme.colors.grey85};
  list-style: none;
  padding: 0.75rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin: 0;
`;

const listStyle = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: bold;
  font-size: 1rem;
  padding: 0;
  margin: -0.35rem 0;
  text-decoration: none;

  svg {
    flex: 0 0 20%;
    transform: scale(0.6);
    margin-right: 1rem;
    path {
      fill: ${({ theme }) => theme.colors.grey85};
      ${getTransition(300, ["fill"])}
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.font};
    path {
      fill: ${({ theme }) => theme.colors.font};
    }
  }
  ${getTransition()}
`;
export const ListEltLink = styled(Link)`
  ${listStyle}
`;

export const ListElt = styled.div`
  ${listStyle}
`;

export const BottomContainer = styled.div`
  background-color: hsl(0, 0%, 28%);
  color: ${({ theme }) => theme.colors.grey85};
  list-style: none;
  padding: 0.5rem 1.25rem 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;

export const Date = styled.div`
  font-size: 0.85rem;
  font-weight: bolder;
  line-height: 1;
`;

export const Heading = styled.h5`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.yellow};
  margin: 0.5rem 0;
  line-height: 1;
`;

export const Paragraph = styled.p`
  line-height: 1.2;
  font-size: 0.85rem;
`;
