import { useToggleVisible } from "../../../hooks/useToggleVisible";
import * as S from "./Styles";
import { ModalContainer } from "./ModalContainer/ModalContainer";

// type HelpProps = {};
export const Help = () => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  return (
    <div>
      <S.HelpDivider>
        <S.Help onClick={toggle}>?</S.Help>
      </S.HelpDivider>
      <ModalContainer isOpen={isOpen} isShow={isShow} toggle={toggle} />
    </div>
  );
};
