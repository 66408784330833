import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  margin: 4rem 2rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 6rem 6rem;
  }
`;

export const Relative = styled.div`
  position: relative;
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: 2rem;
  }
`;

export const ErrorInfo = styled.div`
  color: ${({ theme }) => theme.colors.red};
`;
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;
`;
