import * as S from "./Styles";
import { Head } from "./Head/Head";
import { Body } from "./Body/Body";
import { Footer } from "./Footer/Footer";

// type BannerProps = {};
export const Banner = () => {
  return (
    <S.Wrapper>
      <Head />
      <Body />
      <Footer />
    </S.Wrapper>
  );
};
