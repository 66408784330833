import styled, { css } from "styled-components";
import { getTransition, UniversalButton } from "../../../Styles/utilites";

export const Heading = styled.h4`
  margin-top: 0.5em;
  font-weight: 700;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1.5em;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  padding: 1rem;
`;
type ImgProps = {
  $isNotAvailable?: boolean;
};

export const StarContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  bottom: -15px;
  right: 10px;
  //left: 50%;
  z-index: 1;
`;

export const ImgRelative = styled.div`
  position: relative;
  min-height: 257px;
  min-width: 257px;
`;
export const Img = styled.img<ImgProps>`
  ${({ $isNotAvailable }) =>
    $isNotAvailable
      ? css`
          filter: grayscale();
        `
      : css`
          filter: none;
        `};
  max-height: 260px;
  ${getTransition(500, "filter")};
`;
export const BottomContainer = styled.div`
  margin-top: -2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  font-size: 1rem;
  border-radius: 1em;
  border: 2px solid ${({ theme }) => theme.colors.brightOrange};
  background-color: ${({ theme }) => theme.colors.brown};
  font-weight: 500;
  padding: 1em;
  margin: 0 1em;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 0 2em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 0 auto;
    max-width: 30rem;
  }
`;

export const InfoTitle = styled.div`
  color: ${({ theme }) => theme.colors.secret};
  font-weight: 700;
`;

type BtnDividerProps = {
  $isSingle?: boolean;
};
export const BtnDivider = styled.div<BtnDividerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: ${({ $isSingle }) => ($isSingle ? "3em 1em 1em" : "0.75em 1em")};
  font-weight: 700;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: block;
    margin: ${({ $isSingle }) => ($isSingle ? "3em auto 1em" : "1em auto")};
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    ${({ $isSingle }) =>
      $isSingle &&
      css`
        margin-top: 5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      `}
  }
`;

export const Multiply = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 auto 1em;
  @media screen and ${({ theme }) => theme.media.lg} {
    margin: 0 1em;
  }
`;
type MultItemProps = {
  $isActive?: boolean;
  $isClose?: boolean;
};
export const MultItem = styled.li<MultItemProps>`
  padding: 0.75em 0.9em;
  cursor: ${({ $isClose }) => ($isClose ? "auto" : "pointer")};
  color: ${({ theme, $isActive, $isClose }) =>
    $isActive
      ? theme.colors.font
      : $isClose
      ? theme.colors.grey58
      : theme.colors.grey85};
  background-color: ${({ theme, $isActive, $isClose }) =>
    $isActive
      ? theme.colors.brightPurple
      : $isClose
      ? `hsl(0, 0%, 30%)`
      : theme.colors.bgLayout};
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.15em;
  margin-right: 0.12em;
  &:hover {
    color: ${({ theme, $isClose }) =>
      $isClose ? theme.colors.grey58 : theme.colors.font};
  }
  ${getTransition()}
`;

export const GreyButton = styled(UniversalButton)`
  color: ${({ theme }) => theme.colors.grey85};
  background: ${({ theme }) => theme.colors.grey37};
  svg {
    vertical-align: -8px;
    path {
      fill: ${({ theme }) => theme.colors.yellow};
      ${getTransition(300, "fill")}
    }
  }
  div {
    opacity: 0.85;
    ${getTransition(300, "opacity")}
  }
  &:disabled {
    filter: grayscale();
  }
  &:hover {
    color: ${({ theme }) => theme.colors.font};
    background: ${({ theme }) => theme.colors.grey45};
    div {
      opacity: 1;
    }
    path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }
  ${getTransition(300, ["background-color", "color", "filter"])}
`;

type GreyBtnContainerProps = {
  $isNotAvailable?: boolean;
};
export const GreyBtnContainer = styled.div<GreyBtnContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: block;
    margin: 1em auto;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-left: ${({ $isNotAvailable }) => ($isNotAvailable ? "0" : "1em")};
  }
`;
