import { Route, Routes } from "react-router-dom";
import { Main } from "./Main/Main";
import { Error404 } from "./404/Error404";
import { Case } from "./Case/Case";
import Items from "./Items/Items";
import Marketplace from "./Marketplace/Marketplace";
import { Referral } from "./Referral/Referral";
import Bonus from "./Bonus/Bonus";
import { Agreement } from "./agreement/Agreement";
import Faq from "./faq/Faq";
import UserContainer from "./user/UserContainer";
import { Partners } from "./Partners/Partners";
export const Pages = () => {
  const main = <Main />;
  const caseComp = <Case />;
  const items = <Items />;
  const marketplace = <Marketplace />;
  const user = <UserContainer />;
  const referral = <Referral />;
  const bonus = <Bonus />;
  const agreement = <Agreement />;
  const faq = <Faq />;
  const partners = <Partners />;
  const error404 = <Error404 />;
  return (
    <Routes>
      <Route index={true} path={"/"} element={main} />
      <Route path={"/case/:id"} element={caseComp} />
      <Route path={"/items"} element={items} />
      <Route path={"/marketplace"} element={marketplace} />
      <Route path={"/user/:id"} element={user} />
      <Route path={"/referral"} element={referral} />
      <Route path={"/bonus"} element={bonus} />
      <Route path={"/agreement"} element={agreement} />
      <Route path={"/contacts"} element={agreement} />
      <Route path={"/privacy-policy"} element={agreement} />
      <Route path={"/faq"} element={faq} />
      <Route path={"/partners"} element={partners} />
      <Route path={"/*"} element={error404} />
    </Routes>
  );
};
