import * as S from "./Styles";

//type AgreementBodyProps = {};
export const AgreementBody = () => {
  return (
    <S.Text>
      <S.Paragraph>Обновлено от 03.08.2023</S.Paragraph>
      <S.Heading>
        1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕМ СОГЛАШЕНИИ
      </S.Heading>
      <S.Paragraph>
        Перечисленные ниже термины в рамках настоящего Соглашения имеют
        следующее значение:
      </S.Paragraph>
      <S.Paragraph>
        1.1. Сайт – Интернет-ресурс, состоящий из совокупности всей информации
        (текстовой и графической), функций, программ для ЭВМ и размещенного в
        них контента (имеющих электронную форму данных, информации, материалов,
        документов, файлов, сообщений, изображений и т.п.), расположенный по
        сетевому адресу (доменному имени) {process.env.REACT_APP_URI}, включая
        все внутренние страницы данного адреса. Сайт является интернет-ресурсом,
        предназначенным для оказания развлекательно-аттракционных услуг
        физическим лицам, и не является сайтом для азартных игр, так как на
        Сайте нельзя умножить и вывести реальные деньги!
      </S.Paragraph>
      <S.Paragraph>
        1.2. Администрация Сайта – ИП Беляков Константин Вадимович, владеющее и
        управляющее Сайтом.
      </S.Paragraph>
      <S.Paragraph>
        1.3. Пользователь Сайта (далее – Пользователь) – физическое лицо,
        осуществляющее пользование Сайтом. Юридическое лицо осуществляет функции
        Пользователя через своего представителя.
      </S.Paragraph>
      <S.Paragraph>
        1.4. Пользование Сайтом - доступ на Сайт, навигация по нему и иные
        правомерные и добросовестные действия Пользователя без прав
        администрирования Сайта.
      </S.Paragraph>
      <S.Paragraph>
        1.5. Стороны – Администрация Сайта и Пользователь Сайта, заключившие
        Настоящее Соглашение.
      </S.Paragraph>
      <S.Paragraph>
        1.6. Монета – имеет символ ₽, внутриигровая валюта, в которую
        конвертируются все вводимые Пользователем Сайта средства при пополнении
        баланса.
      </S.Paragraph>
      <S.Heading>2. ОБЩИЕ ПОЛОЖЕНИЯ</S.Heading>
      <S.Paragraph>
        2.1. Настоящее Пользовательское соглашение (далее – Соглашение)
        относится к Сайту, расположенному в сети интернет по адресу{" "}
        {process.env.REACT_APP_URI}
      </S.Paragraph>
      <S.Paragraph>
        2.2. Настоящее Соглашение регулирует отношения между Администрацией
        Сайта и Пользователем Сайта.
      </S.Paragraph>
      <S.Paragraph>
        2.3. Администрация Сайта оставляет за собой право в любое время
        изменять, добавлять или удалять пункты настоящего Соглашения без
        уведомления Пользователя.
      </S.Paragraph>
      <S.Paragraph>
        2.4. Продолжение использования Сайта Пользователем означает принятие
        условий Соглашения и изменений к нему, внесенных в настоящее Соглашение.
      </S.Paragraph>
      <S.Paragraph>
        2.5. Пользователь самостоятельно несет ответственность за проверку
        настоящего Соглашения на наличие изменений в нем.
      </S.Paragraph>
      <S.Paragraph>
        2.6. Начиная просмотр Сайта или использование каких-либо предложенных на
        Сайте функций, Пользователь считается принявшим условия Соглашения в
        полном объеме, без каких-либо оговорок и исключений.
      </S.Paragraph>
      <S.Heading>3. ПРЕДМЕТ СОГЛАШЕНИЯ</S.Heading>
      <S.Paragraph>
        3.1. Пользователь осуществляет пользование Сайтом на условиях,
        изложенных в настоящем Соглашении. Предметом Соглашения является
        предоставление Пользователю доступа к использованию Сайта и его
        функционала.
      </S.Paragraph>
      <S.Paragraph>
        3.2. Под действие настоящего Соглашения подпадают все существующие на
        данный момент функции Сайта, а также любые их последующие модификации и
        появляющиеся в дальнейшем функции.
      </S.Paragraph>
      <S.Paragraph>
        3.3. С помощью Сайта обеспечивается информирование и взаимодействие
        Сторон по удаленной форме (без личного непосредственного присутствия
        Сторон) - с использованием программного обеспечения, электронных
        сервисов и инструментов, размещенных (предоставляемых) на Сайте.
      </S.Paragraph>
      <S.Paragraph>
        3.4. Сайт не является средством массовой информации. Сайт не содержит
        информации, причиняющей вред здоровью и (или) развитию детей, а также
        иной информации, ограниченной или запрещенной для распространения в
        соответствии с законодательством РФ.
      </S.Paragraph>
      <S.Paragraph>
        3.4. Доступ к Сайту предоставляется на безвозмездной основе.
      </S.Paragraph>
      <S.Paragraph>
        3.5. Настоящее Соглашение является публичной офертой. Получая доступ к
        Сайту, Пользователь соглашается с условиями Соглашения и считается
        присоединившимся к настоящему Соглашению.
      </S.Paragraph>
      <S.Paragraph>
        3.6. При предоставлении возможности пользования Сайтом Пользователь, его
        сотрудники и/или иные лица ни при каких обстоятельствах не являются
        информационными посредниками по смыслу законодательства РФ.
      </S.Paragraph>
      <S.Heading>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</S.Heading>
      <S.Paragraph>
        4.1. <u>Администрация Сайта вправе:</u>
      </S.Paragraph>
      <S.Paragraph>
        4.1.1. Изменять правила пользования Сайтом, а также изменять содержание
        Сайта. Изменения вступают в силу с момента публикации новой редакции
        Соглашения на Сайте.
      </S.Paragraph>
      <S.Paragraph>
        4.1.2. Ограничить доступ к Сайту или к частям сайта в случае нарушения
        Пользователем условий настоящего Соглашения.
      </S.Paragraph>
      <S.Paragraph>
        4.2. <u>Пользователь вправе:</u>
      </S.Paragraph>
      <S.Paragraph>
        4.2.1. Получить доступ к использованию Сайта в ознакомительных и
        подобных целях, а также к функциям, предложенным на Сайте.
      </S.Paragraph>
      <S.Paragraph>
        4.2.2. Пользоваться Сайтом исключительно в целях и порядке,
        предусмотренных Соглашением и не запрещенных законодательством
        Российской Федерации.
      </S.Paragraph>
      <S.Paragraph>
        4.2.3. Выводить игровые ценности в размере, не превышающем общую сумму
        пополнений более чем в 200 раз и не превышающем 500000 (пятьсот тысяч)
        рублей по курсу, устанавливаемому на этапе пополнения баланса.
        Превышение суммы вывода возможно по усмотрению Администрации.
      </S.Paragraph>
      <S.Paragraph>
        4.3. <u>Пользователь Сайта обязуется:</u>
      </S.Paragraph>
      <S.Paragraph>
        4.3.1. Соблюдать имущественные и неимущественные права авторов и иных
        правообладателей при использовании Сайта.
      </S.Paragraph>
      <S.Paragraph>
        4.3.2. Не предпринимать действий, которые могут рассматриваться как
        нарушающие нормальную работу Сайта.
      </S.Paragraph>
      <S.Paragraph>
        4.3.3. Не распространять с использованием Сайта любую конфиденциальную и
        охраняемую законодательством Российской Федерации информацию о
        физических или юридических лицах.
      </S.Paragraph>
      <S.Paragraph>
        4.3.4. Избегать любых действий, в результате которых может быть нарушена
        конфиденциальность информации, охраняемой законодательством Российской
        Федерации.
      </S.Paragraph>
      <S.Paragraph>4.3.5. Не использовать сервисы Сайта с целью:</S.Paragraph>
      <S.Paragraph>
        а) Размещения информации, которая является незаконной, нарушает любые
        права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или)
        дискриминацию по расовому, национальному, половому, религиозному,
        социальному признакам; содержит недостоверные сведения и (или)
        оскорбления в адрес каких-либо лиц, организаций, органов власти.
      </S.Paragraph>
      <S.Paragraph>
        б) Побуждения к совершению противоправных действий, а также содействия
        лицам, действия которых направлены на нарушение ограничений и запретов,
        действующих на территории Российской Федерации.
      </S.Paragraph>
      <S.Paragraph>
        4.4. <u>Пользователю запрещается:</u>
      </S.Paragraph>
      <S.Paragraph>
        4.4.1. Отслеживать или пытаться отслеживать любую информацию о любом
        другом Пользователе Сайта.
      </S.Paragraph>
      <S.Paragraph>
        4.4.2. Использовать Сайт в любых целях, запрещенных законодательством
        Российской Федерации, а также подстрекать к любой незаконной
        деятельности или другой деятельности, нарушающей права Администрации
        Сайта или других лиц.
      </S.Paragraph>
      <S.Paragraph>
        4.4.3. Действовать недобросовестно, допускать злоупотребление правом.
      </S.Paragraph>
      <S.Paragraph>
        4.4.4. Без предварительного письменного согласия Администрации Сайта
        копировать, воспроизводить, распространять (возмездно или безвозмездно),
        изменять, показывать, публично исполнять, готовить или использовать для
        коммерческих или некоммерческих целей содержание Сайта.
      </S.Paragraph>
      <S.Paragraph>
        4.4.5. Использовать содержание Сайта на любом другом сайте или
        компьютере с сетевым окружением для любых целей (кроме собственного
        просмотра).
      </S.Paragraph>
      <S.Paragraph>
        4.4.6. Вмешиваться (пытаться вмешаться) в работу Сайта или любые
        действия, производимые на Сайте.
      </S.Paragraph>
      <S.Paragraph>
        4.4.7. Распространять спам, сообщения, содержащие просьбу переслать
        данное сообщение другим Пользователям или другую нежелательную или
        запрещенную законом информацию.
      </S.Paragraph>
      <S.Paragraph>
        4.4.8. Пытаться вмешаться или ставить под угрозу целостность системы или
        безопасность, декодировать любую передачу от/ к серверу, обслуживающему
        Сайт.
      </S.Paragraph>
      <S.Paragraph>
        4.4.9. Загружать вирусы, трояны или другие вредоносные программы на Сайт
        или через него.
      </S.Paragraph>
      <S.Paragraph>
        4.4.10. Заниматься бонусхантингом, злоупотреблять бонусной программой.
        Иными словами, осуществлять действия на аккаунте преимущественно за счёт
        средств, полученных в результате активации промокодов, получения
        подарков или участия в акциях.
      </S.Paragraph>
      <S.Paragraph>
        4.4.11. Использовать более одного аккаунта на Сайте
      </S.Paragraph>
      <S.Paragraph>
        4.4.12. Заниматься мошенничеством, вовлекать других Пользователей в
        мошеннические схемы, обманывать Администрацию, либо распространять
        вредоносную информацию любым способом через ресурсы сайта.
      </S.Paragraph>
      <S.Paragraph>
        4.4.13. Публично оскорблять Администрацию Сайта и/или распространять
        клевету, используя сервисы для обмена информацией.
      </S.Paragraph>
      <S.Paragraph>
        4.4.14. Выдавать себя за Администрацию Сайта, либо за других сотрудников
        и аффилированных лиц, имеющих отношение к Администрации Сайта.
      </S.Paragraph>
      <S.Paragraph>
        4.5.{" "}
        <u>
          Ограничения, применяемые к аккаунту Пользователя при нарушении правил
          пользования:
        </u>
      </S.Paragraph>
      <S.Paragraph>
        4.5.1. Блокировка возможности вывода игровых ценностей (при нарушении п.
        4.4.10 или п. 4.4.11).
      </S.Paragraph>
      <S.Paragraph>
        4.5.2. Запрет на получение любых бонусов (промокодов, участия в ивентах
        и т.д.) и списание средств, полученных в результате использования
        бонусной программы (при нарушении п. 4.4.10 или п. 4.4.11).
      </S.Paragraph>
      <S.Paragraph>4.5.3. Полная блокировка аккаунта.</S.Paragraph>
      <S.Paragraph>
        4.6. Ограничения с аккаунта Пользователя могут быть сняты по усмотрению
        Администрации Сайта. При этом с аккаунта могут быть списаны все средства
        и предметы, а также начислена разница между суммой депозита и суммой
        вывода.
      </S.Paragraph>
      <S.Heading>5. ПОЛЬЗОВАНИЕ САЙТОМ</S.Heading>
      <S.Paragraph>
        5.1. Сайт не может быть скопирован, опубликован, воспроизведен, передан
        или распространен любым способом, полностью или частично, без
        предварительного письменного согласия Администрации Сайта.
      </S.Paragraph>
      <S.Paragraph>
        5.2. Сайт защищен авторским правом, а также другими правами, связанными
        с интеллектуальной собственностью, и законодательством о
        недобросовестной конкуренции.
      </S.Paragraph>
      <S.Paragraph>
        5.3. Пользователь самостоятельно несет ответственность за сохранение
        конфиденциальности информации, а также за всю без исключения
        деятельность, которая ведётся от имени Пользователя.
      </S.Paragraph>
      <S.Paragraph>
        5.4. В целях безопасности Пользователю рекомендуется пользоваться
        антивирусными программами и соблюдать общие правила безопасности доступа
        к сайтам в сети Интернет.
      </S.Paragraph>
      <S.Paragraph>
        5.5. Настоящее Соглашение распространяет свое действия на все
        дополнительные положения и условия, представленные на Сайте.
      </S.Paragraph>
      <S.Paragraph>
        5.6. Администрация Сайта имеет право в любое время без уведомления
        Пользователя вносить любые изменения в информацию, представленную на
        Сайте.
      </S.Paragraph>
      <S.Paragraph>
        5.7. Документ «Политика конфиденциальности» регулирует в соответствующей
        части и распространяет свое действие на использование Пользователем
        Сайта.
      </S.Paragraph>
      <S.Heading>6. ОТВЕТСТВЕННОСТЬ</S.Heading>
      <S.Paragraph>
        6.1. Любые убытки, которые Пользователь может понести в случае
        умышленного или неосторожного нарушения любого положения настоящего
        Соглашения, а также вследствие несанкционированного доступа,
        Администрацией Сайта не возмещаются.
      </S.Paragraph>
      <S.Paragraph>
        6.2. Программное обеспечение поставляется "как есть" и Пользователь
        использует его на свой страх и риск. Администрация не несёт
        ответственности за любые убытки или потери, понесенные пользователем
        из-за использования программного обеспечения.
      </S.Paragraph>
      <S.Paragraph>
        6.3. <u>Администрация Сайта не несет ответственности за:</u>
      </S.Paragraph>
      <S.Paragraph>
        6.3.1. Задержки или сбои, а также любые другие случаи неполадок в
        телекоммуникационных, компьютерных, электрических и иных смежных
        системах.
      </S.Paragraph>
      <S.Paragraph>
        6.3.2. Действия систем переводов, банков, платежных систем, почтовых
        сервисов, транспортных компаний и за задержки связанные с их работой.
      </S.Paragraph>
      <S.Paragraph>
        6.3.3. Ненадлежащее функционирование Сайта, в случае, если Пользователь
        не имеет необходимых технических средств или навыков для его
        использования.
      </S.Paragraph>
      <S.Paragraph>
        6.4. Администрация Сайта не несет никаких обязательств по обеспечению
        Пользователей средствами и навыками, необходимыми для использования
        Сайта.
      </S.Paragraph>
      <S.Heading>7. НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</S.Heading>
      <S.Paragraph>
        7.1. Администрация Сайта вправе раскрыть любую информацию о
        Пользователе, если раскрытие необходимо в связи с расследованием или
        жалобой в отношении неправомерного использования Сайта, либо для
        установления (идентификации) Пользователя, который может нарушать или
        вмешиваться в права Администрации Сайта или в права других Пользователей
        Сайта.
      </S.Paragraph>
      <S.Paragraph>
        7.2. Администрация Сайта имеет право раскрыть информацию о Пользователе,
        если действующее законодательство Российской Федерации требует или
        разрешает такое раскрытие.
      </S.Paragraph>
      <S.Paragraph>
        7.3. Администрация Сайта вправе без предварительного уведомления
        Пользователя прекратить доступ к Сайту, если Пользователь нарушил
        настоящее Соглашение или содержащиеся в иных документах условия
        пользования Сайтом, а также в случае прекращения действия Сайта, либо по
        причине технической неполадки любого рода.
      </S.Paragraph>
      <S.Paragraph>
        7.4. Администрация Сайта не несет ответственности перед Пользователем
        или третьими лицами за прекращение доступа к Сайту в случае нарушения
        Пользователем любого положения настоящего Соглашения или иного
        документа, содержащего условия пользования Сайтом.
      </S.Paragraph>
      <S.Heading>8. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</S.Heading>
      <S.Paragraph>
        8.1. Администрация Сайта не принимает предложения от Пользователя
        относительно изменений настоящего Пользовательского соглашения.
      </S.Paragraph>
      <S.Paragraph>
        8.2. Сообщения сотрудников Сайта, отправленные Пользователю посредством
        использования Чата, инструментов для оказания технической поддержки,
        либо в медиаресурсах, принадлежащих Администрации, не являются публичной
        офертой.
      </S.Paragraph>
      <S.Paragraph>
        8.3. Отзывы и вопросы Пользователя, размещенные на Сайте, не являются
        конфиденциальной информацией и могут быть использованы Администрацией
        Сайта.
      </S.Paragraph>
      <S.Paragraph>
        8.4. Настоящее Соглашение имеет юридическую силу с момента размещения на
        Сайте, содержит все существенные условия и становится обязательным для
        каждого отдельно взятого Пользователя с момента выражения таким
        Пользователем согласия с условиями Соглашения в порядке, предусмотренном
        п. 2.4 Соглашения.
      </S.Paragraph>
      <S.Paragraph>
        8.5. Соглашение может быть в любое время изменено Администрацией Сайта в
        одностороннем порядке без какого-либо специального уведомления. Новая
        редакция Соглашения вступает в силу с момента ее размещения на Сайте,
        если иное не предусмотрено новой редакцией Соглашения.
      </S.Paragraph>
      <S.Paragraph>
        8.6. Настоящее Соглашение регулируется и толкуется в соответствии с
        законодательством РФ. Вопросы, не урегулированные настоящим Соглашением,
        подлежат разрешению в соответствии с законодательством РФ.
      </S.Paragraph>
      <S.Paragraph>
        8.7. Споры и разногласия разрешаются путем переговоров, если соглашение
        не достигнуто, то спор решается согласно действующему законодательству
        РФ.
      </S.Paragraph>
      <S.Paragraph>
        8.8. Ничто в Соглашении не может пониматься как установление между
        Пользователем и Администрацией Сайта юридических отношений товарищества,
        партнерства, отношений по совместной деятельности (с созданием
        юридических лиц или без такового), трудовых отношений,
        гражданско-правовых отношений, предметом которых являются отношения
        авторского заказа, отчуждение или предоставление права использования
        объектов интеллектуальной собственности, либо какие-то иные отношений,
        прямо не предусмотренные Соглашением.
      </S.Paragraph>
      <S.Paragraph>
        8.9. Действующее Пользовательское соглашение размещено на Сайте по
        адресу {process.env.REACT_APP_URI}agreement
      </S.Paragraph>
      <S.Heading>ПОЛИТИКА ВОЗВРАТОВ</S.Heading>
      <S.Paragraph>
        Наши Продукты представляют собой цифровой контент (виртуальные
        предметы), не являются товарами в понимании «О защите прав потребителей»
        и в силу своей специфики не подлежат общему праву потребителя на
        возврат.
      </S.Paragraph>
      <S.Paragraph>
        Возврат денежных средств за приобретенный контент на Сайте возможен по
        усмотрению Администрации. Возврат невозможен, если средства были
        потрачены или преумножены, либо если прошло более 30 календарных дней с
        момента пополнения счёта.
      </S.Paragraph>
      <S.Paragraph>
        Возврат осуществляется по тем же реквизитам, с которых была совершена
        оплата.
      </S.Paragraph>
    </S.Text>
  );
};
