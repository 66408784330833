import * as S from "./Styles";
import { useQuery } from "@apollo/client";
import { GET_MY_REFS } from "../../../graphql/Query";

// type InviteTableProps = {};

export const InviteTable = () => {
  const { data, loading } = useQuery(GET_MY_REFS);
  return (
    <S.Table>
      <thead>
        <tr>
          <S.Th>Пользователь</S.Th>
          <S.Th>Внес</S.Th>
          <S.Th>Получено вами</S.Th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <S.Tr>
            <td colSpan={3}>Загрузка</td>
          </S.Tr>
        ) : data && data.myReff && data.myReff.length ? (
          data.myReff.map((ref, i) => (
            <S.Tr key={i}>
              <td>{ref!.firstname}</td>
              <td>{ref!.in}</td>
              <td>{ref!.get}</td>
            </S.Tr>
          ))
        ) : (
          <S.Tr>
            <td colSpan={3}>
              Нет приглашенных пользователей, выполнивших условия.
            </td>
          </S.Tr>
        )}
      </tbody>
    </S.Table>
  );
};
