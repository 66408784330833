import styled, { css } from "styled-components";
import { getTransition, UniversalButton } from "../../utilites";

type Vars = typeof variants;
type GradButtonProps = {
  $isDark?: boolean;
  $variant?: keyof Vars;
  $font?: number;
  $isSquare?: boolean;
};
export const GradButton = styled(UniversalButton)<GradButtonProps>`
  font-size: ${({ $font }) => ($font ? $font : 1)}rem;
  color: ${({ theme, $isDark }) =>
    $isDark ? theme.colors.revert : theme.colors.font};
  ${({ $variant }) => ($variant ? variants[$variant] : variants["orange"])};
  svg {
    height: auto;
    vertical-align: -4px;
  }
  ${({ $isSquare }) =>
    $isSquare &&
    css`
      line-height: 0;
      padding: 0.5rem 0.5rem;
      border-radius: 0.5rem;
      svg {
        //margin: 0;
        vertical-align: 1px;
        margin: 1px 1px 1px 2px;
      }
    `};
  div {
    opacity: ${({ $isDark }) => ($isDark ? 0.7 : 0.85)};
    ${getTransition(300, "opacity")}
  }
  &:disabled {
    filter: grayscale();
  }
  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const variants = {
  orange: css`
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.brightOrange} 0%,
      ${({ theme }) => theme.colors.orange} 100%
    );
  `,
  green: css`
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.greenGrad} 0%,
      ${({ theme }) => theme.colors.border} 100%
    );
  `,
  yellow: css`
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.yellow} 0%,
      ${({ theme }) => theme.colors.gold} 100%
    );
  `
};
