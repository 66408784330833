import { CountdownRenderProps } from "react-countdown/dist/Countdown";
import * as S from "./Styles";

const getDouble = (num: number) => {
  const value = num.toString();
  if (value.length < 2) return "0" + value;
  return value;
};
export const CountdownDisplay = ({
  days,
  hours,
  minutes,
  seconds
}: CountdownRenderProps) => {
  return (
    <S.Wrapper className="countdown">
      <S.TimeDivider>
        <S.TimeCell>{getDouble(days)}</S.TimeCell>
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(hours)}</S.TimeCell>
        {/*<S.TimeCell>:</S.TimeCell>*/}
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(minutes)}</S.TimeCell>
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(seconds)}</S.TimeCell>
      </S.TimeDivider>
      <S.FlexDivider>
        <div>дни</div>
        <div>часы</div>
        <div style={{ marginRight: "5px" }}>мин</div>
        <div>сек</div>
      </S.FlexDivider>
    </S.Wrapper>
  );
};

export const CountdownDisplayDay = ({
  hours,
  minutes,
  seconds
}: CountdownRenderProps) => {
  return (
    <S.Wrapper className="countdown" $isInText={true} $isWithBox={true}>
      <S.TimeDivider>
        <S.TimeCell>{getDouble(hours)}</S.TimeCell>
        {/*<S.TimeCell>:</S.TimeCell>*/}
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(minutes)}</S.TimeCell>
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(seconds)}</S.TimeCell>
      </S.TimeDivider>
      <S.FlexDivider>
        <div>часы</div>
        <div style={{ marginLeft: "-5px" }}>мин</div>
        <div>сек</div>
      </S.FlexDivider>
    </S.Wrapper>
  );
};

export const CountdownDisplayText = ({
  days,
  hours,
  minutes,
  seconds
}: CountdownRenderProps) => {
  return (
    <S.Wrapper className="countdown" $isInText={true}>
      <S.TimeDivider>
        <S.TimeCell>{getDouble(days)}</S.TimeCell>
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(hours)}</S.TimeCell>
        {/*<S.TimeCell>:</S.TimeCell>*/}
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(minutes)}</S.TimeCell>
        <S.SepDivider>:</S.SepDivider>
        <S.TimeCell>{getDouble(seconds)}</S.TimeCell>
      </S.TimeDivider>
      <S.FlexDivider>
        <div>дни</div>
        <div>часы</div>
        <div style={{ marginRight: "5px" }}>мин</div>
        <div>сек</div>
      </S.FlexDivider>
    </S.Wrapper>
  );
};
