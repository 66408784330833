import styled from "styled-components";
import { getTransition, UniversalButton } from "../../../../Styles/utilites";

export const HeadDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 1rem 1rem;
  height: 4rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 4rem 2rem 1rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin: 4rem 2.5rem 0rem;
  }
`;

export const SellBtn = styled(UniversalButton)`
  color: ${({ theme }) => theme.colors.font};
  background: ${({ theme }) => theme.colors.red};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  svg {
    font-size: 0.65rem;
    vertical-align: -6px;
  }
  div {
    opacity: 0.85;
    ${getTransition(300, "opacity")}
  }
  &:hover {
    div {
      opacity: 1;
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export const ContentWrapper = styled.div`
  > div {
    margin-top: 0;
    min-height: 500px;
  }
`;
