import * as S from "./Styles";
import { Item } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Item";
import { useEffect } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { SpinArray } from "./SpinnerContainer";
import { CardVars } from "../../../Components/Layout/Sidebar/ItemsFlow/Item/Styles";

type SpinnerProps = {
  activeMult: number;
  isFast: boolean;
  spinArray: SpinArray;
  setTimeout: () => void;
  run: boolean;
  setRun: () => void;
  isEnd: boolean;
};

export const Spinner = ({
  activeMult,
  isFast,
  spinArray,
  setTimeout,
  run,
  setRun,
  isEnd
}: SpinnerProps) => {
  const width = useWindowWidth();

  useEffect(() => {
    setRun();
    setTimeout();
  }, [spinArray]);

  useEffect(() => {
    if (width > 576) {
      if (activeMult > 3) window.scrollTo(0, 200);
      else window.scrollTo(0, 0);
    }
  }, [run]);

  return (
    // <S.Wrapper>
    <S.Spins>
      {spinArray.map(({ index, items, transform }) => (
        <S.FixedWidthDivider key={index} data-testid={"spinnerDiv"}>
          <S.SpinContainer>
            <S.SpinRow
              $isEnd={isEnd}
              transform={run ? transform : undefined}
              $isFast={isFast}
            >
              {items.map(({ type, image, price, name, id }, i) => (
                <Item
                  type={type as CardVars}
                  img={process.env.REACT_APP_URI + image}
                  price={price}
                  name={name}
                  isWrap={true}
                  key={id}
                  isWin={i === 46}
                  isFast={isFast}
                />
              ))}
            </S.SpinRow>
          </S.SpinContainer>
          <S.SpinIndicator />
        </S.FixedWidthDivider>
      ))}
    </S.Spins>
  );
};
