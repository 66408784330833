import styled from "styled-components";
import { Wrapper } from "../TopContainer/Styles";

export const SecondWrapper = styled(Wrapper)`
  margin-top: 1rem;
  > div {
    height: 26rem;
  }
`;

export const ErrorInfo = styled.div`
  margin-top: 1rem;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.red};
`;

export const BtnContainer = styled.div`
  margin-top: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-top: 3rem;
  }
`;
