import * as S from "./Styles";
import { ReactComponent as Man } from "../../../../../assets/icons/profile/man.svg";
import { ReactComponent as Faq } from "../../../../../assets/icons/profile/faq.svg";
import { ReactComponent as Door } from "../../../../../assets/icons/profile/door.svg";
import { useNavigate } from "react-router-dom";
import { News } from "./News/News";
import { useLogout } from "../../../../../hooks/useLogout";

type PopoverProps = {
  isOpen: boolean;
  userId: string;
  toggle: () => void;
};

const navItems = [
  {
    title: "Профиль",
    to: "/user/",
    icon: <Man />
  },
  {
    title: "FAQ",
    to: "/faq",
    icon: <Faq />
  },
  {
    title: "Выйти",
    to: "exit",
    icon: <Door />
  }
];
export const Popover = ({ isOpen, userId, toggle }: PopoverProps) => {
  const logOut = useLogout();
  const navigate = useNavigate();
  const onClick = async (e: React.MouseEvent, to: string) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
    if (to === "exit") {
      const res = await fetch(process.env.REACT_APP_URI + "logout", {
        method: "POST"
      });
      console.log(res);
      if (res) logOut();
    } else if (to === "/user/") navigate(to + userId);
    else navigate(to);
  };
  return (
    <S.Wrapper $isOpen={isOpen}>
      <S.Arrow />
      <S.TopContainer>
        {navItems.map(({ title, icon, to }) =>
          to === "exit" ? (
            <S.ListElt
              key={to}
              onClick={(e: React.MouseEvent) => onClick(e, to)}
            >
              {icon}
              {title}
            </S.ListElt>
          ) : (
            <S.ListEltLink
              key={to}
              onClick={(e) => onClick(e, to)}
              to={to === "/user/" ? to + userId : to}
            >
              {icon}
              {title}
            </S.ListEltLink>
          )
        )}
      </S.TopContainer>
      <News />
    </S.Wrapper>
  );
};
