import * as S from "./Styles";
import { FlowType } from "../Sidebar";
import { useEffect, useRef, useState } from "react";
import { OnlineStatus } from "./OnlineStatus/OnlineStatus";
import { useTimeout } from "../../../../hooks/useTimeout";
import { useQuery, useReactiveVar, useSubscription } from "@apollo/client";
import { GET_LAST_ITEMS, GET_ME } from "../../../../graphql/Query";
import { Result } from "../../../../gql/graphql";
import { LISTEN_FOR_NEW_ITEMS } from "../../../../graphql/Subscription";
import { isSlowOpeningVar } from "../../../../graphql/Cache";
import { generate } from "../../../../pages/Case/Spinner/SpinnerContainer";
import { PureItems as Items } from "./Items/Items";

type Props = {
  flow: FlowType;
  online: number;
};

export const ItemsFlow = ({ flow, online }: Props) => {
  const { data, client } = useQuery(GET_LAST_ITEMS, {
    variables: {
      flow
    },
    fetchPolicy: "network-only"
  });

  const myQueue = useRef<Result[]>([]);
  const queue = useRef<Result[]>([]);
  const isSlow = useReactiveVar(isSlowOpeningVar);
  const [current, setCurrent] = useState<Result[]>([]);
  const [next, setNext] = useState(false);
  const addItems = () => {
    setNext(true);
    //console.log(queue.current);
    setCurrent([
      ...queue.current.map((obj) => ({
        ...obj,
        item: {
          ...obj.item,
          id: generate(5)
        }
      })),
      ...current
    ]);
    toggleNext();
  };
  const addMyItems = () => {
    queue.current = [...myQueue.current, ...queue.current];
    myQueue.current = [];
    addItems();
    isSlowOpeningVar(false);
  };
  const [toggleNext] = useTimeout(() => {
    setNext(false);
    queue.current = [];
  }, 100);
  const [setOnAdd] = useTimeout(addItems, 650);
  const [setOnMyAdd] = useTimeout(addMyItems, 9900);

  useSubscription(LISTEN_FOR_NEW_ITEMS, {
    variables: {
      flow
    },
    onData: (data) => {
      if (data && data.data && data.data.data) {
        const itemData = data.data.data.caseItemWon;
        //console.log(itemData);
        const me = client.readQuery({ query: GET_ME });
        const item = {
          item: {
            id: itemData.date,
            name: itemData.name,
            image: itemData.image,
            type: itemData.type,
            price: itemData.price
          },
          date: itemData.date,
          case: {
            image: itemData.caseImage
          },
          user: {
            id: itemData.userId,
            first_name: itemData.first_name
          }
        } as Result;

        if (me && me.getMe && itemData.userId === me.getMe.id && isSlow) {
          if (!myQueue.current.length) setOnMyAdd();
          myQueue.current = [item, ...myQueue.current];
        } else {
          if (!queue.current.length) setOnAdd();
          queue.current = [item, ...queue.current];
        }
      }
    }
  });

  useEffect(() => {
    if (data && data.latestItems && data.latestItems.length) {
      setCurrent(
        data.latestItems.map((obj) => ({
          ...obj,
          item: {
            ...obj!.item,
            id: generate(5)
          }
        })) as Result[]
      );
    }
    //console.log(data);
  }, [flow, data]);

  return (
    <S.BottomContainer>
      <OnlineStatus online={online} />
      {!!current.length && (
        <Items current={current} next={next} queueLen={queue.current.length} />
      )}
    </S.BottomContainer>
  );
};
