import { useLayoutEffect } from "react";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import {
  PageHeading,
  PageMarginSection,
  PageWrapper
} from "../../Styles/components";
import * as S from "./Styles";
import { Wrapper } from "./Styles";

export const Partners = () => {
  useLayoutEffect(() => {
    document.title = "Brawl Cases | Партнерство";
  }, []);
  useReloadScrollTop();
  return (
    <PageWrapper>
      <PageMarginSection>
        <PageHeading>Партнерство</PageHeading>
        <S.Wrapper>
          <S.TextDivider>
            Вы можете обратится по вопросам сотрудничества к нам по почте:
          </S.TextDivider>
          <S.Link href={"mailto:brawlcases@mail.ru"}>brawlcases@mail.ru</S.Link>
          <S.TextDivider>Или через Телеграмм бота:</S.TextDivider>
          <S.Link href={"https://t.me/brawlcases_admin_bot"}>
            Telegram бот
          </S.Link>
        </S.Wrapper>
      </PageMarginSection>
    </PageWrapper>
  );
};
