import * as S from "./Styles";
import { GradButton } from "../../../Styles/StyleComponents/GradButton/GradButton";
import { ReactComponent as Wallet } from "../../../assets/icons/pay/wallet.svg";
import { useState } from "react";
import { ReactComponent as Lightning } from "../../../assets/icons/pay/lightning.svg";
import { CaseVars } from "../../../data/casesData";
import Countdown from "react-countdown";
import {
  CountdownDisplayDay,
  CountdownDisplayText
} from "../../../Components/CountdownDisplay/CountdownDisplay";
import { useApolloClient } from "@apollo/client";
import { GET_ME } from "../../../graphql/Query";
import { ModalContainer } from "../../../Components/SignComponent/ModalContainer/ModalContainer";
import { useToggleVisible } from "../../../hooks/useToggleVisible";
import { Timers, User } from "../../../gql/graphql";
import { InputModal } from "../../../Components/InputModal/InputModal";
import { BonusStar } from "../../../Components/BonusStar/BonusStar";
import { useAppContext } from "../../../Components/AppContext/AppContext";
import { ToastifyVars } from "../../../Components/Toastify2/Toastify";

type HeaderProps = {
  img: string;
  name: string;
  price: number;
  type: CaseVars;
  freeType?: string;
  activeMult: number;
  setActive: (arg: number) => void;
  setGame: (arg: "norm" | "fast") => void;
  me?: User;
};

const freeLimits = {
  first_case: "50",
  second_case: "250",
  third_case: "500",
  fourth_case: "1000"
};

const multis = [
  {
    multBy: 1
  },
  {
    multBy: 2
  },
  {
    multBy: 3
  },
  {
    multBy: 4
  },
  {
    multBy: 5
  }
];
export const Header = ({
  name,
  img,
  price,
  type,
  freeType,
  setActive,
  activeMult,
  setGame,
  me
}: HeaderProps) => {
  const client = useApolloClient();
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const [isAvailable, setAvailable] = useState(!freeType);
  const { pushToast } = useAppContext();
  const onClick = (multBy: number) => {
    if (multBy * price > me!.balance) return;
    setActive(multBy);
  };

  const timeoutBonus = () => {
    const data = client.readQuery({ query: GET_ME });
    if (!data || !data.getMe) return;
    const newData = {
      ...data,
      getMe: {
        ...data.getMe,
        isDoubleProbTimer: null
      }
    };
    client.writeQuery({ query: GET_ME, data: newData });
  };

  //useEffect(() => console.log(me));

  return (
    <>
      <S.TopContainer>
        <S.Heading>{name}</S.Heading>
        <S.ImgRelative>
          <S.Img
            src={process.env.REACT_APP_URI + img}
            alt={name}
            $isNotAvailable={!isAvailable}
          />
          {!freeType && me && me.isDoubleProbTimer && price >= me.balance && (
            <S.StarContainer>
              <Countdown
                date={
                  new Date(
                    new Date(me.isDoubleProbTimer).getTime() +
                      60 * 60 * 1000 * 3
                  )
                }
                renderer={CountdownDisplayDay}
                onComplete={timeoutBonus}
              />
              <BonusStar main={"X2"} descr={"шанс"} />
            </S.StarContainer>
          )}
        </S.ImgRelative>
      </S.TopContainer>
      <S.BottomContainer>
        {!me && (
          <>
            <S.Info>
              <S.InfoTitle>Вы не авторизованы!</S.InfoTitle>
              <div>Для открытия кейсов необходимо пройти авторизацию</div>
            </S.Info>
            <S.BtnDivider>
              <GradButton $isDark={false} onClick={toggle}>
                <div>Войти</div>
              </GradButton>
            </S.BtnDivider>
            <ModalContainer isOpen={isOpen} isShow={isShow} toggle={toggle} />
          </>
        )}
        {me && price > me.balance && (
          <>
            <S.Info>
              <S.InfoTitle>
                {price}₽ - не хватает {price - me.balance}₽
              </S.InfoTitle>
              <div>Недостаточно средств для открытия кейса</div>
            </S.Info>
            <S.BtnDivider>
              <GradButton $isDark={true} onClick={toggle}>
                <div>
                  <Wallet /> Пополнить баланс
                </div>
              </GradButton>
            </S.BtnDivider>
            <InputModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
          </>
        )}
        {me && price <= me.balance && (
          <>
            {!isAvailable &&
              !!freeType &&
              (me.timer![freeType as keyof Timers] ? (
                <S.Info>
                  <S.InfoTitle>Время до открытия</S.InfoTitle>
                  <Countdown
                    date={
                      new Date(
                        new Date(
                          me.timer![freeType as keyof Timers]
                        ).getTime() +
                          60 * 60 * 1000 * 3
                      )
                    }
                    renderer={CountdownDisplayText}
                    onComplete={() => setAvailable(true)}
                  />
                </S.Info>
              ) : (
                <S.Info>
                  <S.InfoTitle>Выполните условия</S.InfoTitle>
                  <div>
                    Чтобы разблокировать этот бесплатный кейс нужно открыть
                    кейсы от {freeLimits[freeType as keyof typeof freeLimits]}₽
                    за день
                  </div>
                </S.Info>
              ))}
            <S.BtnDivider $isSingle={isAvailable}>
              <S.Multiply>
                {type !== CaseVars.free &&
                  multis.map(({ multBy }) => (
                    <S.MultItem
                      key={multBy}
                      onClick={() => onClick(multBy)}
                      $isActive={multBy === activeMult}
                      $isClose={price * multBy > me.balance}
                      data-testid={`x${multBy}`}
                    >
                      {`x${multBy}`}
                    </S.MultItem>
                  ))}
              </S.Multiply>
              <GradButton
                disabled={!isAvailable}
                $isDark={true}
                onClick={() => {
                  if (me.isPayment) {
                    setGame("norm");
                  } else {
                    pushToast(ToastifyVars.noPay);
                  }
                }}
              >
                <div>
                  <Wallet /> Открыть за {price * activeMult}₽
                </div>
              </GradButton>
              <S.GreyBtnContainer $isNotAvailable={!isAvailable}>
                <S.GreyButton
                  disabled={!isAvailable}
                  onClick={() => {
                    if (me.isPayment) {
                      setGame("fast");
                    } else {
                      pushToast(ToastifyVars.noPay);
                    }
                  }}
                >
                  <Lightning /> Быстрое открытие
                </S.GreyButton>
              </S.GreyBtnContainer>
            </S.BtnDivider>
          </>
        )}
      </S.BottomContainer>
    </>
  );
};
