import * as S from "./Styles";
import * as C from "../TopContainer/Styles";
import { FormInput } from "../../../Styles/components";
import { ReactComponent as Check } from "../../../assets/icons/referral/check.svg";
import { Accordion, Items } from "../../../Components/Accordion/Accordion";
import { useFormInput } from "../../../hooks/useFormInput";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ACCEPT_REFERRAL } from "../../../graphql/Mutation";
import { GET_ME } from "../../../graphql/Query";
import { GradButton } from "../../../Styles/StyleComponents/GradButton/GradButton";
import { InputModal } from "../../../Components/InputModal/InputModal";
import { useToggleVisible } from "../../../hooks/useToggleVisible";

// type BottomContainerProps = {};
const items: Items = [
  {
    title: "Что такое инвайт-код?",
    body: "Инвайт-код — это код, который ваш знакомый может вставить в соответствующей форме ввода, что даст вам бонус при каждом пополнении счета, приглашенным пользователем."
  },
  {
    title: "Зачем нужна реферальная ссылка?",
    body: "Реферальная ссылка автоматизирует ввод вашего ивайт-кода пользователем. Ему требуется только перейти по ссылке и подтвердить свое согласие на приглашение."
  },
  {
    title: "Как мне получить бонус за приглашение?",
    body: "Бонусы начисляются автоматически, когда приглашенный вами пользователь пополняет баланс на сайте."
  }
];
export const BottomContainer = () => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  const { data } = useQuery(GET_ME);
  const [error, setError] = useState(false);
  const [values] = useFormInput({
    code: ""
  });
  const [send, { loading, client }] = useMutation(ACCEPT_REFERRAL, {
    onCompleted: (data) => {
      console.log(data);
      if (data.checkInviteCode) {
        const me = client.readQuery({ query: GET_ME });
        if (me && me.getMe) {
          const newData = {
            ...me,
            getMe: {
              ...me.getMe,
              isOld: true
            }
          };
          client.writeQuery({
            query: GET_ME,
            data: newData
          });
        }
      }
    },
    onError: () => setError(true)
  });

  const onClick = async () => {
    if (!values.value.code || loading) return;
    await send({
      variables: {
        code: values.value.code
      }
    });
  };
  return (
    <S.SecondWrapper>
      <C.Divider>
        <C.Title>Вопрос — ответ</C.Title>
        <C.InputContainer>
          <C.LimitContainer>
            <Accordion items={items} />
          </C.LimitContainer>
        </C.InputContainer>
      </C.Divider>
      <C.SecondDivider $isFirstOrder={true}>
        {data && data.getMe && !data.getMe.isOld && !data.getMe.isPayment && (
          <>
            <C.Title>Есть инвайт-код?</C.Title>
            <C.InputContainer>
              <C.LimitContainer>
                <C.Relative>
                  <FormInput
                    name={"code"}
                    id={"code"}
                    type={"text"}
                    placeholder={"Введите инвайт-код"}
                    value={values.value.code}
                    maxLength={8}
                    onChange={values.onChange}
                  />
                  <Check onClick={onClick} />
                </C.Relative>

                {error ? (
                  <S.ErrorInfo>
                    Произошла ошибка при активации, попробуйте еще раз позже.
                  </S.ErrorInfo>
                ) : (
                  <C.Description>
                    Для получения дополнительных бонусов, введите инвайт код,
                    который вы получили от своего знакомого.
                  </C.Description>
                )}
              </C.LimitContainer>
            </C.InputContainer>
          </>
        )}
        {data && data.getMe && data.getMe.isOld && !data.getMe.isPayment && (
          <>
            <C.Title>Инвайт-код подтвержден</C.Title>
            <C.Description>
              При первом пополнении вы получите +10% к сумме пополнения.
            </C.Description>
            <S.BtnContainer>
              <GradButton $variant={"green"} $isDark={true} onClick={toggle}>
                Пополнить
              </GradButton>
            </S.BtnContainer>
          </>
        )}
        {data && data.getMe && data.getMe.isOld && data.getMe.isPayment && (
          <>
            <C.Title>Инвайт-код подтвержден</C.Title>
            <C.Description>
              Ваш бонус +10% к сумме первого пополнения успешно начислен на ваш
              баланс.
            </C.Description>
            <C.Description>
              Приглашайте друзей и получайте еще больше бонусов вместе! Для
              приглашения воспользуйтесь своей инвайт-ссылкой или своим
              инвайт-кодом, которые указаны выше на данной странице.
            </C.Description>
            <C.Description>
              Также вы можете пополнить свой счет кликнув по кнопке ниже:
            </C.Description>
            <S.BtnContainer>
              <GradButton $variant={"green"} $isDark={true} onClick={toggle}>
                Пополнить
              </GradButton>
            </S.BtnContainer>
          </>
        )}
        {data && data.getMe && !data.getMe.isOld && data.getMe.isPayment && (
          <>
            <C.Title>Приглашайте друзей</C.Title>
            <C.Description>
              Начните получать 10% с каждого пополнения вашего друга на свой
              баланс.
            </C.Description>
            <C.Description>
              Приглашайте друзей и получайте еще больше бонусов вместе! Для
              приглашения воспользуйтесь своей инвайт-ссылкой или своим
              инвайт-кодом, которые указаны выше на данной странице.
            </C.Description>
            <C.Description>
              Также вы можете пополнить свой счет кликнув по кнопке ниже:
            </C.Description>
            <S.BtnContainer>
              <GradButton $variant={"green"} $isDark={true} onClick={toggle}>
                Пополнить
              </GradButton>
            </S.BtnContainer>
          </>
        )}
        <InputModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
      </C.SecondDivider>
    </S.SecondWrapper>
  );
};
