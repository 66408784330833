import { ModalContainerProps } from "../../pages/Marketplace/Help/ModalContainer/ModalContainer";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { LISTEN_ORDER_STATUS } from "../../graphql/Subscription";
import { useFormInput } from "../../hooks/useFormInput";
import { Modal } from "../Modal/Modal";
import {
  FormInput,
  ModalDescription,
  ModalHeading,
  WrapperModal
} from "../../Styles/components";
import * as S from "./Styles";
import { GreenBtn } from "../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { useEffect, useState } from "react";
import { SEND_OUTPUT_CODE } from "../../graphql/Mutation";
import { Relative } from "../../pages/Referral/TopContainer/Styles";
import { Loader } from "../Preloader/Loader/Loader";
import { GET_ME } from "../../graphql/Query";
import { useAppContext } from "../AppContext/AppContext";
import { ToastifyVars } from "../Toastify2/Toastify";
import { ErrorNotification } from "../InputModal/Main/Styles";

export const OrderModal = ({ isOpen, isShow, toggle }: ModalContainerProps) => {
  const [isError, setError] = useState(false);
  const { pushToast } = useAppContext();
  const { data: me } = useQuery(GET_ME, {
    onCompleted: (data) => {
      if (data && data.getMe && data.getMe.orderStatus.status)
        setStatus(data.getMe.orderStatus.status);
    }
  });
  const [status, setStatus] = useState<null | string>(
    me?.getMe?.orderStatus.status || "unconfirmed"
  );
  const [sendCode, { loading }] = useMutation(SEND_OUTPUT_CODE, {
    onCompleted: (data) => {
      if (data.outputCode) {
        setStatus("getcode");
      } else {
        setError(true);
      }
    },
    onError: (err) => {
      console.log(err);
      setError(true);
    }
  });
  useSubscription(LISTEN_ORDER_STATUS, {
    onData: (data) => {
      if (data && data.data && data.data.data) {
        const status = data.data.data.sourceStatus.status;
        if (!status) {
          toggle();
          setStatus(null);
        } else {
          setStatus(status);
        }
      }
    }
  });
  const [values, reset] = useFormInput({
    code: ""
  });
  const onSend = async () => {
    if (!values.value.code) return;
    await sendCode({
      variables: {
        code: values.value.code
      }
    });
  };

  // useEffect(() => {
  //   if (me && me.getMe && me.getMe.orderStatus.status !== status) {
  //     const status = !!me.getMe.orderStatus.status
  //       ? me.getMe.orderStatus.status
  //       : null;
  //     setStatus(status);
  //   }
  // }, [me]);
  return isOpen ? (
    <Modal isShow={isShow} onClose={() => pushToast(ToastifyVars.noClose)}>
      <WrapperModal>
        <ModalHeading>Вывод гемов</ModalHeading>
        {status === "unconfirmed" && (
          <>
            <ModalDescription>
              Ваша заявка в очереди на исполнение, как только оператор
              подтвердит ее, уведомление появится в данном окне автоматически.
              Ожидайте, пожалуйста.
            </ModalDescription>
            <Loader isSmall={true} />
          </>
        )}
        {status === "confirmed" && (
          <>
            <ModalDescription>
              Ваша заявка успешно подтверждена, вам на почту отправлен код для
              доступа к аккаунту. Введите свой код до истечения времени таймера
              для продолжения совершения операции.
            </ModalDescription>
            <S.Content>
              <Relative>
                <FormInput
                  name={"code"}
                  id={"code"}
                  placeholder={"Введите код из Email"}
                  value={values.value.code}
                  onChange={values.onChange}
                />
                <ErrorNotification $show={isError}>
                  {isError &&
                    "Произошла непредвиденная ошибка, попробуйте позже"}
                </ErrorNotification>
              </Relative>
              <GreenBtn
                $width100={true}
                $notUpper={true}
                $borderWidth={1}
                onClick={onSend}
                disabled={loading || !values.value.code}
              >
                {loading ? "Загрузка" : "Подтвердить"}
              </GreenBtn>
            </S.Content>
          </>
        )}
        {status === "getcode" && (
          <>
            <ModalDescription>
              Ваш код успешно отправлен. Наш оператор уже приступил к пополнению
              вашего игрового баланса, как только он завершит операцию, вы
              получите уведомление в данном окне. Ожидайте, пожалуйста.
            </ModalDescription>
            <Loader isSmall={true} />
          </>
        )}
        {status === "cancel" && (
          <>
            <ModalDescription>
              Ваша заявка отменена. Если вы не инициировали отмену заявки, то
              создайте новую через Торговую площадку сайта.
            </ModalDescription>
            <S.Content>
              <GreenBtn
                $width100={false}
                $notUpper={true}
                $borderWidth={1}
                onClick={toggle}
              >
                Закрыть окно
              </GreenBtn>
            </S.Content>
          </>
        )}
        {status === "success" && (
          <>
            <ModalDescription>
              Ваша заявка успешно выполнена и закрыта. На ваш игровой баланс уже
              поступили гемы. Приятной игры!
            </ModalDescription>
            <S.Content>
              <GreenBtn
                $width100={false}
                $notUpper={true}
                $borderWidth={1}
                onClick={toggle}
              >
                Закрыть окно
              </GreenBtn>
            </S.Content>
          </>
        )}
      </WrapperModal>
    </Modal>
  ) : null;
};
