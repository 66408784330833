import styled from "styled-components";
import { getTransition } from "../../../../Styles/utilites";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NavContainer = styled.nav`
  width: 100%;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: flex;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-top: 0.3em;
    max-width: 70%;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-top: 0.6em;
    max-width: 60%;
  }
`;

type NavItemProps = {
  $bg: string;
};
export const NavItem = styled(Link)<NavItemProps>`
  height: 5rem;
  text-decoration: none;
  flex: 1;
  z-index: ${({ theme }) => theme.order.one};
  position: relative;
  user-select: none;
  cursor: pointer;
  background: linear-gradient(
    258.27deg,
    ${({ theme }) => theme.colors.bgLayout} 16.46%,
    rgba(0, 0, 0, 0.77) 86.21%
  );
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  img {
    ${getTransition(300, "transform", "ease-in-out")}
  }
  span {
    color: ${({ theme }) => theme.colors.font} ${getTransition()};
  }
  &:before {
    z-index: ${({ theme }) => theme.order.one};
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url(${({ $bg }) => $bg}) no-repeat 4.5rem;
    background-size: 50%;
    opacity: 0;
    ${getTransition(300, "opacity", "ease-in-out")}
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    img {
      transform: scale(1.1);
    }
    span {
      color: ${({ theme }) => theme.colors.yellow};
    }
    z-index: ${({ theme }) => theme.order.two};
    box-shadow: 0 1px 10px 2px
      hsla(${({ theme }) => theme.colors.orangeValue}, 0.5);
  }
  ${getTransition(300, "box-shadow", "ease-in-out")}

  @media screen and ${({ theme }) => theme.media.sm} {
    height: auto;
    img {
      height: 4rem;
    }
    &:before {
      background-position: center;
      background-size: 80%;
    }
  }

  @media screen and ${({ theme }) => theme.media.md} {
    img {
      height: 6rem;
    }
  }
`;
export const Text = styled.span`
  font-weight: 700;
  font-size: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
  width: 10rem;
  text-align: center;
  @media screen and ${({ theme }) => theme.media.sm} {
    width: auto;
    max-width: 8rem;
    font-size: 0.7rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 0.9rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-right: 1.5rem;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 1rem;
  }
`;

export const SignContainer = styled.div`
  display: none;
  margin-left: auto;
  margin-right: 1rem;
  @media screen and ${({ theme }) => theme.media.lg} {
    display: block;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-right: 3rem;
  }
`;
