import styled from "styled-components";

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.yellow};
  position: absolute;
  background-color: ${({ theme }) => `hsla(${theme.colors.grey37Value}, 0.5)`};
  backdrop-filter: blur(3px);
  border-radius: 0.5em;
  z-index: ${({ theme }) => theme.order.fixed};
  width: 7rem;
  right: 0.5rem;
  box-shadow: 0 1px 10px 2px
    hsla(${({ theme }) => theme.colors.revertValue}, 0.5);

  @media screen and ${({ theme }) => theme.media.sm} {
    position: absolute;
    width: 66%;
    max-width: 7rem;
    //top: 0.5rem;
    bottom: 0.5rem;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export const IconDivider = styled.div`
  transform: scale(0.8);
  svg {
    path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    transform: scale(0.9);
  }
`;
// тут мой комментарий!
export const Container = styled.div`
  padding: 0.5em;
  opacity: 1;
  display: flex;
  align-content: center;
  justify-content: space-around;
`;

export const TextContainer = styled.div`
  text-align: center;
  flex: 0 1 50%;
`;

export const Count = styled.div`
  font-weight: 500;
  line-height: 1;
`;
export const Status = styled.div`
  font-size: 0.6rem;
  font-weight: 400;
`;
