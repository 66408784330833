import { ModalContainerProps } from "../../pages/Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../Modal/Modal";
import * as S from "./Styles";
import { useToggle } from "../../hooks/useToggle";
import { Agreement } from "./Agreement/Agreement";
import { Main } from "./Main/Main";

export const InputModal = ({
  isOpen,
  isShow,
  toggle,
  promo
}: ModalContainerProps & { promo?: string }) => {
  const [isAgreement, setAgreement] = useToggle();
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        {isAgreement ? (
          <Agreement back={setAgreement(false)} />
        ) : (
          <Main onAgreement={setAgreement(true)} promo={promo} />
        )}
      </S.Wrapper>
    </Modal>
  ) : null;
};
