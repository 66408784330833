import * as S from "./Styles";
import { GreenBtn } from "../../Styles/StyleComponents/GreenBtn/GreenBtn";
import { ReactComponent as Signin } from "../../assets/icons/signin.svg";
import { MiniProfile } from "./MiniProfile/MiniProfile";
import { useToggleVisible } from "../../hooks/useToggleVisible";
import { ModalContainer } from "./ModalContainer/ModalContainer";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../../graphql/Query";

// type SignComponentProps = {};
export const SignComponent = () => {
  const { data } = useQuery(IS_LOGGED_IN);
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  return (
    <>
      {data?.isLoggedIn ? (
        <MiniProfile />
      ) : (
        <>
          <GreenBtn onClick={toggle}>
            <S.Icon>
              <Signin />
            </S.Icon>
            вход
          </GreenBtn>
          <ModalContainer isOpen={isOpen} isShow={isShow} toggle={toggle} />
        </>
      )}
    </>
  );
};
