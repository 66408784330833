import * as S from "./Styles";
import { FlowType } from "../Sidebar";
import { ReactComponent as Top } from "../../../../assets/icons/top.svg";
import { ReactComponent as Day } from "../../../../assets/icons/day.svg";
import { ReactComponent as All } from "../../../../assets/icons/all.svg";
import { Logo } from "../../../Logo/Logo";
import { ReactElement } from "react";

type Props = {
  flow: FlowType;
  setFlow: (arg: FlowType) => void;
};
type Items = {
  type: FlowType;
  elt: ReactElement;
}[];
const items: Items = [
  {
    type: "all",
    elt: <All />
  },
  {
    type: "day",
    elt: <Day />
  },
  {
    type: "top",
    elt: <Top />
  }
];
export const LogoAndControl = ({ flow, setFlow }: Props) => {
  return (
    <S.TopContainer>
      <Logo />
      <S.Navigate>
        {items.map(({ type, elt }) => (
          <S.NavItem
            $isActive={type === flow}
            key={type}
            onClick={() => setFlow(type)}
            data-testid={type}
          >
            {elt}
          </S.NavItem>
        ))}
      </S.Navigate>
    </S.TopContainer>
  );
};
