import styled from "styled-components";

export const Wrapper = styled.section`
  text-align: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  @media screen and ${({ theme }) => theme.media.sm} {
    padding: 2rem;
  }
`;
export const Heading = styled.h5`
  font-weight: 700;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-bottom: 1em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin-bottom: 1.5em;
  }
`;

export const ItemContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 1em;
  list-style: none;
  flex-wrap: wrap;
`;
