import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 4rem;
  text-align: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  @media screen and ${({ theme }) => theme.media.sm} {
    padding: 2rem;
  }
`;

export const ItemContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 1em;
  list-style: none;
  flex-wrap: wrap;
`;

export const BtnDivider = styled.div`
  margin-top: 2rem;
`;
