import { ModalContainerProps } from "../../../pages/Marketplace/GemCase/ModalContainer/ModalContainer";
import { Modal } from "../../Modal/Modal";
import * as C from "../../../pages/Bonus/Bonuses/ModalContainer/Styles";
import { ModalHeading } from "../../../Styles/components";
import * as S from "./Styles";
import { ReactComponent as Google } from "../../../assets/icons/social/google.svg";
import { ReactComponent as Vk } from "../../../assets/icons/social/vk_volor.svg";

const auths = [
  {
    title: "Google",
    type: "google",
    img: <Google />,
    to: process.env.REACT_APP_URI + "google/login"
  },
  // {
  //   title: "Steam",
  //   type: "steam",
  //   img: <Steam />,
  //   to: "#"
  // },
  {
    title: "Вконтакте",
    type: "vk",
    img: <Vk />,
    to: process.env.REACT_APP_URI + "auth/vk"
  }
  // {
  //   title: "Telegram",
  //   type: "tg",
  //   img: <Tg />,
  //   to: "#"
  // }
];
export const ModalContainer = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <C.Wrapper>
        <ModalHeading>Войти через</ModalHeading>
        <S.Content>
          {auths.map(({ title, img, to, type }) => (
            <S.Block key={type} type={type} href={to}>
              {img}
              <span>{title}</span>
            </S.Block>
          ))}
        </S.Content>
      </C.Wrapper>
    </Modal>
  ) : null;
};
