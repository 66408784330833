import * as S from "./Styles";
import { useAppContext } from "../AppContext/AppContext";
import { Toast } from "./Toast/Toast";

export enum ToastifyVars {
  noThing = "noThing",
  noMoney = "noMoney",
  noAuth = "noAuth",
  dontOpen = "dontOpen",
  copy = "copy",
  noStart = "noStart",
  noPay = "noPay",
  noClose = "noClose"
}

const messages = [
  {
    type: "noPay",
    title: "Пополните баланс!",
    body: "Пополните баланс, чтобы открыть кейс"
  },
  {
    type: "noThing",
    title: "Нет предметов!",
    body: "Начните открывать кейсы"
  },
  {
    type: "noMoney",
    title: "Недостаточно средств!",
    body: "Пополните счет, для совершения действия"
  },
  {
    type: "noAuth",
    title: "Вы не авторизованы!",
    body: "Выполните вход, чтобы совершить это действие"
  },
  {
    type: "noStart",
    title: "Вы уже получили бонус!",
    body: "Вами уже был получен стартовый бонус"
  },
  {
    type: "dontOpen",
    title: "Не удалось открыть!",
    body: "Ошибка при открытии кейса, попробуйте еще раз"
  },
  {
    type: "copy",
    title: "Скопировано!",
    body: "Данные успешно скопированы в буфер обмена"
  },
  {
    type: "noClose",
    title: "Дождитесь завершения!",
    body: "Ваша заявка обрабатывается, подождите, пожалуйста"
  }
];
const getMessage = (typeStr: string) =>
  messages.find(({ type }) => type === typeStr);
export const Toastify = () => {
  const { toastifyArr, setToastifyArr } = useAppContext();

  return (
    <S.Wrapper>
      {toastifyArr.map(({ type, id }) => (
        <Toast
          title={getMessage(type)!.title}
          body={getMessage(type)!.body}
          remove={() =>
            setToastifyArr(toastifyArr.filter((toast) => toast.id !== id))
          }
          key={id}
        />
      ))}
    </S.Wrapper>
  );
};
