import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0 3rem;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    align-items: center;
    margin: 1.2rem 0 1.2rem;
  }
`;

export const Container = styled.div`
  margin: auto;
  @media screen and ${({ theme }) => theme.media.lg} {
    margin: initial;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    display: flex;
  }
`;
export const ItemDivider = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  svg {
    transform: scale(0.9);
    margin-right: 0.5em;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-bottom: 0;
    margin-right: 2em;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    margin-bottom: 0.5em;
  }
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 1.1rem;
  margin-right: auto;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.brightPurple} 0%,
    ${({ theme }) => theme.colors.darklyBlue} 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`;

export const Description = styled.div`
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 600;
`;
