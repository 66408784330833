import * as S from "./Styles";
import { GradButton } from "../../../../../../Styles/StyleComponents/GradButton/GradButton";

// type NewsProps = {};

const news = [
  {
    id: "1",
    heading: "Новость",
    description:
      "В рамках спецификации современных стандартов, сторонники тоталитаризма в науке могут быть представлены в исключительно положительном свете.",
    date: new Date().toLocaleDateString(),
    btnTitle: "Дерзай!",
    to: "/"
  },
  {
    id: "2",
    heading: "Новость",
    description:
      "В рамках спецификации современных стандартов, сторонники тоталитаризма в науке могут быть представлены в исключительно положительном свете.",
    date: new Date().toLocaleDateString(),
    btnTitle: "Дерзай!",
    to: "/"
  }
];
export const News = () => {
  return (
    <S.Wrapper>
      {news.map(({ id, btnTitle, description, heading, date, to }) => (
        <S.Container key={id}>
          <S.Date>{date}</S.Date>
          <S.Heading>{heading}</S.Heading>
          <S.Paragraph>{description}</S.Paragraph>
          <GradButton $variant={"orange"} style={{ width: "100%" }}>
            <div>{btnTitle}</div>
          </GradButton>
        </S.Container>
      ))}
    </S.Wrapper>
  );
};
