import styled, { css } from "styled-components";
import { ItemType } from "./Statistic";
import { getTransition } from "../../../../Styles/utilites";

export const Wrapper = styled.div`
  display: none;
  user-select: none;
  padding: 0.75em 0;
  width: 100%;
  > div:last-child {
    border-right: none;
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    display: flex;
    // margin: 0 -1em;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    margin: 0;
    padding: 1em 0.1em;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 1em 7vw;
    > div:last-child {
      border-right: 1px solid ${({ theme }) => theme.colors.grey37};
    }
    > div:first-child {
      border-left: 1px solid ${({ theme }) => theme.colors.grey37};
    }
  }
`;
type ItemProps = {
  type: ItemType;
};
export const Item = styled.div<ItemProps>`
  font-size: 0.7rem;
  line-height: 1.2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.grey37};
  path {
    fill: ${({ theme }) => theme.colors.yellow};
    ${getTransition(300, "fill")}
  }
  svg {
    margin-left: -0.5em;
    transform: scale(0.6);
    margin-right: 0.15em;
  }
  > div > div {
    ${getTransition()}
  }
  &:hover {
    ${({ theme, type }) => {
      const colors = {
        key: `${theme.colors.brightPurple}`,
        diamond: `${theme.colors.border}`,
        man: `${theme.colors.orange}`
      };
      const color = colors[type];
      return css`
        path {
          fill: ${color};
        }
        > div > div {
          color: ${color};
        }
      `;
    }}
  }

  @media screen and ${({ theme }) => theme.media.md} {
    font-size: 0.9rem;
    padding: 0 2%;
    svg {
      transform: scale(0.8);
      margin-right: 0.5em;
    }
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    font-size: 1rem;
    svg {
      margin-right: 1em;
    }
  }
`;

export const InfoDivider = styled.div`
  align-self: center;
`;

export const Count = styled.div`
  font-weight: 700;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.grey58};
  font-weight: 500;
  text-wrap: none;
`;
