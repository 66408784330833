import { useEffect } from "react";
import { useScrollWindowListener } from "./useScrollWindowListener";

type HookType = (bool: boolean, reducer: (arg: boolean) => void) => void;
export const useVisibleTopLink: HookType = (bool, reducer) => {
  const [pageY] = useScrollWindowListener();

  useEffect(() => {
    if (pageY <= 300) {
      reducer(false);
    } else if (pageY > 300 && !bool) {
      reducer(true);
    }
  }, [pageY]);
};
