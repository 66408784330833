import styled, { keyframes } from "styled-components";
import { getTransition } from "../../../Styles/utilites";

type ToastifyProps = {
  $isVisible: boolean;
};
export const ToastifyContainer = styled.div<ToastifyProps>`
  width: calc(100vw - 4rem);
  margin: 1rem;

  cursor: pointer;
  transform: ${({ $isVisible }) =>
    $isVisible ? "translateX(0)" : "translateX(110%)"};
  ${getTransition(
    400,
    ["transform", "position"],
    "cubic-bezier(0.47, -0.23, 0.49, 1.26)"
  )};

  @media screen and ${({ theme }) => theme.media.sm} {
    width: 26rem;
  }
`;

export const Toastify = styled.div`
  width: auto;
  font-weight: 600;
  padding: 16px;
  margin-bottom: 0.3em;
  font-size: 0.85rem;
  border-radius: 0.5em;
  background-color: hsla(${({ theme }) => theme.colors.revertValue}, 0.4);
  backdrop-filter: blur(5px); 
  &:after {
    position: absolute;
    right: 0;
    bottom: 24px;
    left: 0;
    width: calc(100% - 2rem);
    height: 4px;
    margin: auto;
    border-radius: 2px;
    content: "";
    background: hsla(302, 58%, 36%, 0.4);
  }

  &:hover {
    div {
      animation-play-state: paused;
    }
  }

  svg {
    height: 4rem;
    margin-right: 0.75em;
  }
`;

export const TopFlex = styled.div`
  display: flex;
  align-items: center;
`;
export const Heading = styled.div`
  color: ${({ theme }) => theme.colors.yellow};
  font-size: 1rem;
  line-height: 1.3;
  @media screen and ${({ theme }) => theme.media.sm} {
    font-size: 1.4rem;
  }
`;

const Toastify__trackProgress = keyframes`
0%{
  transform: scaleX(1);
}
  100%{
    transform: scaleX(0);
  }
`;

export const Progress = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.order.two};
  animation: ${Toastify__trackProgress} linear 1 forwards 3s;
  animation-delay: 350ms;
  animation-fill-mode: both;
  -webkit-transform-origin: left;
  transform-origin: left;
  opacity: 1;
  width: 100%;
  transform: scaleX(0.5);
  height: 4px;
  margin: 8px auto;
  border-radius: 2px;
  background-color: hsla(${({ theme }) => theme.colors.brightPurpleValue}, 0.7);
`;
