import styled from "styled-components";

export const Wrapper = styled.div`
  overflow-x: hidden;
  @media screen and ${({ theme }) => theme.media.sm} {
    overflow-x: initial;
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 1fr 3fr;
    gap: 0;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    grid-template-columns: 1fr 4fr;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    grid-template-columns: 1fr 6fr;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    grid-template-columns: 1fr 7fr;
  }
`;

export const Container = styled.div`
  grid-area: main;
  height: 100%;
  main {
    min-height: 100vh;
  }
`;
