import styled, { css } from "styled-components";

type WrapperProps = {
  $isInText?: boolean;
  $isWithBox?: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  z-index: 2;
  ${({ $isInText }) =>
    $isInText
      ? css`
          margin: 1em;
        `
      : css`
          margin: 1rem 1em 6rem 0;
          @media screen and ${({ theme }) => theme.media.lg} {
            margin: initial;
            margin-left: 1rem;
          }
          @media screen and ${({ theme }) => theme.media.xxl} {
            margin-left: 5rem;
          }
        `};
  ${({ $isWithBox }) =>
    $isWithBox &&
    css`
      background-color: hsla(
        ${({ theme }) => theme.colors.bgLayoutValue},
        0.35
      );
      //padding: 1rem;
      border-radius: 1rem;
    `};
`;
export const FlexDivider = styled.div`
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 1.2;
`;

export const TimeDivider = styled(FlexDivider)`
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  justify-content: space-between;

  //text-shadow: 0 0 5px hsla(${({ theme }) => theme.colors.revertValue}, 1);
`;

export const TimeCell = styled.div`
  flex: 1 0;
  width: 40px;
`;

export const SepDivider = styled.div`
  flex: 0 1;
  width: 20px;
`;
