import { useToggleVisible } from "../../../../../hooks/useToggleVisible";
import { GradButton } from "../../../../../Styles/StyleComponents/GradButton/GradButton";
import { OutputModal } from "./OutputModal/OutputModal";

//type OutputProps = {};
export const Output = () => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  return (
    <>
      <GradButton $variant={"yellow"} $isDark={true} onClick={toggle}>
        <div>Вывести</div>
      </GradButton>
      <OutputModal toggle={toggle} isShow={isShow} isOpen={isOpen} />
    </>
  );
};
