import * as S from "./Styles";
import Countdown from "react-countdown";
import { CountdownDisplay } from "../../../../Components/CountdownDisplay/CountdownDisplay";
import little from "../../../../assets/imgs/banner/little.png";
import big from "../../../../assets/imgs/banner/big.png";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ME, IS_LOGGED_IN } from "../../../../graphql/Query";
import { useToggleVisible } from "../../../../hooks/useToggleVisible";
import { ModalContainer } from "../../../../Components/SignComponent/ModalContainer/ModalContainer";
import { BonusStar } from "../../../../Components/BonusStar/BonusStar";

// type FooterProps = {};
export const Footer = () => {
  const { data, client } = useQuery(IS_LOGGED_IN);
  const navigate = useNavigate();
  const [isOpen, isShow, toggle] = useToggleVisible(300);

  const onClick = () => {
    if (data?.isLoggedIn) {
      const me = client.readQuery({
        query: GET_ME
      });
      if (!me || !me.getMe) return;
      navigate(`/user/${me.getMe.id}`);
    } else toggle();
  };
  return (
    <S.Wrapper>
      <S.Button onClick={onClick}>
        {data?.isLoggedIn ? "личный кабинет" : "авторизоваться"}
      </S.Button>
      <ModalContainer isOpen={isOpen} isShow={isShow} toggle={toggle} />
      <S.TimerDivider>
        <Countdown date={Date.now() + 1000000000} renderer={CountdownDisplay} />
        <S.PercentFigure>
          <BonusStar main={"+5%"} descr={"к вводу"} />
        </S.PercentFigure>
      </S.TimerDivider>
      <S.LittleMan src={little} alt={"Al Primo"} />
      <S.BigMan src={big} alt={"Big Al Primo"} />
    </S.Wrapper>
  );
};
