import styled from "styled-components";
import { getTransition } from "../../Styles/utilites";
type ContainerProps = {
  $isOpen: boolean;
  $isJsxIn?: boolean;
  $widthInRem?: number;
};
export const Container = styled.ul<ContainerProps>`
  position: absolute;
  z-index: ${({ theme }) => theme.order.height};
  display: block;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? "visible" : "hidden")};
  min-width: ${({ $widthInRem }) =>
    $widthInRem ? $widthInRem + "rem" : "10rem"};
  padding: 0.5rem 0;
  //padding: ${({ $isJsxIn }) => ($isJsxIn ? 0 : "0.5rem 1rem")};
  margin: 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey85};
  background-color: ${({ theme }) => theme.colors.bgLayout};
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border-radius: 1rem;
  border: ${({ theme }) => theme.colors.grey37} 1px solid;
  top: 100%;
  left: 50%;
  transform: ${({ $isOpen }) =>
    $isOpen ? "translate(-50%, -5px)" : "translate(-50%, -25px)"};
  box-shadow: 0 3px 5px 3px
    hsla(${({ theme }) => theme.colors.brightPurpleValue}, 0.4);
  li {
    cursor: pointer;
    padding: 0.5em 1em;
    &:hover {
      color: ${({ theme }) => theme.colors.font};
      background-color: ${({ theme }) => theme.colors.grey37};
    }
    ${getTransition()}
  }
  ${getTransition(150, ["opacity", "visibility", "transform"], "ease-out")}
`;
