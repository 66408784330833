import * as S from "./Styles";

type BonusStarProps = {
  main: string;
  descr: string;
};
export const BonusStar = ({ main, descr }: BonusStarProps) => {
  return (
    <S.PercentFigure>
      <S.Percent>{main}</S.Percent>
      <S.PercentDescr>{descr}</S.PercentDescr>
    </S.PercentFigure>
  );
};
