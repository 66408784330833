import { useToggleVisible } from "../../../../../hooks/useToggleVisible";
import { GradButton } from "../../../../../Styles/StyleComponents/GradButton/GradButton";
import { InputModal } from "../../../../../Components/InputModal/InputModal";

//type InputProps = {};
export const Input = () => {
  const [isOpen, isShow, toggle] = useToggleVisible(300);
  return (
    <>
      <GradButton $variant={"green"} $isDark={true} onClick={toggle}>
        <div>Пополнить</div>
      </GradButton>
      <InputModal isOpen={isOpen} isShow={isShow} toggle={toggle} />
    </>
  );
};
