import styled from "styled-components";

export const PercentFigure = styled.div`
  //z-index: 1;
  //position: absolute;
  background-color: ${({ theme }) => theme.colors.brightPurple};
  box-shadow: 0px 0px 5px 5px
    hsla(${({ theme }) => theme.colors.revertValue}, 0.25);

  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  transform: rotate(7deg);

  // right: -45px;
  // top: 15px;
  // @media screen and ${({ theme }) => theme.media.lg} {
  //   right: -60px;
  //   top: 0px;
  // }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    background: ${({ theme }) => theme.colors.brightPurple};
  }
  &:before {
    transform: rotate(30deg);
  }
  &:after {
    transform: rotate(60deg);
  }
`;

export const Percent = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  position: relative;
  z-index: ${({ theme }) => theme.order.three};
  color: ${({ theme }) => theme.colors.font};
`;
export const PercentDescr = styled(Percent)`
  font-size: 10px;
  white-space: nowrap;
`;
