import styled, { css } from "styled-components";
import { getTransition } from "../../Styles/utilites";
import { Link } from "react-router-dom";

export const Wrapper = styled.section`
  text-align: center;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.bgLayout} 98%,
    transparent 100%
  );
  margin: 0;
  padding: 4rem 2rem 8rem;
  @media screen and ${({ theme }) => theme.media.xl} {
    padding: 4rem 6rem 8rem;
  }
  @media screen and ${({ theme }) => theme.media.xxl} {
    padding: 4rem 10rem 8rem;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.revert};
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 3rem;
  overflow-y: scroll;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

type MenuItemProps = {
  $isActive: boolean;
};
export const MenuItem = styled(Link)<MenuItemProps>`
  color: ${({ theme }) => theme.colors.grey45};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.font};
    `}
  &:hover {
    ${({ $isActive }) =>
      $isActive
        ? css`
            color: inherit;
          `
        : css`
            color: ${({ theme }) => theme.colors.grey58};
            text-decoration: underline;
          `}
  }
  ${getTransition()}
`;
