import * as S from "./Styles";
import { TopContainer } from "./TopContainer/TopContainer";
import { BottomContainer } from "./BottomContainer/BottomContainer";
import { User as UserType } from "../../../gql/graphql";

type ProfileProps = {
  user: UserType;
  isMine: boolean;
};

export const Profile = ({ user, isMine }: ProfileProps) => {
  return (
    <div>
      <S.Name>{user.first_name}</S.Name>
      <TopContainer user={user} isMine={isMine} />
      {isMine && <BottomContainer user={user} />}
    </div>
  );
};
