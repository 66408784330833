import { ReactComponent as Game } from "../../../../assets/icons/banner/game.svg";
import { ReactComponent as Present } from "../../../../assets/icons/banner/present.svg";
import { ReactComponent as Time } from "../../../../assets/icons/banner/time.svg";
import * as S from "./Styles";
import { ReactElement } from "react";

// type BodyProps = {};
type Item = {
  img: ReactElement;
  title: string;
  description: string;
  substring: string;
};
const items: Item[] = [
  {
    img: <Time />,
    title: "Скорость",
    description: "Быстрый вывод",
    substring: "гемов"
  },
  {
    img: <Game />,
    title: "Надежность",
    description: "Высокие шансы",
    substring: "80%"
  },
  {
    img: <Present />,
    title: "Выгодно",
    description: "Бонусы каждый",
    substring: "день"
  }
];
export const Body = () => {
  return (
    <S.Wrapper>
      <S.Container>
        {items.slice(0, -1).map(({ title, img, substring, description }, i) => (
          <S.ItemDivider key={i}>
            {img}
            <S.TextColumn>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
              <div>{substring}</div>
            </S.TextColumn>
          </S.ItemDivider>
        ))}
      </S.Container>
      <S.Container>
        {items.slice(2).map(({ title, img, substring, description }) => (
          <S.ItemDivider key={3}>
            {img}
            <S.TextColumn>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
              <div>{substring}</div>
            </S.TextColumn>
          </S.ItemDivider>
        ))}
      </S.Container>
    </S.Wrapper>
  );
};
