import * as S from "./Styles";
import { LogoAndControl } from "./LogoAndControl/LogoAndControl";
import { ItemsFlow } from "./ItemsFlow/ItemsFlow";
import { useState } from "react";

export type FlowType = "all" | "day" | "top";

type SidebarProps = {
  online: number;
};
export const Sidebar = ({ online }: SidebarProps) => {
  const [flow, setFlow] = useState<FlowType>("all");
  return (
    <S.Wrapper>
      <LogoAndControl flow={flow} setFlow={setFlow} />
      <ItemsFlow flow={flow} online={online} />
    </S.Wrapper>
  );
};
