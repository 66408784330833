import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ErrorDivider = styled.div`
  font-weight: 700;
  text-align: center;
  margin: -20rem 2rem 0;
  background-image: linear-gradient(
    -45deg,
    hsl(0, 0%, 100%),
    hsl(351, 10%, 80%)
  );
  border: 2px solid hsla(${({ theme }) => theme.colors.red}, 0.8);
  border-radius: 1rem;
  box-shadow: 0 0 6px 1px ${({ theme }) => theme.colors.red};
  color: hsla(${({ theme }) => theme.colors.revertValue}, 0.7);
  padding: 1em;

  @media screen and ${({ theme }) => theme.media.sm} {
    margin: -20rem 2rem 0;
  }
`;
export const Link = styled.a`
  font-weight: bold;
`;
