import { useState } from "react";
import * as S from "./Styles";
import { AccordionItem } from "./AccordionItem/AccordionItem";

export type Items = {
  title: string;
  body: string;
}[];
type AccordionProps = {
  items: Items;
};

export const Accordion = ({ items }: AccordionProps) => {
  const [openItem, setOpenItem] = useState(0);

  const changeOpenItem = (item: number) => {
    if (openItem === item) {
      setOpenItem(0);
    } else {
      setOpenItem(item);
    }
  };

  return (
    <S.Wrapper>
      {items.map((item, i) => (
        <AccordionItem
          key={i}
          title={item.title}
          body={item.body}
          isOpen={openItem === i + 1}
          onToggle={() => changeOpenItem(i + 1)}
          idx={i}
        />
      ))}
    </S.Wrapper>
  );
};
