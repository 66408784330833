import { graphql } from "../gql";

// export const SIGNUP_USER = gql(`
//   mutation SignUp($username: String!, $password: String!) {
//     signUp(username: $username, password: $password)
//   }
// `);
//
// export const SIGNIN_USER = gql(`
//   mutation SignIn($username: String!, $password: String!) {
//     signIn(username: $username, password: $password)
//   }
// `);

// export const LOGOUT = graphql(`
//   mutation Logout {
//     logout
//   }
// `);

export const OPEN_CASE = graphql(`
  mutation OpenCase($id: ID!, $multby: Int!) {
    opencase(id: $id, multby: $multby) {
      id
      image
      price
      name
      type
    }
  }
`);

export const GET_YT_BONUS = graphql(`
  mutation GetYoutubeBonus {
    youtube_bonus
  }
`);

export const SELL_ALL_ITEMS = graphql(`
  mutation SellAllItems {
    sellAllItems
  }
`);

export const SELL_LAST_ITEMS = graphql(`
  mutation SellLastItems($multBy: Int) {
    sellAllItems(multby: $multBy)
  }
`);

export const SET_PROMOCODE = graphql(`
  mutation SetPromocode($code: String!) {
    promocode(code: $code)
  }
`);

export const ACCEPT_REFERRAL = graphql(`
  mutation AcceptReferral($code: String!) {
    checkInviteCode(code: $code)
  }
`);

export const CONVERT_REF_BALANCE = graphql(`
  mutation ConvertRefBalance {
    convertRefBalance
  }
`);

export const SEND_OUTPUT_DATA = graphql(`
  mutation SendOutputData($email: String!, $sum: Int!) {
    outputEmailSum(email: $email, sum: $sum)
  }
`);

export const SEND_OUTPUT_CODE = graphql(`
  mutation SendOutputCode($code: String!) {
    outputCode(code: $code)
  }
`);
