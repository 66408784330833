import styled from "styled-components";
import { getTransition } from "../../../../Styles/utilites";

export const TopContainer = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.order.two};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.bgLayout};
`;

export const Navigate = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 0.5rem;
  }
`;

type NavItemProps = {
  $isActive: boolean;
};
export const NavItem = styled.div<NavItemProps>`
  text-align: center;
  padding: 0.2rem;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.grey58 : "transparent"};
  cursor: pointer;
  ${getTransition()}
  svg {
    height: 1.5rem;
    transform: scale(0.65);
  }
  path {
    fill: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.yellow : theme.colors.grey45};
  }
  line {
    stroke: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.yellow : theme.colors.grey45};
  }
  path,
  line {
    ${getTransition(300, ["fill", "stroke"])}
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
    line {
      stroke: ${({ theme }) => theme.colors.yellow};
    }
  }
  @media screen and ${({ theme }) => theme.media.sm} {
    padding: 0.5rem;
    background-color: transparent;
    svg {
      transform: scale(0.8);
    }
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    svg {
      transform: scale(0.9);
    }
  }
`;
