import styled from "styled-components";

export const Text = styled.div`
  text-align: justify;
  margin-top: 3rem;
`;

export const Paragraph = styled.p`
  margin-bottom: 1rem;
`;

export const Heading = styled.div`
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 2rem;
`;
