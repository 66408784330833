import { ModalContainerProps } from "../../../pages/Marketplace/Help/ModalContainer/ModalContainer";
import { Modal } from "../../Modal/Modal";
import * as S from "./Styles";
import {
  FormInput,
  ModalDescription,
  ModalHeading
} from "../../../Styles/components";
import { GradButton } from "../../../Styles/StyleComponents/GradButton/GradButton";
import { GreyButton } from "../../../pages/Case/Header/Styles";
import { useMutation } from "@apollo/client";
import { ACCEPT_REFERRAL } from "../../../graphql/Mutation";
import { useState } from "react";
import { GET_ME } from "../../../graphql/Query";

export const ReferralModal = ({
  isOpen,
  isShow,
  toggle
}: ModalContainerProps) => {
  const [error, setError] = useState(false);
  const [send, { loading, client }] = useMutation(ACCEPT_REFERRAL, {
    variables: {
      code: localStorage.getItem("referralCode") as string
    },
    onCompleted: (data) => {
      console.log("invite complete");
      if (data.checkInviteCode) {
        const me = client.readQuery({ query: GET_ME });
        if (me && me.getMe) {
          const newData = {
            ...me,
            getMe: {
              ...me.getMe,
              isOld: true
            }
          };
          client.writeQuery({
            query: GET_ME,
            data: newData
          });
        }
        toggle();
        localStorage.removeItem("referralCode");
      }
    },
    onError: () => setError(true)
  });
  const onCancel = () => {
    toggle();
    if (!error) localStorage.removeItem("referralCode");
  };
  const onConfirm = async () => {
    await send();
  };
  return isOpen ? (
    <Modal isShow={isShow} onClose={toggle}>
      <S.Wrapper>
        <ModalHeading>Подтверждение ввода</ModalHeading>
        <ModalDescription>
          Задействовать данный код для получения бонуса?
        </ModalDescription>
        <S.Relative>
          <FormInput
            name={"code"}
            id={"code"}
            type={"text"}
            placeholder={"Инвайт-код"}
            value={localStorage.getItem("referralCode") as string}
            readOnly
          />
          {error && (
            <S.ErrorInfo>
              Произошла ошибка при активации, попробуйте еще раз позже.
            </S.ErrorInfo>
          )}
        </S.Relative>
        <S.ActionContainer>
          <GradButton onClick={onConfirm} $variant={"green"} disabled={loading}>
            {loading ? "Загрузка" : "Подтвердить"}
          </GradButton>
          <GreyButton onClick={onCancel}>Отмена</GreyButton>
        </S.ActionContainer>
      </S.Wrapper>
    </Modal>
  ) : null;
};
