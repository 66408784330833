import { CardData } from "./cardsData";

export enum CaseVars {
  auto = "auto",
  trof = "trof",
  pers = "pers",
  levels = "levels",
  legend = "legend",
  gem = "gem",
  free = "free",
  combine = "combine"
}

export type Case = {
  id: string;
  img: string;
  price: number;
  name: string;
  type: CaseVars;
  items: CardData[];
};
