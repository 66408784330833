import styled from "styled-components";

type AvatarProps = {
  $isOnline: boolean;
};
export const Avatar = styled.div<AvatarProps>`
  position: relative;
  img {
    cursor: pointer;
    border-radius: 0.5rem;
    object-fit: cover;
    height: 4rem;
    width: 4rem;
  }
  &:after {
    position: absolute;
    content: "";
    top: -5px;
    right: 5px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.bgLayout};
    background-color: ${({ theme, $isOnline }) =>
      $isOnline ? theme.colors.greenGrad : theme.colors.red};
  }
`;
