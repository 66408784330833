import styled, { css } from "styled-components";
import { ModalDescription } from "../../../Styles/components";
import { getTransition } from "../../../Styles/utilites";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

export const DividerStyle = css`
  //height: 11rem;
  text-align: center;
  width: 100%;
  height: 15rem;
  padding: 1rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.bgLayout};
  @media screen and ${({ theme }) => theme.media.sm} {
    height: 14rem;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-basis: 50%;
  }
`;

export const Divider = styled.div`
  ${DividerStyle};
  order: 2;
  margin-bottom: 1rem;
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-bottom: 0;
  }
`;

type SecondDividerProps = {
  $isFirstOrder?: boolean;
};
export const SecondDivider = styled.div<SecondDividerProps>`
  ${DividerStyle};
  margin-left: 0;
  order: 3;
  ${({ $isFirstOrder }) =>
    $isFirstOrder &&
    css`
      order: 1;
      margin-bottom: 1rem;
    `}
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-left: 1rem;
    order: 3;
    margin-bottom: 0;
  }
`;
export const Title = styled.h5`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const InputContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LimitContainer = styled.div`
  max-width: 550px;
  width: 100%;
`;
export const Relative = styled.div`
  position: relative;
  //max-width: 500px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 1.5rem;
  svg {
    cursor: pointer;
    transform: scale(0.8);
    position: absolute;
    right: 1rem;
    top: auto;
    bottom: auto;
    opacity: 0.6;
    &:hover {
      opacity: 1;
      transform: scale(0.9);
    }
    ${getTransition(300, ["opacity", "transform"])}
  }
`;

export const Description = styled(ModalDescription)`
  font-size: 0.85rem;
  margin: 1rem 0.25rem 0.5rem;
  text-align: center;
  line-height: 1.2;
`;
