import * as S from "./Styles";
import { MutableRefObject, useRef } from "react";

type AccordionItemProps = {
  title: string;
  body: string;
  onToggle: () => void;
  isOpen: boolean;
  idx: number;
};
export const AccordionItem = ({
  title,
  body,
  onToggle,
  isOpen,
  idx
}: AccordionItemProps) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  return (
    <S.AccordionItem>
      <S.Heading
        $isOpen={isOpen}
        onClick={onToggle}
        data-testid={"accordionItem" + idx}
      >
        <S.PlusMinusContainer>
          <S.PlusMinus $isOpen={isOpen} />
        </S.PlusMinusContainer>

        {title}
      </S.Heading>
      <S.AccBodyContainer
        $isOpen={isOpen}
        $height={isOpen ? ref.current.scrollHeight : 0}
        ref={ref}
        data-testid={"accordionBody"}
      >
        <S.BodyText>{body}</S.BodyText>
      </S.AccBodyContainer>
    </S.AccordionItem>
  );
};
