import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { getTransition } from "../../../../Styles/utilites";

export const Wrapper = styled.div`
  flex: 0 0 45%;
  margin-right: 0.2em;
  @media screen and ${({ theme }) => theme.media.lg} {
    flex: initial;
    flex-basis: 45%;
    margin-right: 0;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    flex-basis: initial;
  }
`;
export const TopInfo = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 4rem;
  }
`;
export const Heading = styled.h5`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  justify-self: baseline;
  margin-left: 1rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin-left: 1rem;
  }
`;

export const ListContainer = styled.ul`
  font-size: 1rem;
  list-style: none;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2.3em;
`;

export const ListElt = styled.li`
  margin-top: 0.3em;
`;
type LinkProps = {
  $isMail?: boolean;
};
export const Link = styled(NavLink)<LinkProps>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grey58};
  &:hover {
    color: ${({ theme, $isMail }) =>
      $isMail ? theme.colors.orange : theme.colors.font};
  }
  svg {
    font-size: 0.85rem;
    margin-right: 0.85em;
    vertical-align: -2px;
  }
  ${getTransition()}
`;
