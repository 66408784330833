import styled from "styled-components";
import { getTransition, UniversalButton } from "../../../Styles/utilites";

export const Wrapper = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and ${({ theme }) => theme.media.xl} {
    margin: 4rem 0;
  }
`;

export const Spins = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0 0 2rem;
  overflow-x: hidden;
  max-width: calc(100vw - 2rem);
  @media screen and ${({ theme }) => theme.media.sm} {
    max-width: 73vw;
  }
  @media screen and ${({ theme }) => theme.media.xl} {
    max-width: 80vw;
  }
`;

export const FixedWidthDivider = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 0 0.75em;
  background: ${({ theme }) => theme.colors.bgLayout};
  border: 0.25em solid ${({ theme }) => theme.colors.brightPurple};
  border-radius: 1rem;
  &:after {
    z-index: ${({ theme }) => theme.order.three};
    content: "";
    position: absolute;
    left: -1px;
    height: 200px;
    width: 2rem;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.border} 0%,
      rgba(0, 0, 0, 0) 100%
    );
    backdrop-filter: blur(2px);
    mix-blend-mode: multiply;
  }
  &:before {
    z-index: ${({ theme }) => theme.order.three};
    content: "";
    position: absolute;
    right: -1px;
    height: 200px;
    width: 2rem;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      ${({ theme }) => theme.colors.border} 100%
    );
    backdrop-filter: blur(2px);
    mix-blend-mode: multiply;
  }
  @media screen and ${({ theme }) => theme.media.md} {
    &:before,
    &:after {
      width: 4rem;
    }
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin: 0 0 1em;
  }
`;
export const SpinContainer = styled.li`
  position: relative;
  overflow: hidden;
  padding: 0;
  list-style: none;
  min-width: 10000px;
  max-width: 10000px;
  width: 10000px;
  @media screen and ${({ theme }) => theme.media.lg} {
    padding: 0.5em 0;
  }
`;
export const SpinIndicator = styled.div`
  position: absolute;
  width: 2px;
  top: 0;
  height: 200px;
  left: 50%;
  margin-left: 0;
  background-color: ${({ theme }) => theme.colors.orange};
`;
type SpinRowProps = {
  transform?: number;
  $isFast: boolean;
  $isEnd: boolean;
};
export const SpinRow = styled.ul<SpinRowProps>`
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: left;
  li {
    min-width: 9rem;
    height: 5.5rem;
    margin: 0.5em 0;
    font-size: 0.65rem;
    img {
      height: 3.3rem;
    }
    h5 {
      font-size: 0.6rem;
    }
  }
  transform: translateX(
    ${({ transform, $isFast }) =>
      transform ? transform + "px" : $isFast ? "-3000px" : "3615px"}
  );
  ${({ $isFast, transform, $isEnd }) =>
    !!transform &&
    !$isEnd &&
    getTransition($isFast ? 500 : 10000, "transform", "ease")};
  @media screen and ${({ theme }) => theme.media.xxl} {
    li {
      min-width: initial;
    }
  }
`;

type BtnDividerProps = {
  $isEnd: boolean;
};

export const BtnDivider = styled.div<BtnDividerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 1em 0.75em;
  font-weight: 700;
  visibility: ${({ $isEnd }) => ($isEnd ? "visible" : "hidden")};
  opacity: ${({ $isEnd }) => ($isEnd ? 1 : 0)};
  ${getTransition(300, "opacity")};
  @media screen and ${({ theme }) => theme.media.md} {
    display: block;
    margin: 0 auto 1em;
  }
`;

const UniversalBtn = styled(UniversalButton)`
  width: 100%;
  &:hover {
    opacity: 0.6;
  }
  ${getTransition(300, "opacity")}

  @media screen and ${({ theme }) => theme.media.md} {
    width: auto;
  }
`;

const BtnWithMargin = styled(UniversalBtn)`
  margin-top: 0.5rem;
  @media screen and ${({ theme }) => theme.media.md} {
    margin-top: 0;
    margin-left: 0.75rem;
    width: auto;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    margin-top: 0;
    margin-left: 1rem;
    width: auto;
  }
`;
export const BackBtn = styled(UniversalBtn)`
  color: ${({ theme }) => theme.colors.font};
  background: ${({ theme }) => theme.colors.grey37};
  svg {
    vertical-align: -7px;
  }
`;

export const RetryBtn = styled(BtnWithMargin)`
  color: ${({ theme }) => theme.colors.revert};
  background: ${({ theme }) => theme.colors.yellow};
  svg {
    font-size: 0.95rem;
    vertical-align: -6px;
  }
`;

export const SellBtn = styled(BtnWithMargin)`
  color: ${({ theme }) => theme.colors.font};
  background: ${({ theme }) => theme.colors.red};
  svg {
    font-size: 0.65rem;
    vertical-align: -6px;
  }
`;
