import { PageHeading, PageWrapper } from "../../Styles/components";
import * as S from "./Styles";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as SortDown } from "../../assets/icons/dropdown/sortDown.svg";
import { ReactComponent as Triangle } from "../../assets/icons/dropdown/tringle.svg";
import { Dropdown } from "../../Components/Dropdown/Dropdown";
import { useToggle } from "../../hooks/useToggle";
import {
  MutableRefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Content } from "./Content/Content";
import { useFormInput } from "../../hooks/useFormInput";
import _ from "lodash";
import { useReloadScrollTop } from "../../hooks/useReloadScrollTop";
import { useQuery } from "@apollo/client";
import { GET_ALL_ITEMS } from "../../graphql/Query";
import { Loader } from "../../Components/Preloader/Loader/Loader";
import { Error404 } from "../404/Error404";

// type ItemsProps = {};

const sortArray = [
  {
    type: "typeNum/desc",
    title: "По редкости"
  },
  {
    type: "typeNum/asc",
    title: "По редкости"
  },
  {
    type: "price/desc",
    title: "По цене"
  },
  {
    type: "price/asc",
    title: "По цене"
  }
];

const typeToNum = {
  simple: "1",
  items: "2",
  epic: "3",
  secret: "4",
  gold: "5"
};

const Items = () => {
  const { data, loading } = useQuery(GET_ALL_ITEMS);
  const [search] = useFormInput({ search: "" });
  const [sort, setSort] = useState("typeNum/desc");
  const [isMenuOpen, setMenuOpen] = useToggle();
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(setMenuOpen(false), ref);

  const dropArray = useMemo(
    () =>
      sortArray.map(({ type, title }) => ({
        content: (
          <S.DirectionDivider $isDes={type.endsWith("desc")}>
            <SortDown />
            {title}
          </S.DirectionDivider>
        ),
        action: () => {
          setSort(type);
          setMenuOpen()();
        }
      })),
    [setMenuOpen]
  );

  const items = useMemo(() => {
    if (!data) return;
    return _.chain(
      data.getallItems.map((item) => ({
        ...item,
        typeNum: typeToNum[item.type as keyof typeof typeToNum]
      }))
    )
      .filter(
        ({ name }) =>
          !search.value.search ||
          name.toLowerCase().includes(search.value.search.toLowerCase())
      )
      .orderBy([sort.split("/")[0]], [sort.split("/")[1] as "asc" | "desc"])
      .value();
  }, [search.value.search, sort, data]);

  useLayoutEffect(() => {
    document.title = "Brawl Cases | Предметы";
  }, []);
  useReloadScrollTop();

  if (loading) return <Loader />;
  if (!data || !data.getallItems.length) return <Error404 />;
  return (
    <PageWrapper>
      <S.Header>
        <PageHeading>Предметы</PageHeading>
        <S.SearchContainer>
          <S.SearchInput
            placeholder={"Поиск"}
            id={"search"}
            type={"text"}
            // type={"search"}
            name={"search"}
            role={"search"}
            onChange={search.onChange}
            value={search.value.search}
          />
          <S.InputSpan>
            <SearchIcon />
          </S.InputSpan>
        </S.SearchContainer>
        <S.RelativeDivider ref={ref}>
          <S.SortContainer
            onClick={setMenuOpen()}
            $isMenuOpen={isMenuOpen}
            data-testid={"sort-container"}
          >
            <S.DirectionDivider $isDes={sort.endsWith("desc")}>
              <SortDown />
              {sortArray.find((elt) => elt.type === sort)!.title}
            </S.DirectionDivider>
            <span>
              <Triangle />
            </span>
          </S.SortContainer>
          <Dropdown isOpen={isMenuOpen} array={dropArray} widthInRem={16} />
        </S.RelativeDivider>
      </S.Header>
      <Content items={items!} noThings={"По данному запросу нет данных."} />
    </PageWrapper>
  );
};

export default Items;
