import styled from "styled-components";
import { Link } from "react-router-dom";
import { getTransition } from "../../../../Styles/utilites";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const LogoWrapper = styled.div`
  display: block;
  @media screen and ${({ theme }) => theme.media.sm} {
    display: none;
  }
`;

export const Links = styled.div`
  max-width: 10rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    max-width: none;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;
export const Support = styled.div`
  display: flex;
  @media screen and ${({ theme }) => theme.media.sm} {
    flex-direction: column;
  }
  @media screen and ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.font};
  &:hover {
    color: ${({ theme }) => theme.colors.grey58};
  }
  ${getTransition()}
`;
export const FaqLink = styled(StyledLink)`
  text-decoration: none;
  margin-right: 0.5em;
`;

export const SignContainer = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  @media screen and ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;
