import * as S from "./Styles";
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import { useEffect } from "react";
import { useToggleVisible } from "../../../hooks/useToggleVisible";
import { useTimeout } from "../../../hooks/useTimeout";

type ToastProps = {
  title: string;
  body: string;
  remove: () => void;
};
export const Toast = ({ title, body, remove }: ToastProps) => {
  const [isOpen, isVisible, toggle] = useToggleVisible(400);
  const closeAndClear = () => {
    clearTimeOut();
    toggle();
    timeToToggleToastType();
  };
  const [timeToToggleToastType] = useTimeout(remove, 400);
  const [setTimeOut, clearTimeOut, pauseTimeOut, resume] = useTimeout(
    closeAndClear,
    3400
  );

  const onMouseLeave = () => {
    resume();
  };

  useEffect(() => {
    toggle();
    setTimeOut();
  }, []);

  if (!isOpen) return <></>;
  return (
    <S.ToastifyContainer
      onMouseEnter={pauseTimeOut}
      onMouseLeave={onMouseLeave}
      onClick={closeAndClear}
      $isVisible={isVisible}
      data-testid={"toast"}
    >
      <S.Toastify>
        <S.TopFlex>
          <Warning />{" "}
          <div>
            <S.Heading>{title}</S.Heading>
            <div>{body}</div>
          </div>
        </S.TopFlex>
        <S.Progress />
      </S.Toastify>
    </S.ToastifyContainer>
  );
};
