import styled, { css } from "styled-components";
import { getTransition } from "../../../Styles/utilites";

type BlockProps = {
  type: string;
};
export const Block = styled.a<BlockProps>`
  text-decoration: none;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: bold;
  text-align: center;
  border-radius: 0.5em;
  width: 100%;
  max-width: 35rem;
  padding: 0.5em 1em;
  background: hsl(0, 0%, 25%);
  margin-top: 1rem;
  gap: 0.5rem;
  svg {
    flex: 0 0;
    flex-basis: 1.5rem;
    margin: -.35rem 1rem -.35rem 0;
    ${getTransition(300, "fill")}
  }
  ${({ theme, type }) => {
    type Colors = typeof colors;
    const colors = {
      google: theme.colors.secret,
      steam: theme.colors.border,
      vk: theme.colors.simple,
      tg: theme.colors.tgColor
    };
    const color = colors[type as keyof Colors];
    return css`
      border: 1px solid ${color};
      color: ${color};
      &:hover {
        background: ${color};
        color: hsl(0, 0%, 25%);
        svg {
          path {
            fill: hsl(0, 0%, 25%);
          }
        }
      }
    `;
  }};
  ${getTransition()}
  }
`;

export const Divider = styled.div`
  margin: auto;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 10em;
  max-height: 80vh;
  // margin-bottom: 4rem;
  margin: 2rem 0 4rem;
  @media screen and ${({ theme }) => theme.media.sm} {
    margin: 1rem 0 4rem;
    max-height: 35rem;
  }
`;
