import * as S from "./Styles";
import { useToggle } from "../../../../hooks/useToggle";
import { Popover } from "./Popover/Popover";
import { MutableRefObject, useRef } from "react";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { User as UserType } from "../../../../gql/graphql";

type AvaAndPopoverProps = {
  me: UserType;
};
export const AvaAndPopover = ({ me }: AvaAndPopoverProps) => {
  const [isOpen, setOpen] = useToggle();
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(setOpen(false), ref);

  return (
    <S.Avatar $isOnline={true} ref={ref}>
      <img
        src={me.avatar}
        alt={me.first_name}
        onClick={setOpen()}
        data-testid={"miniprofile-ava"}
      />
      <Popover isOpen={isOpen} userId={me.id} toggle={setOpen(false)} />
    </S.Avatar>
  );
};
