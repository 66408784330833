import { FC } from "react";
import * as S from "./Styles";
import { IError } from "../ErrorBoundary/ErrorBoundary";

export const ErrorScreen: FC<IError> = ({ error }) => {
  return (
    <S.Wrapper>
      <S.ErrorDivider>
        <h3>Что-то пошло не так</h3>
        <p>Мы не можем дать ответ на твой запрос в этот момент.</p>
        <p>ERROR: {error.message}</p>
        <p>
          Попробуй перезагрузить страницу. Если это не помогло обратись к нам по
          электронной почте:{" "}
          <S.Link type={"email"} href={"mailto:bawlcases@mail.com"}>
            bawlcases@mail.com
          </S.Link>
        </p>
      </S.ErrorDivider>
    </S.Wrapper>
  );
};
