import * as S from "./Styles";
import { FirstPanel } from "./FirstPanel/FirstPanel";
import { SecondPanel } from "./SecondPanel/SecondPanel";
import TopSiteLinkContainer from "./TopSiteLink/TopSiteLinkContainer";

export const Footer = () => {
  return (
    <S.Footer>
      <hr />
      <S.Wrapper>
        <FirstPanel />
        <SecondPanel />
        <TopSiteLinkContainer />
      </S.Wrapper>
    </S.Footer>
  );
};
